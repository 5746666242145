import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Select} from '@ngxs/store';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, filter, startWith, switchMap, tap} from 'rxjs/operators';
import {KetelModel} from 'src/app/core/models/ketel.models';
import {RoleModel} from '../../../core/states/auth/auth.model';
import {AuthState} from '../../../core/states/auth/auth.state';
import {QrCodeModel} from '../../../modules/barcode/qr-code.model';
import {StorageModel} from '../../../modules/receipt/symbols';
import {EquipmentService} from './equipment.service';


@Component({
    selector: 'app-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnDestroy, OnInit {
    @Output() outputKetelName: EventEmitter<{ ketel: Partial<KetelModel> }> = new EventEmitter();
    @Output() outputEquipmentFromStorage: EventEmitter<StorageModel> = new EventEmitter();
    @Output() outputKetelSn: EventEmitter<{ ketel: Partial<KetelModel> }> = new EventEmitter();
    @Output() outputKetelKwt: EventEmitter<{ ketel: Partial<KetelModel> }> = new EventEmitter();
    @Output() outputService = new EventEmitter<{ date: string; masterCode: string }>();
    @Output() formEquipment = new EventEmitter<FormGroup>();
    @Output() formControlSelectOnly = new EventEmitter<FormControl>();

    @Input() typeEquipment: 'all' | 'main' | 'accessories' = 'all';
    @Input() stateEquipment: 'all' | 'used' | 'new' = 'all';
    @Input() selectOnly = false;
    @Input() readonly = false;
    @Input() showSnReadyForCreatingTalon = true;

    @Select(AuthState.access) access$: Observable<RoleModel>;


    localKetel: KetelModel;

    const;
    form = new FormGroup({
        controlKetel: new FormControl(),
        controlSn: new FormControl(),
        controlKwt: new FormControl(),
    });


    ketels$: BehaviorSubject<StorageModel[]> = new BehaviorSubject([]);
    selectedEquipmentValue: StorageModel;
    subscription = new Subscription();
    freeKetels$: BehaviorSubject<QrCodeModel[]> = new BehaviorSubject<QrCodeModel[]>([]);
    requiredLocal = false;

    constructor(private service: EquipmentService) {
    }

    @Input() set ketel(ketel: KetelModel) {
        this.localKetel = {...ketel};
        this.form.controls.controlSn.markAsUntouched();

        if (this.localKetel.sn !== this.form.controls.controlSn.value) {
            this.form.controls.controlSn.setValue(this.localKetel.sn, {emitEvent: false});
        }

        if (this.form.controls.controlSn.value === ketel.sn && ketel.name !== '') {
            this.form.controls.controlKetel.setValue(this.localKetel.name, {emitEvent: false});
        }


        this.form.controls.controlKwt.setValue(this.localKetel.kWt, {emitEvent: false});


    }

    @Input() set equipmentFromStorage(id: number) {
        if (id) {
            this.service.getEquipmentById(id).subscribe({
                next: v => {
                   this.selectedEquipmentValue = v;
                },
            });
        }
    }

    @Input() set required(value: boolean) {
        this.requiredLocal = value;
        if (value) {
            this.form.controls.controlSn.setValidators([Validators.required]);
            this.form.controls.controlKwt.setValidators([Validators.required]);
            this.form.controls.controlKetel.setValidators([Validators.required]);
        } else {
            this.form.controls.controlSn.removeValidators([Validators.required]);
            this.form.controls.controlKwt.removeValidators([Validators.required]);
            this.form.controls.controlKetel.removeValidators([Validators.required]);
        }
    }

    ngOnInit(): void {
        if (this.readonly) {
            return;
        }

        this.formEquipment.emit(this.form);

        this.subscription.add(this.form.controls.controlKetel.valueChanges
            .pipe(
                startWith(this.form.controls.controlKetel.value ? this.form.controls.controlKetel.value : ''),
                debounceTime(200),
                switchMap((v: string) => this.service.getLibraryEquipment(v, this.stateEquipment, this.typeEquipment)),
            ).subscribe((v: StorageModel[]) => {
                this.ketels$.next(v);
            }));

        this.subscription.add(
            this.form.controls.controlSn.valueChanges
                .pipe(
                    startWith(this.form.controls.controlSn.value),
                    debounceTime(500),
                    switchMap(v => combineLatest([this.service.getFreeKetel(v, !this.showSnReadyForCreatingTalon),
                        this.service.getEquipmentByArticle(v)])),
                    tap(([free]) => this.freeKetels$.next(free)),
                    filter(([, ketel]) => !!ketel),
                )
                .subscribe({
                    next: ([, ketel]) => {
                        this.form.controls.controlKetel.setValue(ketel.name, {emitEvent: false});
                        const updateKetel = {...this.localKetel, name: ketel.name};
                        this.outputKetelName.emit({ketel: updateKetel});
                    },
                }));


        this.init();
    }

    init(): void {


        this.subscription.add(this.form.controls.controlSn.valueChanges
            .pipe(debounceTime(500))
            .subscribe((sn) => {
                const updateKetel = {...this.localKetel, sn};
                this.outputKetelSn.emit({ketel: updateKetel});
            }));

        this.subscription.add(this.form.controls.controlKwt.valueChanges
            .subscribe((value) => {
                const updateKetel = {...this.localKetel, kWt: value};
                this.outputKetelKwt.emit({ketel: updateKetel});
            }));

        this.subscription.add(this.form.controls.controlKetel.valueChanges
            .subscribe((value) => {
                const updateKetel = {...this.localKetel, name: value};
                this.outputKetelName.emit({ketel: updateKetel});
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    selectSn(equipment: QrCodeModel): void {
        const updateKetel = {
            ...this.localKetel,
            name: equipment.ketel.name,
            kWt: equipment.ketel.kWt,
            sn: equipment.ketel.sn,
            master_name: equipment.master,
        };
        this.outputKetelSn.emit({ketel: updateKetel});
        this.outputService.emit({date: equipment.date, masterCode: equipment.master?.toString()});
    }

    setFormControlSearchSelector(formControl: FormControl) {
        this.formControlSelectOnly.emit(formControl);
    }

    selectedEquipment(item: any) {
        this.outputEquipmentFromStorage.emit(item);
    }
}
