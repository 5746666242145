import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LoginModel, RoleModel, UserModel} from '../states/auth/auth.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(private http: HttpClient) {
    }


    signIn(userData: LoginModel): Observable<UserModel> {
        return this.http.post<UserModel>('/api/auth/login', userData);
    }

    loadRolesParams(): Observable<RoleModel> {
        return this.http.get<RoleModel>('/api/auth/permissions');
    }

    refreshToken(): Observable<UserModel> {
        return this.http
            .post<UserModel>('/api/auth/refresh-token', {withCredentials: true});

    }


    getNameUserFromCode(code: string): Observable<{ isSuccess: boolean, user: { name: string } }> {
        return this.http.get<{ isSuccess: boolean, user: { name: string } }>(`api/auth/getNameUserFromCode?code_for_calls=${code}`);
    }


    /** Запросить доступ через телеграм бота */
    authWidthTelegram(tel: string): Observable<{ uuid: string, isExistTelegram: boolean }> {
        return this.http.post<{ uuid: string, isExistTelegram: boolean }>('/api/auth/login-width-telegram', {tel});
    }

    getAuthWidthTelegram(uuid: string, tel: string): Observable<UserModel> {
        return this.http.post<UserModel>(`/api/auth/get-login-width-telegram`, {uuid, tel});
    }
}
