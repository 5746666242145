<div class="pdf-config">

    <h1 style="font-size: 16px;
  font-weight: 900;" *ngIf="data.field_list">Для створення макету, створіть PDF файл, додайте поля з відповідними
        назвами.</h1>
    <div *ngFor="let elem of data.field_list" style="line-heigh: 10px">
        <span style="font-weight: bold;">{{elem.name}}: </span> <span style="color: red;">
      <{{elem.key}}>
    </span>
    </div>


    <h1
            style="margin-top: 30px; font-size: 16px; font-weight: 900;"
            *ngIf="data.jsonParams">При створенні PDF макету, можна створити поля і дати їм назви "content_x",
        де х - це індекс кожного поля і записати в них дані об'єкту в форматі JSON</h1>
    <div *ngFor="let elem of data.jsonParams" style="line-heigh: 10px">
    <span style="font-weight: bold; color: red;">{{elem.name}}:
      <pre>
        <code>
        {{elem.key| json}}

        </code>
        </pre>

    </span>
    </div>


</div>
