import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SseService {
    private eventSource: EventSource;

    constructor() {}

    // Метод, який підписується на потік подій та повертає Observable з отриманими даними
    getServerSentEvents(url: string): Observable<any> {
        return new Observable(observer => {
            this.eventSource = new EventSource(url);

            this.eventSource.onmessage = event => {
                observer.next(event.data);
            };

            this.eventSource.onerror = error => {
                observer.error(error);
            };
        });
    }

    // Метод, який закриває підписку на потік подій
    closeServerSentEvents() {
        this.eventSource.close();
    }
}
