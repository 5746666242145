import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TelModel} from 'src/app/core/models/tel.models';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from 'src/app/core/services/auth.service';
import {Select} from '@ngxs/store';
import {AuthState} from '../../../core/states/auth/auth.state';
import {RoleModel} from '../../../core/states/auth/auth.model';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})

export class ClientComponent implements OnChanges, OnDestroy, OnInit {
    @Select(AuthState.access) access$: Observable<RoleModel>;

    @Output() outputTel: EventEmitter<{ tel: TelModel }> = new EventEmitter();
    @Output() outputUser: EventEmitter<{ user: string }> = new EventEmitter();
    @Input() user?: string;

    telLocal: TelModel;
    controlUser = new FormControl();
    subscription = new Subscription();


    constructor(public auth: AuthService, private cd: ChangeDetectorRef) {
    }

    @Input() set tel(tel: TelModel) {
        this.telLocal = tel;
    }

    ngOnInit(): void {
        this.subscription.add(this.controlUser.valueChanges.subscribe(v => this.outputUser.emit({user: v})));
    }

    ngOnChanges(): void {
        this.controlUser.setValue(this.user, {emitEvent: false});
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getTel(tel: TelModel): void {
        if (this.isVerify(tel)) {
            this.outputTel.emit({tel});
        }
    }


    private isVerify(tel: TelModel): boolean {
        return tel.subscriber.length === 4 || !tel.area;
    }


}
