import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogModel} from '../../confirm.symbols';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ConfirmDialogModel,
        public dialog: MatDialogRef<ConfirmDialogComponent>
    ) {
    }


}
