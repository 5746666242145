import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/classes/auth.guard';
import {DefineAuthComponent} from './define-auth/define-auth.component';
import {LoginPageComponent} from './modules/login-page/login-page.component';

const routes: Routes = [
    {
        path: '',
        component: DefineAuthComponent,
        data: {dashboard: true},
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule),
        data: {dashboard: true},
        canActivate: [AuthGuard],
    },
    {
        data: {dashboard: false},
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'response/:id',
        data: {dashboard: false},
        loadChildren: () =>
            import('./modules/response/response.module').then((m) => m.ResponseModule),
    },
    {
        path: '**',
        data: {dashboard: true},
        redirectTo: 'dashboard',
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
