import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RtPlatformService} from '../../../rt-platform/rt-platform.service';

/** Define in which way content should be transformed. */
enum ContentTransformStrategy {
    /** Content fully hidden and only loader displayed above it. */
    REPLACE = 'replace',

    /** Loader displays above the content. */
    OVERLAY = 'overlay',

    /** Content partially disappear and loader displays above it. */
    DISAPPEAR = 'disappear',
}

/** Describes which loader should be displayed. */
enum LoaderType {
    /** Simple circular loader with casual animation. */
    CIRCULAR = 'circular',

    /** Linear loader on the top of the content. */
    LINEAR = 'linear',
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'rt-loading',
    templateUrl: './rt-loading.component.html',
    styleUrls: ['./rt-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('halfDisappear', [
            state('true', style({opacity: 0.6})),
            state('false', style({opacity: 1})),
            transition('false => true', [animate(300)]),
            transition('true => false', [animate(200)]),
        ]),
    ],
})
export class RtLoadingComponent implements AfterViewInit {
    @ViewChild('content') content: ElementRef;

    @Input() strategy: ContentTransformStrategy = ContentTransformStrategy.DISAPPEAR;
    @Input() type: LoaderType = LoaderType.LINEAR;
    @Input() circularHeight = 60;
    @Input() linearHeight = 3;
    @Input() overlayColor = '#999999';
    @Input() centringCircular = true;

    /** Describes available loader types. Saved in local variables for usage in HTML template. */
    public readonly availableLoaderTypes = LoaderType;

    /** Describes available loader types. Saved in local variables for usage in HTML template. */
    public readonly availableTransformStrategy = ContentTransformStrategy;

    /** Contain actual content height. */
    public contentHeight: number;

    /** Contain actual content width. */
    public contentWidth: number;

    /** Until condition: show loader if this condition is `false`. */
    public untilCondition = true;

    /** While condition: show loader if this condition is `true`. */
    public whileCondition = false;

    /** Result of merging conditions. Indicates if loader should be displayed. */
    public showLoading = !this.until && this.while;

    constructor(protected platform: RtPlatformService) {
    }

    get until(): boolean {
        return this.untilCondition;
    }

    @Input() set until(untilCondition: boolean) {
        this.untilCondition = untilCondition;
        this.showLoading = !this.until && this.while;
    }


    get while(): boolean {
        return this.whileCondition;
    }


    @Input() set while(whileCondition: boolean) {
        this.whileCondition = whileCondition;
        this.showLoading = !this.until && this.while;
    }


    ngAfterViewInit(): void {
        if (this.platform.isBrowser) {
            const rect = this.content.nativeElement.getBoundingClientRect();
            this.contentHeight = rect.height;
            this.contentWidth = rect.width;
        }
    }
}
