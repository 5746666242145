import {StoryDoc} from '../stories/story.model';

export enum CONNECT_STATUS_WS {
    CONNECT = 'connect',
    CONNECTING = 'connecting',
    DISCONNECT = 'disconnect',

    DISCONNECT_BACKEND = 'disconnect_backend'
}


export interface CallInAsteriskModel {
    playbackId: string;
    creatingTime: string;
    state: CallingState;
    id: string;
    isHold: boolean;
    answered: string | null;
    typeCall: TypeCall;
    isDisabled: boolean;
    tel: string;
    nameCaller?: string;
    beforeId?: string;
    docs?: StoryDoc[];
}


export enum TypeCall {
    InCall = 'inCall',
    OutCall = 'outCall'
}

export enum CallingState {
    WaitingForAnAnswer = 'waitingOfAnswer',
    Ringing = 'ringing',
    Ring = 'ring',
    Answer = 'answer'
}

export interface ToCallResponseModel {
    id: string;
    name: string;
    state: string;
    caller: { name: string; number: string; };
    connected: { name: string; number: string; };
    accountcode: string;
    dialplan: { context: string; exten: string; priority: number; };
    creationtime: string;
    language: string;

}


export interface AsteriskEndpoint {
    channel_ids: [];
    resource: string;
    state: string | 'online';
    technology: string | 'SIP';
}

export interface TimeModel {
    hour: number;
    minute: number;
}

export interface RoutineModel {
    monday_start: TimeModel;
    monday_end: TimeModel;
    tuesday_start: TimeModel;
    tuesday_end: TimeModel;
    wednesday_start: TimeModel;
    wednesday_end: TimeModel;
    thursday_start: TimeModel;
    thursday_end: TimeModel;
    friday_start: TimeModel;
    friday_end: TimeModel;
    saturday_start: TimeModel;
    saturday_end: TimeModel;
    sunday_start: TimeModel;
    sunday_end: TimeModel;
}

export interface ConfigAsteriskModel {
    host: string;
    port: string;
    api_key: string;
    protocol: string;
    web_port: string;
}

