import {AddressModel} from '../../core/models/adress.models';
import {SearchMode} from '../../core/models/filters.models';
import {KetelModel} from '../../core/models/ketel.models';
import {TelModel} from '../../core/models/tel.models';
import {ReceiptModel} from '../receipt/symbols';


export interface TalonModel {
    id: string;
    // eslint-disable-next-line id-blacklist
    number: number;
    date: string;

    dateLaunch?: string;
    address: AddressModel;
    tel: TelModel;
    masterName: string;
    dateOfCheck?: string;
    ketel?: KetelModel;
    user?: string;
    mediator?: number; // Посередник
    mediator_price?: string;
    debtFlag: boolean; // борг
    dateDebt?: string;
    receipt: ReceiptModel[];
    receipt_id: number;
    debt?: string;
    isDeactivated: boolean;
    state?: WarrantyModel;
    warranty: number;
    assembling: string;
    isNotOurs?: boolean;
    contractors_name?: string;
    version: number; // версія документу
    isNewEquipment?: boolean;
    masterNumber?: string;
    isStory?: boolean;
    isNew: boolean;
    uid: string;
}

export interface TalonsListModel {
    items: TalonModel[];
    total_count: number;
}

export enum WarrantyEnum {
    NOT_GUARANTEED = 'notGuaranteed',
    GUARANTEED = 'guaranteed',
    NOT_LAUNCHED = 'notLaunched'
}


export interface TalonFiltersModel {
    debt: boolean;
    searchMode: SearchMode;
    searchText: string;
}

export interface WarrantyModel {
    state?: WarrantyEnum;
    guaranteePeriod?: number;
    dateEndedGuarantee?: string;
}


export interface PrintTalonAct {
    id: string;
    maket: string;
    'number': number;
    ketel: KetelModel;
    date: string;
    address: AddressModel;
    master: string;
}

export interface PrintTalonContract {
    id: string;
    maket: string;
    'number': number;
    ketel: KetelModel;
    date: string;
    address: AddressModel;
    user: string;
    tel: TelModel;
}

export interface DataForTalonPrintingModel {
    id: string;
    maket: string;
    'number': number;
    ketel: KetelModel;
    date: string;
    user: string;
    address: AddressModel;
    tel: TelModel;
    dateLaunch: string;
    assembling: string;
}


