import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ToastStateModel} from './toast.model';
import {ShowToast} from './toast.action';
import {CoreState} from '../core/core.state';

@State<ToastStateModel>({
    name: 'toast',
    defaults: {
        toast: null
    },
})
@Injectable()
export class ToastState {
    constructor(private store: Store) {
    }


    @Selector()
    static toast(state: ToastStateModel): ToastStateModel['toast'] {
        return state.toast;
    }

    @Action(ShowToast)
    showToastSuccess(ctx: StateContext<ToastStateModel>, {toast}: ShowToast): void {
        const isDesktop = this.store.selectSnapshot(CoreState.isDesktop);
        ctx.patchState({toast: {...toast, key: isDesktop ? 'top-right' : 'top-center'}});
    }
}

