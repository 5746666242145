import {CourseClass} from '../../models/auxiliary.models';
import {ConfigSystemStructure} from '../../models/config_system.models';
import {MaketsPrint} from '../../models/makets_print.models';
import {MasterModel} from '../../models/models-for-service.models';
import {UserModel} from '../auth/auth.model';

export interface CoreStateModel {
    isDesktop: boolean;
    masters: MasterModel[];
    currentMaster: MasterModel;
    maketsPrint: MaketsPrint;
    course: CourseClass[];
    usersDictCode: { [code: string]: UserModel };
    configSystem: ConfigSystemStructure;
}


export const DEFAULT_CORE_STATE: CoreStateModel = {
    isDesktop: null,
    masters: null,
    currentMaster: null,
    maketsPrint: null,
    course: null,
    usersDictCode: {},
    configSystem: null,
};
