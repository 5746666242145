import {Component, TemplateRef} from '@angular/core';
import { ToastService } from './toast-service';



@Component({
  selector: 'app-toasts',
  templateUrl: './toast-container.html',
})

export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
