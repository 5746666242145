import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {QrCodeModel} from '../../../modules/barcode/qr-code.model';
import {StorageModel} from '../../../modules/receipt/symbols';

@Injectable({
    providedIn: 'root',
})
export class EquipmentService {
    private readonly api = '/api/storage';

    constructor(private http: HttpClient) {
    }


    getLibraryEquipment(searchValue: string, state: 'all' | 'used' | 'new', type: 'all' | 'accessories' | 'main'):
        Observable<StorageModel[]> {
        return this.http.get<StorageModel[]>(
            `${this.api}/`, {
                params: {
                    searchValue,
                    type,
                    state,
                },
            },
        );
    }


    getEquipmentByArticle(art: string): Observable<StorageModel> {
        return this.http.get<StorageModel>(
            `${this.api}/by-article/`, {params: {art}},
        );
    }

    getEquipmentById(id: number): Observable<StorageModel> {
        return this.http.get<StorageModel>(
            `${this.api}/by-id/${id}/`,
        );
    }

    getFreeKetel(sn: string, returnEmptyArray?: boolean): Observable<QrCodeModel[]> {
        if (returnEmptyArray) {
            return of([]);
        } else {
            return this.http.get<QrCodeModel[]>(
                `${this.api}/ready-for-creating-talon?sn=${sn}`,
            );
        }
    }


}
