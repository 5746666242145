import {TelModel} from 'src/app/core/models/tel.models';
import {AddressModel} from '../../core/models/adress.models';
import {SearchMode} from '../../core/models/filters.models';
import {KetelModel} from '../../core/models/ketel.models';

export interface ContractModel {
    id: string;
    number?: number;
    ketel: KetelModel;
    address?: AddressModel;
    tel: TelModel;
    user: string;
    date: string;
    state?: STATE_CONTRACT;
    endDate?: Date;
    isDeactivated: boolean;
    isStory?: boolean;
    version: number;
    isNew: boolean;
    uid: string;
}

export enum STATE_CONTRACT {
    valid = 'valid',
    invalid = 'invalid',
}

export interface FilterContractModel {
    searchText: string;
    searchMode: SearchMode;
}


export interface ContractPrintModel {
    id: number | string;
    maket: string;
    number: string | number;
    date: string;
    ketel: string;
    address: AddressModel;
    tel: string;
    user: string;
}
