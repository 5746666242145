import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AddressModel} from 'src/app/core/models/adress.models';
import {AddressService} from './address.service';
import {debounceTime, switchMap} from 'rxjs/operators';


@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnDestroy, OnChanges, OnInit {

    @Output() outputAddress: EventEmitter<{ address: AddressModel }> = new EventEmitter();
    localAddress: AddressModel;


    county: BehaviorSubject<string[]> = new BehaviorSubject([]);
    cities: BehaviorSubject<string[]> = new BehaviorSubject([]);
    streets: BehaviorSubject<string[]> = new BehaviorSubject([]);

    controlStreet = new FormControl('');
    controlCity = new FormControl('');
    controlCounty = new FormControl('');

    subscription = new Subscription();


    constructor(
        private service: AddressService,
        private cd: ChangeDetectorRef
    ) {
    }

    @Input() set address(address: AddressModel) {
        this.localAddress = {...address};
    }

    ngOnInit(): void {


        this.subscription.add(this.controlStreet.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((v: string) => this.service.getStreet(v, this.controlCity.value))
            ).subscribe(v => {
                this.streets.next(v);
                this.cd.detectChanges();
            }));


        this.subscription.add(this.controlCity.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((v: string) => this.service.getCity(this.controlCounty.value, v))
            ).subscribe(v => {
                this.cities.next(v);
                this.cd.detectChanges();
            }));


        this.subscription.add(this.controlCounty.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((v: string) => this.service.getCounty(v))
            ).subscribe(v => {
                this.county.next(v);
                this.cd.detectChanges();
            }));

        //// STREET

        this.subscription.add(this.controlStreet.valueChanges
            .subscribe(v => {
                const updateAddress = {...this.localAddress, street: v};
                this.outputAddress.emit({address: updateAddress});
                this.cd.detectChanges();
            }));

        ///// CITY


        this.subscription.add(this.controlCity.valueChanges
            .subscribe(v => {
                const updateAddress = {...this.localAddress, city: v};
                this.outputAddress.emit({address: updateAddress});
                this.cd.detectChanges();
            }));


        //// COUNTY

        this.subscription.add(this.controlCounty.valueChanges
            .subscribe(v => {
                const updateAddress = {...this.localAddress, county: v};
                this.outputAddress.emit({address: updateAddress});
                this.cd.detectChanges();
            }));


    }

    ngOnChanges(): void {
        this.controlCity.setValue(this.localAddress.city, {emitEvent: false});
        this.controlStreet.setValue(this.localAddress.street, {emitEvent: false});
        this.controlCounty.setValue(this.localAddress.county, {emitEvent: false});
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
