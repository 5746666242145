import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {OrderModel} from '../../order.models';
import {FormControl, FormGroup} from '@angular/forms';
import {TelModel} from '../../../../core/models/tel.models';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnDestroy {

    @Input() set order(order: OrderModel) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.orderLocal = {...order};
        this.form.setValue({
            county: order.address.county,
            city: order.address.city,
            street: order.address.street
        });

        this.init();
    }

    @Output() outputContacts = new EventEmitter();

    subscription: Subscription;
    orderLocal: OrderModel;
    form = new FormGroup(
        {
            county: new FormControl(),
            city: new FormControl(),
            street: new FormControl(),
        }
    );

    constructor() {
    }


    init() {
        this.subscription = this.form.valueChanges.subscribe(v => {
            const adress = {...this.orderLocal.address};
            adress.street = v.street;
            adress.city = v.city;
            adress.county = v.county;
            this.orderLocal.address = adress;
            this.outputContacts.emit(this.orderLocal);
        });
    }

    setTel(tel: TelModel) {
        this.orderLocal.tel = tel;
        this.outputContacts.emit(this.orderLocal);
    }

    setTel2(tel: TelModel) {
        this.orderLocal.tel2 = tel;
        this.outputContacts.emit(this.orderLocal);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
