import { TabsStructure } from './tabs.models';

export class ToCloseTab {
  static type = '[Tabs] ToCloseTab';
  constructor(public tab?: TabsStructure, public uid?: string, public url?: string) {}
}

export class CloseTabByUID {
    static type = '[Tabs] CloseTabByUID';
    constructor(public uid: string) {}
}
export class RemoveTab {
  static type = '[Tabs] RemoveTab';
  constructor(public tab?: TabsStructure, public uid?: string) {}
}

export class AddTab {
  static type = '[Tabs] AddTab';
  constructor(public tab: TabsStructure) {}
}

export class ClearTabs {
  static type = '[Tabs] ClearTabs';
}

export class SelectTab {
  static type = '[Tabs] SelectTab';
  constructor(public index: number, public tab: TabsStructure) {}
}

export class IsChangedDoc {
  static type = '[Tabs] ChangedDoc';
  constructor(public uid: string, public isChanged: boolean) {}
}
