import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MasterModel} from 'src/app/core/models/models-for-service.models';
import {ParamsApplicationService} from 'src/app/core/services/params-application.service';
import {AsteriskService} from 'src/app/modules/asterisk/services/asterisk.service';
import {CoreState} from '../../../core/states/core/core.state';
import {CONNECT_STATUS_WS} from '../../../modules/asterisk/asterisk.symbols';
import {AsteriskStateModel} from '../../../modules/asterisk/states/asterisk-state/asterisk-state.model';
import {ToCall} from '../../../modules/asterisk/states/asterisk-state/asterisk.actions';
import {AsteriskState} from '../../../modules/asterisk/states/asterisk-state/asterisk.state';
import {PROGRESS_STATUSES} from '../../models/loadingStatus.model/PROGRESS_STATUSES';
import {parseTel} from '../../simbol';

@Component({
    selector: 'app-masters-select-input',
    templateUrl: './masters-select-input.component.html',
    styleUrls: ['./masters-select-input.component.scss'],
})
export class MastersSelectInputComponent implements OnChanges, OnDestroy, OnInit {
    @Select(AsteriskState.toCallStatus) toCallStatus$: Observable<PROGRESS_STATUSES>;
    @Select(AsteriskState.connectStatus) connectStatus$: Observable<AsteriskStateModel['connectStatus']>;

    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() masterName: string;
    @Input() required: boolean;
    @Output() selectNumberMaster: EventEmitter<string> = new EventEmitter();
    @Output() masterFormControl = new EventEmitter<FormControl>();

    public masterNumberLocal = null;
    public mastersFormControl: FormControl = new FormControl({disabled: this.disabled});
    public mastersFilterCtrl: FormControl = new FormControl();
    public filteredMasters: ReplaySubject<MasterModel[]> = new ReplaySubject<MasterModel[]>();

    public progressStatuses = PROGRESS_STATUSES;
    public connectWs = CONNECT_STATUS_WS;
    protected onDestroy = new Subject<void>();
    private subscription = new Subscription();

    constructor(private params: ParamsApplicationService, private callService: AsteriskService, private store: Store) {
    }

    get masterNumber(): string {
        return this.masterNumberLocal;
    }


    @Input() set masterNumber(masterNumber: string) {
        this.masterNumberLocal = masterNumber?.toString();
    }


    ngOnInit() {
        this.masterFormControl.emit(this.mastersFormControl);

        this.subscription.add(
            this.mastersFormControl.valueChanges.subscribe({
                next: (value) => {
                    this.selectNumberMaster.emit(value);
                    this.masterNumberLocal = value;
                },
            }),
        );
    }

    ngOnChanges(): void {
        if (this.required) {
            this.mastersFormControl.setValidators([Validators.required]);
        }
        this.mastersFormControl.setValue(this.store.selectSnapshot(CoreState.masterList)
            .find(f => f.code === this.masterNumber)?.code);

        this.filteredMasters.next(this.store.selectSnapshot(CoreState.masterList).slice());
        this.mastersFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterMasters();
            });
    }


    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.subscription.unsubscribe();
    }


    toCall(): void {
        const master = this.store.selectSnapshot(CoreState.masterList).find(item => item.code === this.masterNumberLocal);
        this.store.dispatch(new ToCall(master.tel));
    }

    protected filterMasters(): void {
        if (!this.store.selectSnapshot(CoreState.masterList)) {
            return;
        }
        // get the search keyword
        let search = this.mastersFilterCtrl.value;
        if (!search) {
            this.filteredMasters.next(this.store.selectSnapshot(CoreState.masterList).slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredMasters.next(
            this.store.selectSnapshot(CoreState.masterList).filter(v => v.name.toLowerCase().indexOf(search) > -1),
        );
    }
}
