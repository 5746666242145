import {PROGRESS_STATUSES} from '../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';

export interface AuthStateModel {
    userAuthError: PROGRESS_STATUSES;
    isAccessLoginWidthTelegram: boolean;
    uuidForLoginWidthTelegram: string;
    loginWidthTelegramStatus: PROGRESS_STATUSES;
    isRefusal: boolean;
    user: UserModel;
    loadPermissionsStatus: PROGRESS_STATUSES;
    onlineList: OnlineModel[];
}

export interface OnlineModel {
    id: number;
    name: string;
    code: string;
    online: boolean;
    dateOnline: string;
}

export interface LoginModel {
    login: string;
    password: string;
    tel: string;
}


export const AUTH_STATE_DEFAULT: AuthStateModel = {
    userAuthError: PROGRESS_STATUSES.NOT_INITIALIZE,
    isAccessLoginWidthTelegram: false,
    uuidForLoginWidthTelegram: null,
    loginWidthTelegramStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    isRefusal: false,
    user: null,
    loadPermissionsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    onlineList: [],
};


export interface UserModel {
    id?: number;
    token?: string;
    token_refresh?: string;
    rolesParams?: RoleModel;
    name?: string;
    role?: string;
    dateRegister?: string;
    code?: string;
    isPhone?: boolean;
    login?: string;
    pwd_interior?: string;
    password?: string;
    tel: string;
}

export interface UserExpectBackendModel {
    id: number;
    name: string;
    login: string;
    password: string;
    role: string;
    params: RoleModel;
    code: string;
    time_in: string;
    isOnline: boolean;
    date_update: string;
    pwd_interior: string;
    dateRegister: string;
    tel: string;
    isMaster: boolean;
    isLogin: boolean;
    isRegisterWorks: boolean;
}


export interface RoleModel {
    read: ReadModel;
    write: ReadModel;
    deleteDoc: ReadModel;
    read_equipment: boolean;
    read_contacts: boolean;
    write_state: boolean;
    supper_access: boolean;
    super_admin: boolean;
    sender: boolean;
    SIP_IN: boolean; // разрешение на входн. звонки
    SIP_OUT: boolean; // разрешение на выход. звонки
    PC_PLATFORM: boolean;
}

export interface ReadModel {
    stand: boolean;
    orders: boolean;
    talone: boolean;
    work_performed: boolean;
    cash_register: boolean;
    contracts: boolean;
    storage: boolean;
    movement: boolean;
    departure: boolean;
    analytics: boolean;
    invoices: boolean;
    enterprises: boolean;
}

export interface RoleExpectBackendModel {
    id: number;
    name: string;
    params: RoleModel;
}
