import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PrinterService, PrinterType} from 'src/app/core/services/qz-tray.service';
import {ReceiptModel} from './symbols';

interface DataReceiptPrinter {
    receipt: ReceiptModel[];
    id: string;
    receipt_id: number;
    info_for_receipt: string;
    date: string;
}

@Injectable()
export class ReceiptService {

    constructor(private printerService: PrinterService, private http: HttpClient) {
    }

    printReceipt(dataReceiptPrinter: DataReceiptPrinter): void {

        this.http
            .post<{ url }>('/api/storage/print-receipt', {
                ...dataReceiptPrinter,
            })
            .subscribe((v) => {
                const data = [
                    {
                        type: 'pdf',
                        format: 'pdf',
                        data: v.url,
                    },
                ];
                if (this.printerService.isAvtive()) {
                    this.printerService.printData(PrinterType.DOCUMENT, data);
                } else {
                    window.open(v.url);
                }

            });
    }

}



