<div class="help-container">
    <span class="help-text">{{helpText$ | async}}</span>
    <rt-loading
            [until]="false"
            [while]="loadingChatGpt | async"
            type="circular"
            [circularHeight]="40"
    >
        <button
                [disabled]="ketelName === '' || reason === ''"
                class="icn-button"
                mat-icon-button
                (click)="help('Як виправити '+reason+' на котлі '+ketelName)">
            <img src="/assets/img/help.svg" alt="help" class="icn-help">
        </button>
    </rt-loading>
</div>
