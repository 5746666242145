import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TelModel} from '../../../core/models/tel.models';
import {StoriesModel} from '../story.model';

@Injectable({
    providedIn: 'root',
})
export class StoryService {

    api = 'api/story';

    constructor(private http: HttpClient) {
    }

    getStoryForOrder(tel: TelModel, tel2: TelModel, sn: string, limit?: number): Observable<StoriesModel> {
        return this.http.get<StoriesModel>('/api/orders/story', {
            params: {
                tel: tel ? `${tel.area}${tel.exchange}${tel.subscriber}` : null,
                tel2: tel2 ? `${tel2.area}${tel2.exchange}${tel2.subscriber}` : null,
                sn,
                limit: limit ? limit : '',
            },
        });
    }

    getStoryByTel(tel: string): Observable<StoriesModel> {
        return this.http.get<StoriesModel>(`${this.api}/by-tel`, {params: {tel}});
    }

}
