import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StorageModel} from '../../receipt/symbols';
import {FolderExpectDataBase, StorageMovementList, StorageMovementModel} from '../storage.symbols';

@Injectable()
export class StorageManageService {

    api = '/api/storage';

    constructor(private http: HttpClient) {
    }

    getGoodsById(id: number): Observable<StorageModel> {
        return this.http.get<StorageModel>(`${this.api}/goods-by-id/${id}`);
    }

    updateGoods(id: string, goods: StorageModel): Observable<StorageModel> {
        return this.http.patch<StorageModel>(`${this.api}/${id}`, goods);
    }

    createFolder(folder: FolderExpectDataBase): Observable<FolderExpectDataBase> {
        return this.http.post<FolderExpectDataBase>(`${this.api}/folder/`, folder);
    }

    updateFolder(folder: FolderExpectDataBase): Observable<FolderExpectDataBase> {
        return this.http.patch<FolderExpectDataBase>(`${this.api}/folder/${folder.id}`, folder);
    }

    createGoods(goods: StorageModel): Observable<StorageModel> {
        return this.http.post<StorageModel>(`${this.api}/`, goods);
    }

    getMovementGoods(id: number): Observable<StorageMovementModel> {
        return this.http.get<StorageMovementModel>(`${this.api}/movement/${id}`);
    }

    updateMovementGoods(id: number, goods: StorageMovementModel): Observable<StorageMovementModel> {
        return this.http.patch<StorageMovementModel>(`${this.api}/movement/${id}`, goods);
    }
    insertMovementGoods(goods: StorageMovementModel): Observable<StorageMovementModel> {
        return this.http.post<StorageMovementModel>(`${this.api}/movement/`, goods);
    }

}
