import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {StorageModel} from '../../../receipt/symbols';
import {StorageMovementModel} from '../../storage.symbols';

export interface StorageManageStateModel {
    currentGoods: StorageModel;
    currentMovementGoods: StorageMovementModel;
    loadMovementGoodsStatus: PROGRESS_STATUSES;
    loadGoodsStatus: PROGRESS_STATUSES;
    updateGoodsStatus: PROGRESS_STATUSES;
    updateMovementStatus: PROGRESS_STATUSES;
    createFolderStatus: PROGRESS_STATUSES;
    updateFolderStatus: PROGRESS_STATUSES;
    createGoodsState: PROGRESS_STATUSES;
    insertMovementStatus: PROGRESS_STATUSES;
}

export const STORAGE_MANAGE_STATE_DEFAULT: StorageManageStateModel = {
    currentGoods: null,
    loadGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    updateGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    createFolderStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    updateFolderStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    updateMovementStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    createGoodsState: PROGRESS_STATUSES.NOT_INITIALIZE,
    currentMovementGoods: null,
    loadMovementGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    insertMovementStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
};
