import {OrderModel} from 'src/app/modules/orders/order.models';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TelModel} from '../../../core/models/tel.models';
import {ResponseTurboSMSModel} from '../order.symbols';

@Injectable()
export class OrderService {


    private api = '/api/orders/';

    constructor(private http: HttpClient) {
    }

    getOrderById(id: string): Observable<OrderModel> {
        return this.http.get<OrderModel>(this.api + `order/${id}/`);
    }


    acceptOrder(order: OrderModel): Observable<OrderModel> {
        return this.http.patch<OrderModel>(this.api + `accept/${order.id}/`, {order});
    }

    saveInDB(order: OrderModel): Observable<OrderModel> {
        return this.http.post<OrderModel>(this.api + 'order/', order);
    }

    updateOrder(order: OrderModel): Observable<{ success: boolean; message: string; version: number }> {
        return this.http.patch<{ success: boolean; message: string; version: number }>(this.api + `order/${order.id}/`, order);
    }


    getNumber(): Observable<number> {
        return this.http.get<number>(this.api + 'next-number/');
    }

    downgradeNumerate(numberOrder: number): Observable<void> {
        return this.http.patch<void>(this.api + 'downgrade-number/', {number: numberOrder});
    }


    public getResponse(orderId: string, tel: TelModel): Observable<ResponseTurboSMSModel> {
        const api = '/api/sender/request-response/';
        return this.http.post<ResponseTurboSMSModel>(api, {
            link: `${window.location.protocol}//${window.location.host}/response/${orderId}`,
            tel,
            id: orderId
        });

    }

}
