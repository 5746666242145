<div class="wrap" #content>
<ng-container>
  <ng-container [ngSwitch]="strategy">

    <ng-container *ngSwitchCase="availableTransformStrategy.REPLACE">
      <ng-container *ngIf="showLoading; else loader">
        <div *ngIf="type === availableLoaderTypes.LINEAR" class="linear-wrapper">
          <mat-progress-bar mode="indeterminate" [ngStyle]="{'height.px': linearHeight}" class="linear-progress-bar"></mat-progress-bar>
        </div>
        <div *ngIf="type === availableLoaderTypes.CIRCULAR" class="spinner-wrapper">
          <mat-spinner [diameter]="circularHeight"></mat-spinner>
        </div>
      </ng-container>
      <ng-template #loader>
        <ng-container *ngTemplateOutlet="ngContent"></ng-container>
      </ng-template>
    </ng-container>


    <ng-container *ngSwitchCase="availableTransformStrategy.OVERLAY">
      <div class="content-with-overlay__wrap">
        <div
          class="overlay"
          *ngIf="showLoading"
          [ngStyle]="{
            backgroundColor: overlayColor,
            'height.px': contentHeight,
            'width.px': contentWidth
          }"
        >
          <div *ngIf="type === availableLoaderTypes.LINEAR" class="linear-wrapper linear-wrapper__overlay">
<!--            <mat-progress-bar-->
<!--              mode="indeterminate"-->
<!--              [ngStyle]="{'height.px': linearHeight, 'margin-top.px': -linearHeight}"-->
<!--            ></mat-progress-bar>-->

            <p-progressBar
                    mode="indeterminate"
                    [style]="{'height': '6px', 'margin-bottom': '10px'}"
                    [ngStyle]="{'height.px': linearHeight, 'margin-top.px': -linearHeight}"
            ></p-progressBar>

          </div>

          <div
            *ngIf="type === availableLoaderTypes.CIRCULAR"
            class="spinner-wrapper"
            [ngStyle]="{
              position: 'absolute',
              'margin-top.px': centringCircular ? (contentHeight/2 - circularHeight/2) : 0,
              'margin-left.px': centringCircular ? (contentWidth/2 - circularHeight/2) : 0
            }"
          >
            <mat-spinner [diameter]="circularHeight"></mat-spinner>
          </div>
        </div>
        <div>
          <ng-container *ngTemplateOutlet="ngContent"></ng-container>
        </div>
      </div>
    </ng-container>


    <ng-container *ngSwitchCase="availableTransformStrategy.DISAPPEAR">
      <div class="content__wrap" [class.ignore-clicks]="showLoading" #content>
        <div class="loading-indicator_wrap" *ngIf="showLoading">
          <div *ngIf="type === availableLoaderTypes.LINEAR" class="linear-wrapper">
            <mat-progress-bar
              mode="indeterminate"
              [ngStyle]="{'height.px': linearHeight}"
            ></mat-progress-bar>
          </div>

          <div
            *ngIf="type === availableLoaderTypes.CIRCULAR"
            class="spinner-wrapper"
            [ngStyle]="{
              position: 'absolute',
              'margin-top.px': centringCircular ? (contentHeight/2 - circularHeight/2) : 0,
              'margin-left.px': centringCircular ? (contentWidth/2 - circularHeight/2) : 0
            }"
          >
            <mat-spinner [diameter]="circularHeight"></mat-spinner>
          </div>
        </div>
        <div [@halfDisappear]="showLoading">
          <ng-container *ngTemplateOutlet="ngContent"></ng-container>
        </div>
      </div>
    </ng-container>

  </ng-container>

</ng-container>

<ng-template #ngContent><ng-content></ng-content></ng-template>

</div>

