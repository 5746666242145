<p mat-dialog-title style="text-align: center">
    <mat-icon style="font-size: 60px; width: 100px; height: 100px">error</mat-icon>
</p>
<h1 style="text-align: center">Увага, борг</h1>
<ul>
    <div mat-dialog-content>
        <li *ngFor="let item of data.message">
            {{item.nameDoc}} №{{item.number}} від {{item.dateDoc | date: "dd.MM.yyyy"
            }}, <b class="debt">Борг у розмірі {{item.debt}} грн.</b>
        </li>
    </div>
</ul>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()" >ОК</button>
</mat-dialog-actions>
