<div class="storage-list-root">
    <app-storage-tree
            [isDialog]="data?.isDialog"
            (idFolder)="idFolder$.next($event)"
    >

    </app-storage-tree>

    <div class="controls">
        <button mat-icon-button (click)="createFolder()">
            <img class="add-folder" src="/assets/img/folder-create.svg" alt="create folder">
        </button>

        <button mat-icon-button (click)="createGoods()">
            <img class="add-folder" src="/assets/img/position-add.svg" alt="create folder">
        </button>

    </div>
    <app-storage-filter (filter)="setFilter($event)"></app-storage-filter>
    <app-storage-list
            [isDialog]="data?.isDialog"
            style="height: calc(100% - 145px);"
            (idFolder)="idFolder$.next($event)"
            (selectedGood)="selectItem($event)"
    ></app-storage-list>

</div>
