import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SseService} from '../../core/services/sse.service';

@Injectable()
export class OpenAIService {
    constructor(private http: HttpClient, private sse: SseService) {
    }

    sendQuestion(question: string): Observable<any> {
        return this.sse.getServerSentEvents('/api/open-ai/' + encodeURIComponent(question) + '/');
    }

}
