import {Order, Sort} from '../../shared/simbol';
import {ReceiptModel} from '../receipt/symbols';

export interface FolderExpectDataBase {
    id?: number;
    parent_id?: number;
    name?: string;
}

export interface TreeFolders {
    currentFolder?: FolderExpectDataBase;
    childrenFolders: FolderExpectDataBase[];
    parentFolders: FolderExpectDataBase[];
}


export interface StorageMovementFilterModel {
    first?: number;
    count: number;
    name?: string;
    sort?: Sort;
    order?: Order;
    typeMovement?: TypeMovement;
}

export enum TypeMovement {
    Selling = 'selling',
    Delivery = 'delivery',
}

export enum Appointment {
    Qr = 'qr',
    Departure = 'departure',
    Free = 'free',
    Order = 'order',
    Stand = 'stand',
    Talone = 'talone',
    TaloneNew = 'taloneNew',
}


export interface StorageMovementModel {
    id: number | string;
    parent_id?: number;
    receipt: ReceiptModel[];
    type_movement: TypeMovement;
    appointment: Appointment;
    date: string;
    name: string;
    price: number;
    priceOpt: number;
    count: number;
    sum: number;
    art: string;
    is_do_not_move: boolean;
    id_goods?: number;
    number_doc?: number;
    id_document?: number;
    isNew?: boolean;

}

export interface StorageMovementList {
    items: StorageMovementModel[];
    totalCount: number;
}
