import { Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};




/** @title Datepicker emulating a Year and month picker */
@Component({
  selector: 'app-date-picker-global',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  @Output() dateOut: EventEmitter<string> = new EventEmitter();
  @Input() label: string;
  @Input() month: number;






  uSubWork: Subscription;
  date = new FormControl(moment());

  constructor(private dateAdapter: DateAdapter<any>) {
    this.dateAdapter.setLocale('uk_UA');

  }

  ngOnInit() {
    this.date.setValue(moment().add(this.month, 'months'));
    this.dateOut.emit(moment().add(this.month, 'months').format('YYYY-MM-DD'));
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.dateOut.emit(moment(ctrlValue).format('YYYY-MM-DD'));
    datepicker.close();
  }
}
