<div class="search-selector">
    <mat-form-field appearance="fill" class="mat-form-container">
        <mat-label>{{label}}</mat-label>
        <mat-select [formControl]="selectorFormControl">

            <input class="search-input" [formControl]="searchControl" [placeholder]="placeholder">

            <ng-container *ngIf="(choices$ | async)?.length; else notResult">
                <mat-option *ngFor="let choice of choices$ | async"
                            (click)="selectValue(choice)"
                            [value]="choice[valueFieldNameLocal]"
                >
                    {{choice[viewFieldNameLocal]}}
                </mat-option>
            </ng-container>

            <ng-template #notResult>
                <span class="not-result">not result</span>
            </ng-template>
        </mat-select>
    </mat-form-field>

</div>
