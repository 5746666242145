import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {EquipmentService} from '../../../../shared/components/equipment/equipment.service';
import {ReceiptService} from '../../receipt.service';
import {Subject, Subscription} from 'rxjs';
import {StorageModel} from '../../symbols';
import {debounceTime, startWith, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-mat-goods-selector',
    templateUrl: './mat-goods-selector.component.html',
    styleUrls: ['./mat-goods-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatGoodsSelectorComponent implements OnInit, OnDestroy {
    @Input() placeholder = '';
    @Input() label = '';
    @Output() selectedItem = new EventEmitter<StorageModel>();
    @Output() valueChange = new EventEmitter<string>();

    goodControl = new FormControl();
    goods$: Subject<StorageModel[]> = new Subject<StorageModel[]>();


    private readonly subscription = new Subscription();

    constructor(public service: EquipmentService) {
    }


    @Input() set defaultItem(item: StorageModel) {
        this.goodControl.setValue(item, {emitEvent: false});
    }

    ngOnInit(): void {
        this.subscription.add(
            this.goodControl.valueChanges
                .pipe(
                    tap(v => this.valueChange.next(v)),
                    debounceTime(200),
                    startWith( ''),
                    switchMap(value => this.service.getLibraryEquipment(value, 'all', 'all'))
                ).subscribe({
                next: items => this.goods$.next(items)
            })
        );
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    selectItem(event: StorageModel): void {
        this.selectedItem.next(event);
    }
}
