import {TalonVgModel} from '../../talons-vg.models';

export class OpenTalonVg {
    static type = '[TalonVg] OpenTalonVg';

    constructor(public talon?: TalonVgModel) {
    }
}

export class CloseTalonVg {
    static type = '[TalonVg] CloseTalonVg';

    constructor(public uid: string) {
    }
}

export class GetTalonVgById {
    static type = '[TalonVg] GetTalonVgById';

    constructor(public id?: string) {
    }
}

export class GetTalonVgByIdSuccess {
    static type = '[TalonVg] GetTalonVgByIdSuccess';

    constructor(public talon: TalonVgModel) {
    }
}

export class GetTalonVgByIdFail {
    static type = '[TalonVg] GetTalonVgByIdFail';

    constructor(public error: any) {
    }
}

export class ResetSelectedIndexTalonVg {
    static type = '[TalonVg] ResetSelectedIndexTalonVg';
}


export class UpdateTalonVgPartial {
    static type = '[TalonVg] UpdateTalonVgPartial';

    constructor(public talon: Partial<TalonVgModel>, public id: string) {
    }

}

export class CloseTalonVgForce {
    static type = '[TalonVg] CloseTalonVgForce';

    constructor(public uid: string) {
    }
}

export class OpenDialogSaveTalonVg {
    static type = '[TalonVg] OpenDialogSaveTalonVg';
}

export class OpenDialogClosedTalonVg {
    static type = '[TalonVg] OpenDialogClosedTalonVg';
}

export class ResetDialogTalonVg {
    static type = '[TalonVg] ResetDialogTalonVg';
}

export class ClearTalonVg {
    static type = '[TalonVg] ClearTalonVg';
}

export class SetErrorTalonVg {
    static type = '[TalonVg] SetErrorTalonVg';

    constructor(public error: any) {
    }
}


export class ClearErrorTalonVg {
    static type = '[TalonVg] ClearErrorTalonVg';

}


export class SaveTalonVg {
    static type = '[TalonVg] SaveTalonVg';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}

export class SaveTalonVgSuccess {
    static type = '[TalonVg] SaveTalonVgSuccess';

    constructor(public data: TalonVgModel, public uid: string, public forceClose?: boolean) {
    }
}

export class UpdateTalonVgSuccess {
    static type = '[TalonVg] UpdateTalonVgSuccess';

    constructor(public uid: string, public data: { success: boolean; message: string; version: number }, public forceClose?: boolean) {
    }
}


export class CreateTalonVg {
    static type = '[TalonVg] CreateTalonVg';

    constructor(public talon?: TalonVgModel) {
    }
}


export class PrintReceiptTalonVg {
    static type = '[TalonVg] PrintReceiptTalonVg';
}


export class PrintTalonVgContractVG {
    static type = '[TalonVg] PrintTalonVgContractVG';
}

export class PrintTalonVgContract {
    static type = '[TalonVg] PrintTalonVgContract';
}

export class PrintTalonVg {
    static type = '[TalonVg] PrintTalonVg';

    constructor(public isPrintEquipment?: boolean) {
    }
}



export class OpenTalonVgById {
    static type = '[Talon] OpenTalonVgById';

    constructor(public id: string) {
    }
}

