import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StorageModel} from '../../receipt/symbols';
import {FolderExpectDataBase, StorageMovementFilterModel, StorageMovementList, TreeFolders} from '../storage.symbols';

@Injectable()
export class StorageListService {

    api = '/api/storage';

    constructor(private http: HttpClient) {
    }

    getTreeFolders(id?: number): Observable<TreeFolders> {
        const parameters: any = {};
        if (id) {
            parameters.id = id;
        }

        return this.http.get<TreeFolders>(`${this.api}/tree-folders/`, {params: parameters});
    }

    getGoodsIntoFolder(folderId?: number, name?: string): Observable<{ goods: StorageModel[], folders: FolderExpectDataBase[] }> {
        const parameters: any = {};
        if (name) {
            parameters.name = name;
        }
        if (folderId) {
            parameters.folder_id = folderId;
        }
        return this.http.get<{ goods: StorageModel[], folders: FolderExpectDataBase[] }>(`${this.api}/goods/`, {params: parameters});
    }


    getMovementList(filter: StorageMovementFilterModel): Observable<StorageMovementList> {
        return this.http.get<StorageMovementList>(`${this.api}/movement-list/`, {params: {...filter}});
    }
}
