import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {TelModel} from '../../../../core/models/tel.models';
import {CoreState} from '../../../../core/states/core/core.state';
import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {parseStringToTel, parseTel} from '../../../../shared/simbol';
import {CONNECT_STATUS_WS} from '../../../asterisk/asterisk.symbols';
import {AsteriskService} from '../../../asterisk/services/asterisk.service';
import {AsteriskStateModel} from '../../../asterisk/states/asterisk-state/asterisk-state.model';
import {ToCall} from '../../../asterisk/states/asterisk-state/asterisk.actions';
import {AsteriskState} from '../../../asterisk/states/asterisk-state/asterisk.state';
import {AddTab} from '../../../main/components/tabs/tabs.action';
import {TabCategoriesModel, TabsStructure} from '../../../main/components/tabs/tabs.models';

@Component({
    selector: 'app-input-tel',
    templateUrl: './input-tel.component.html',
    styleUrls: ['./input-tel.component.scss'],
})
export class InputTelComponent {

    @Select(AsteriskState.toCallStatus) toCallStatus$: Observable<PROGRESS_STATUSES>;
    @Select(AsteriskState.connectStatus) connectStatus$: Observable<AsteriskStateModel['connectStatus']>;

    @Output() tel = new EventEmitter<TelModel>();

    public readonly progressStatuses = PROGRESS_STATUSES;
    public readonly connectWs = CONNECT_STATUS_WS;
    public telStr: string;

    constructor(private store: Store, private serviceAsterisk: AsteriskService) {
    }

    private _telNumber: TelModel;

    get telNumber(): TelModel {
        return this._telNumber;
    }

    @Input() set telNumber(tel: TelModel) {
        this._telNumber = tel;
        this.telStr = parseTel(this.telNumber).replace(/\-/g, '');
    }

    changeTel(tel: string) {
        this.tel.emit(parseStringToTel(tel));
    }

    toCall() {
        if (this.store.selectSnapshot(CoreState.isDesktop)) {
            this.store.dispatch(new ToCall(this.telStr));
        } else {
            this.serviceAsterisk.toCall(this.telStr)
                .subscribe({
                    next: () => window.location.href = `tel:${this.telStr}`,
                });
        }
    }

    openCustomerCard() {
        const tab: TabsStructure = {
            label: `Карта клієнта: ${this.telStr}`,
            url: `/dashboard/${TabCategoriesModel.CustomerCard}/${this.telStr}`,
            uid: `customer-card_${this.telStr}`,
            id: this.telStr,
            app: TabCategoriesModel.CustomerCard,
            isChanged: false,
        };
        this.store.dispatch(new AddTab(tab));
    }
}
