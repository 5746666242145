import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-search-selector',
    templateUrl: './search-selector.component.html',
    styleUrls: ['./search-selector.component.scss'],
})
export class SearchSelectorComponent implements OnInit, OnDestroy {

    @Input() placeholder = 'search';
    @Input() label = 'selector';
    @Input() required = false;
    @Output() selectedValue = new EventEmitter<any>();
    @Output() formControl = new EventEmitter<FormControl>();

    selectorFormControl = new FormControl();
    choicesLocal$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    choices$!: Observable<any[]>;
    viewFieldNameLocal = 'name';
    valueFieldNameLocal = 'id';

    searchControl = new FormControl('');

    readonly subscription = new Subscription();

    @Input() set valueFieldName(value: string) {
        this.valueFieldNameLocal = value;
    }

    @Input() set viewFieldName(value: string) {
        this.viewFieldNameLocal = value;
    }

    @Input() set choices(value: any[] | null) {
        if (value) {
            this.choicesLocal$.next(value);
        }
    }

    @Input() set default(value: any) {
        if (value) {
            this.selectorFormControl.setValue(value);
        }
    }

    ngOnInit() {
        this.formControl.emit(this.selectorFormControl);
        if (this.required) {
            this.selectorFormControl.setValidators([Validators.required]);
        }

        this.subscription.add(
            this.searchControl.valueChanges
                .pipe(
                    startWith(''),
                )
                .subscribe({
                    next: value => this.choices$ = this.filter(value),
                }),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    selectValue(choiceElement: any) {
        this.selectedValue.next(choiceElement);
    }

    private filter(value: string): Observable<any[]> {
        return this.choicesLocal$.asObservable()
            .pipe(
                map(items => {
                    return (items.map(item => item).filter(item => item[this.viewFieldNameLocal].includes(value)));
                }),
            );
    }
}
