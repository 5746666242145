import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {StorageModel} from '../../../receipt/symbols';
import {
    StorageCreateFolderDialogComponent,
} from '../../components/storage-create-folder-dialog/storage-create-folder-dialog.component';
import {
    StorageCreateGoodsDialogComponent,
} from '../../components/storage-create-goods-dialog/storage-create-goods-dialog.component';
import {GetGoodsIntoFolder, GetTreeFolders} from '../../states/storage-list-state/storage-list.actions';
import {StorageListStateModel} from '../../states/storage-list-state/storage-list.models';
import {StorageListState} from '../../states/storage-list-state/storage-list.state';
import {ClearCurrentGoods, CreateFolder, CreateGoods} from '../../states/storage-manage-state/storage-manage.actions';

@Component({
    selector: 'app-storage-list-root',
    templateUrl: './storage-list-root.component.html',
    styleUrls: ['./storage-list-root.component.scss'],
})
export class StorageListRootComponent implements OnInit, OnDestroy {
    @Select(StorageListState.loadTreeFoldersState) loadTreeFoldersState$: Observable<StorageListStateModel['loadTreeFoldersState']>;
    @Select(StorageListState.loadGoodsStatus) loadGoodsStatus$: Observable<StorageListStateModel['loadGoodsStatus']>;

    filter$ = new BehaviorSubject(null);
    folderParentId: number;
    public idFolder$ = new BehaviorSubject<number>(null);
    public readonly progressStatuses = PROGRESS_STATUSES;
    private readonly subscription = new Subscription();


    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { isDialog: boolean },
        @Optional() private dialogRef: MatDialogRef<StorageListRootComponent>,
    ) {
    }

    ngOnInit(): void {

        this.store.dispatch(new ClearCurrentGoods());

        if (this.data?.isDialog) {
            this.subscription.add(
                combineLatest([this.filter$, this.idFolder$])
                    .subscribe({
                        next: ([value, idFolder]) => {
                            this.folderParentId = idFolder || null;
                            this.store.dispatch([new GetTreeFolders(idFolder), new GetGoodsIntoFolder(idFolder, value)]);
                        },
                    }),
            );
        } else {

            this.subscription.add(
                combineLatest([this.filter$, this.route.params])
                    .subscribe({
                        next: ([value, params]) => {
                            this.folderParentId = params?.folderId || null;
                            this.store.dispatch([new GetTreeFolders(params?.folderId), new GetGoodsIntoFolder(params?.folderId, value)]);
                        },
                    }),
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setFilter(filterText: string) {
        this.filter$.next(filterText);
    }

    createFolder(): void {
        this.dialog.open<StorageCreateFolderDialogComponent>(StorageCreateFolderDialogComponent)
            .afterClosed()
            .pipe(take(1), filter(item => !!item))
            .subscribe({
                next: folderName =>
                    this.store.dispatch(new CreateFolder({
                        name: folderName,
                        parent_id: this.folderParentId,
                    })),
            });
    }

    createGoods(): void {
        this.dialog.open<StorageCreateGoodsDialogComponent>(StorageCreateGoodsDialogComponent)
            .afterClosed()
            .pipe(take(1), filter(item => !!item))
            .subscribe({
                next: value => this.store.dispatch(new CreateGoods({...value, parent_id: this.folderParentId})),
            });
    }


    selectItem(good: StorageModel) {
        this.dialogRef?.close(good);
    }
}
