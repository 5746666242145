import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {QrCodeModel} from '../../barcode/qr-code.model';
import {DataForTalonPrintingModel, PrintTalonAct, PrintTalonContract, TalonModel, WarrantyModel} from '../talon.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TalonService {
    api = '/api/talons/';

    constructor(private http: HttpClient) {
    }

    getTalonById(id: string): Observable<TalonModel> {
        return this.http.get<TalonModel>(`${this.api}/talon/${id}/`);
    }

    getWarranty(dateDoc: string, dateLaunch: string, guaranteePeriod: number): Observable<WarrantyModel> {
        return this.http.get<WarrantyModel>(`${this.api}warranty`, {
            params: {
                dateDoc,
                dateLaunch,
                guaranteePeriod: guaranteePeriod ? guaranteePeriod : 0,
            },
        });
    }

    insertTalonAccordingToQrCode(qrCode: QrCodeModel): Observable<TalonModel> {
        return this.http.post<TalonModel>(this.api + `talon-according-by-qr-code/`, qrCode);
    }


    saveInDB(talon: TalonModel): Observable<TalonModel> {
        return this.http.post<TalonModel>(this.api + 'talon/', talon);
    }

    updateTalon(talon: TalonModel): Observable<TalonModel> {
        return this.http.patch<TalonModel>(this.api + `talon/${talon.id}/`, talon);
    }

    getNumber(): Observable<number> {
        return this.http.get<number>(this.api + 'get-numerate/');
    }

    downgradeNumerate(numberTalon: number): Observable<any> {
        return this.http.patch(this.api + 'downgrade-numerate/', {number: numberTalon});
    }

    printAct(data: PrintTalonAct): Observable<string> {
        return this.http.post<{ url: string }>(`${this.api}talon/print-act/${data.id}`, data).pipe(map(v => v.url));
    }

    printContract(data: PrintTalonContract): Observable<string> {
        return this.http.post<{ url: string }>(`${this.api}talon/print-contract/${data.id}`, data).pipe(map(v => v.url));
    }

    printTalon(data: DataForTalonPrintingModel): Observable<string> {
        return this.http.post<{ url: string }>(`${this.api}talon/print-talon/${data.id}`, data).pipe(map(v => v.url));
    }
}
