import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { DataPickerComponent } from './components/data-picker/data-picker.component';




@NgModule({
    declarations: [
        DataPickerComponent
    ],
    exports: [
        DataPickerComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatInputModule
    ]
})
export class DataPickerModule { }
