import {TelModel} from '../core/models/tel.models';

export enum Sort {
    CREATED = 'date',
    NUMBER = 'number',
}

export enum Order {
    DESC = 'DESC',
    ASC = 'ASC',
}


export interface SortOrder {
    sort: Sort;
    order: Order;
}


export interface ResponseMysqlModel {
    affectedRows: number;
    fieldCount: number;
    info: string;
    insertId: number;
    serverStatus: number;
    warningStatus: number;
}

export const parseStringToTel = (tel: string): TelModel => {
    return {
        area: tel?.slice(0, 3),
        exchange: tel?.slice(3, 6),
        subscriber: tel?.slice(6, 10),
    };
};

export const parseTel = (tel: TelModel): string => `${tel.area}-${tel.exchange}-${tel.subscriber}`;
