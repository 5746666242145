import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {DateTime} from 'luxon';
import {CoreState} from '../../../../core/states/core/core.state';
import {ShowToast} from '../../../../core/states/toast/toast.action';
import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {AddTab, CloseTabByUID} from '../../../main/components/tabs/tabs.action';
import {TabCategoriesModel, TabsStructure} from '../../../main/components/tabs/tabs.models';
import {ReceiptService} from '../../../receipt/receipt.service';
import {StorageManageService} from '../../services/storage-manage.service';
import {Appointment, StorageMovementModel} from '../../storage.symbols';
import {
    ClearCurrentGoods,
    CreateFolder,
    CreateFolderFail,
    CreateFolderSuccess,
    CreateGoods,
    CreateGoodsFail,
    CreateGoodsSuccess,
    CreateMovementGoods,
    GetGoodsById,
    GetGoodsByIdFail,
    GetGoodsByIdSuccess,
    InsertMovementGoods,
    InsertMovementGoodsFail,
    InsertMovementGoodsSuccess,
    LoadMovementGoods,
    LoadMovementGoodsFail,
    LoadMovementGoodsSuccess,
    PrintMovementGoodsReceipt,
    ResetUpdateGoodsStatus,
    SetCurrentGoods,
    UpdateFolder,
    UpdateFolderFail,
    UpdateFolderSuccess,
    UpdateGoods,
    UpdateGoodsFail,
    UpdateGoodsSuccess,
    UpdateMovementGoods,
    UpdateMovementGoodsFail,
    UpdateMovementGoodsSuccess,
} from './storage-manage.actions';
import {STORAGE_MANAGE_STATE_DEFAULT, StorageManageStateModel} from './storage-manage.models';

@State<StorageManageStateModel>({
    name: 'storageManage',
    defaults: STORAGE_MANAGE_STATE_DEFAULT,
})
@Injectable()
export class StorageManageState {

    constructor(private service: StorageManageService, private receiptService: ReceiptService, private store: Store) {
    }

    @Selector()
    static updateGoodsStatus(state: StorageManageStateModel): StorageManageStateModel['updateGoodsStatus'] {
        return state.updateGoodsStatus;
    }

    @Selector()
    static currentGoods(state: StorageManageStateModel): StorageManageStateModel['currentGoods'] {
        return state.currentGoods;
    }

    @Selector()
    static currentMovementGoods(state: StorageManageStateModel): StorageManageStateModel['currentMovementGoods'] {
        return state.currentMovementGoods;
    }


    @Action(GetGoodsById)
    getGoodsById(ctx: StateContext<StorageManageStateModel>, {id}: GetGoodsById) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.getGoodsById(id).subscribe({
            next: goods => ctx.dispatch(new GetGoodsByIdSuccess(goods)),
            error: err => ctx.dispatch(new GetGoodsByIdFail(err)),
        });
    }


    @Action(GetGoodsByIdSuccess)
    getGoodsByIdSuccess(ctx: StateContext<StorageManageStateModel>, {goods}: GetGoodsByIdSuccess) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.SUCCESS,
            currentGoods: goods,
        });
    }

    @Action(GetGoodsByIdFail)
    getGoodsByIdFail(ctx: StateContext<StorageManageStateModel>, {error}: GetGoodsByIdFail) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: 'Не вдалось завантажити позицію.',
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(ClearCurrentGoods)
    clearCurrentGoods(ctx: StateContext<StorageManageStateModel>) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
            currentGoods: null,
        });

    }

    @Action(UpdateGoods)
    updateGoods(ctx: StateContext<StorageManageStateModel>, {goods, id}: UpdateGoods) {
        ctx.patchState({
            updateGoodsStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });

        this.service.updateGoods(id, goods).subscribe({
            next: value => ctx.dispatch(new UpdateGoodsSuccess(value)),
            error: err => ctx.dispatch(new UpdateGoodsFail(err)),
        });

    }

    @Action(UpdateGoodsSuccess)
    updateGoodsSuccess(ctx: StateContext<StorageManageStateModel>, {goods}: UpdateGoodsSuccess) {
        ctx.patchState({
            updateGoodsStatus: PROGRESS_STATUSES.SUCCESS,
        });
    }


    @Action(UpdateGoodsFail)
    updateGoodsFail(ctx: StateContext<StorageManageStateModel>, {error}: UpdateGoodsFail) {
        ctx.patchState({
            updateGoodsStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: 'Не вдалось оновити позицію.',
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(SetCurrentGoods)
    setCurrentGoods(ctx: StateContext<StorageManageStateModel>, {goods}: SetCurrentGoods) {
        ctx.patchState({
            currentGoods: goods,
        });

    }

    @Action(ResetUpdateGoodsStatus)
    resetUpdateGoodsStatus(ctx: StateContext<StorageManageStateModel>) {
        ctx.patchState({
            updateGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
        });

    }

    @Action(CreateFolder)
    createFolder(ctx: StateContext<StorageManageStateModel>, {folder}: CreateFolder) {
        ctx.patchState({
            createFolderStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });

        this.service.createFolder(folder).subscribe({
            next: res => ctx.dispatch(new CreateFolderSuccess(res)),
            error: err => ctx.dispatch(new CreateFolderFail(err)),
        });

    }

    @Action(CreateFolderSuccess)
    createFolderSuccess(ctx: StateContext<StorageManageStateModel>) {
        ctx.patchState({
            createFolderStatus: PROGRESS_STATUSES.SUCCESS,
        });
    }

    @Action(CreateFolderFail)
    createFolderFail(ctx: StateContext<StorageManageStateModel>, {error}: CreateFolderFail) {
        ctx.patchState({
            createFolderStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось створити папку.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(UpdateFolder)
    updateFolder(ctx: StateContext<StorageManageStateModel>, {folder}: UpdateFolder) {
        ctx.patchState({
            updateFolderStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });

        this.service.updateFolder(folder).subscribe({
            next: res => ctx.dispatch(new UpdateFolderSuccess(res)),
            error: err => ctx.dispatch(new UpdateFolderFail(err)),
        });
    }


    @Action(UpdateFolderSuccess)
    UpdateFolderSuccess(ctx: StateContext<StorageManageStateModel>, {folder}: UpdateFolderSuccess) {
        ctx.patchState({
            updateFolderStatus: PROGRESS_STATUSES.SUCCESS,
        });
    }


    @Action(UpdateFolderFail)
    updateFolderFail(ctx: StateContext<StorageManageStateModel>, {error}: UpdateFolderFail) {
        ctx.patchState({
            updateFolderStatus: PROGRESS_STATUSES.ERROR,
        });


        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось оновити папку.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(CreateGoods)
    createGoods(ctx: StateContext<StorageManageStateModel>, {goods}: CreateGoods) {
        ctx.patchState({
            createGoodsState: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.createGoods(goods).subscribe({
            next: res => ctx.dispatch(new CreateGoodsSuccess(res)),
            error: err => ctx.dispatch(new CreateGoodsFail(err)),
        });
    }


    @Action(CreateGoodsSuccess)
    createGoodsSuccess(ctx: StateContext<StorageManageStateModel>, {goods}: CreateGoodsSuccess) {
        ctx.patchState({
            createGoodsState: PROGRESS_STATUSES.SUCCESS,
        });
    }

    @Action(CreateGoodsFail)
    createGoodsFail(ctx: StateContext<StorageManageStateModel>, {error}: CreateGoodsFail) {
        ctx.patchState({
            createGoodsState: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось створити позицію.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(LoadMovementGoods)
    loadMovementGoods(ctx: StateContext<StorageManageStateModel>, {id}: LoadMovementGoods) {
        ctx.patchState({
            loadMovementGoodsStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        const movement: StorageMovementModel = {
            type_movement: undefined,
            date: DateTime.now().toISO(),
            receipt: [],
            name: '',
            count: 0,
            art: '',
            price: 0,
            sum: 0,
            appointment: Appointment.Free,
            is_do_not_move: false,
            priceOpt: 0,
            id,
            isNew: true,

        };

        this.service.getMovementGoods(id).subscribe({
            next: res => ctx.dispatch(new LoadMovementGoodsSuccess(res ? res : movement)),
            error: err => ctx.dispatch(new LoadMovementGoodsFail(err)),
        });
    }

    @Action(LoadMovementGoodsSuccess)
    loadMovementGoodsSuccess(ctx: StateContext<StorageManageStateModel>, {goods}: LoadMovementGoodsSuccess) {

        ctx.patchState({
            loadMovementGoodsStatus: PROGRESS_STATUSES.SUCCESS,
            currentMovementGoods: goods,
        });
    }

    @Action(LoadMovementGoodsFail)
    loadMovementGoodsFail(ctx: StateContext<StorageManageStateModel>, {error}: LoadMovementGoodsFail) {
        ctx.patchState({
            loadMovementGoodsStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось завантажити товари.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(PrintMovementGoodsReceipt)
    printMovementGoodsReceipt(ctx: StateContext<StorageManageStateModel>) {
        const currentMovement = ctx.getState().currentMovementGoods;
        this.receiptService.printReceipt({
            receipt: currentMovement.receipt,
            id: String(currentMovement.id),
            receipt_id: +currentMovement.id,
            info_for_receipt: this.store.selectSnapshot(CoreState.getInfoForReceipt),
            date: currentMovement.date.toString(),
        });
    }


    @Action(UpdateMovementGoods)
    updateMovementGoods(ctx: StateContext<StorageManageStateModel>, {goods, id}: UpdateMovementGoods) {
        ctx.patchState({
            updateMovementStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.updateMovementGoods(id, goods).subscribe({
            next: res => ctx.dispatch(new UpdateMovementGoodsSuccess(res)),
            error: err => ctx.dispatch(new UpdateMovementGoodsFail(err)),
        });
    }

    @Action(UpdateMovementGoodsSuccess)
    updateMovementGoodsSuccess(ctx: StateContext<StorageManageStateModel>, {goods}: UpdateMovementGoodsSuccess) {
        ctx.patchState({
            updateMovementStatus: PROGRESS_STATUSES.SUCCESS,
            currentMovementGoods: goods,
        });


        ctx.dispatch(new ShowToast({
            detail: 'Запис успішно оновлено.',
            summary: 'Успіх!',
            severity: 'info',
        }));
    }

    @Action(UpdateMovementGoodsFail)
    updateMovementGoodsFail(ctx: StateContext<StorageManageStateModel>, {error}: UpdateMovementGoodsFail) {
        ctx.patchState({
            updateMovementStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось оновити товари.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(CreateMovementGoods)
    createMovementGoods(ctx: StateContext<StorageManageStateModel>, {goods}: CreateMovementGoods) {
        ctx.patchState({currentMovementGoods: goods});
        ctx.dispatch(new AddTab({
            label: 'Надходження/витрати №' + goods.id,
            url: `/dashboard/movement-goods/movement-manage/${goods.id}`,
            isChanged: false,
            app: TabCategoriesModel.Movement,
            uid: `${TabCategoriesModel.Movement}_${goods.id}`,
        }));
    }


    @Action(InsertMovementGoods)
    insertMovementGoods(ctx: StateContext<StorageManageStateModel>, {goods}: InsertMovementGoods) {
        ctx.patchState({
            insertMovementStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.insertMovementGoods(goods).subscribe({
            next: res => ctx.dispatch(new InsertMovementGoodsSuccess(res, goods)),
            error: err => ctx.dispatch(new InsertMovementGoodsFail(err)),
        });
    }

    @Action(InsertMovementGoodsSuccess)
    insertMovementGoodsSuccess(ctx: StateContext<StorageManageStateModel>, {goods, previousGoods}: InsertMovementGoodsSuccess) {

        if (previousGoods.isNew) {
            const tab: TabsStructure = {
                label: 'Надходження/витрати №' + goods.id,
                url: `/dashboard/movement-goods/movement-manage/${goods.id}`,
                isChanged: false,
                app: TabCategoriesModel.Movement,
                uid: `${TabCategoriesModel.Movement}_${goods.id}`,
                id: goods.id,
            };

            ctx.dispatch(
                [
                    new CloseTabByUID(`${TabCategoriesModel.Movement}_${previousGoods.id}`),
                    new AddTab(tab),
                ],
            );
        }

        ctx.patchState({
            insertMovementStatus: PROGRESS_STATUSES.SUCCESS,
            currentMovementGoods: goods,
        });
        ctx.dispatch(new ShowToast({
            detail: 'Запис успішно створено.',
            summary: 'Успіх!',
            severity: 'success',
        }));
    }

    @Action(InsertMovementGoodsFail)
    insertMovementGoodsFail(ctx: StateContext<StorageManageStateModel>, {error}: InsertMovementGoodsFail) {
        ctx.patchState({
            insertMovementStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: `${error.error.message || 'Не вдалось створити товари.'}`,
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }
}
