import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Order, Sort} from '../../../shared/simbol';
import {AmountOrders, OrderFiltersModel, OrdersListModel} from '../state/orders-list/orders-list.model';

@Injectable()
export class OrdersListService {
    constructor(private http: HttpClient) {
    }


    getOrdersList(
        sort: Sort,
        order: Order,
        first: number,
        count: number,
        filter: OrderFiltersModel,
    ): Observable<OrdersListModel> {

        const api = '/api/orders';
        const requestUrl = `${api}?sort=${sort}&order=${order}&first=${first}
        &count=${count}
        &empty=${filter.null}&accepted=${filter.accepted}&canceled=${
            filter.canceled
        }&debt=${filter.debt}&done=${filter.done}&important=${filter.important}&issued=${
            filter.issued
        }&await_confirm=${filter.waiting}&searchMode=${filter.searchMode}&search=${
            filter.searchText
        }&master=${filter.master}`;
        return this.http.get<OrdersListModel>(requestUrl);


    }


    getIssuedToday(
        sort: Sort,
        order: Order,
    ): Observable<OrdersListModel> {
        const api = '/api/orders/issued-today';
        const requestUrl = `${api}?sort=${sort}&order=${order}`;
        return this.http.get<OrdersListModel>(requestUrl);
    }

    getAmountOrders(): Observable<AmountOrders> {
        const api = '/api/orders/amount-orders';
        return this.http.get<AmountOrders>(api);
    }


    getLimitOrders(): Observable<number> {
        const api = '/api/orders/limit-orders';
        return this.http.get<number>(api);
    }

    setLimitOrders(limit: number): Observable<number> {
        const api = '/api/orders/limit-orders';
        return this.http.patch<number>(api, {limit});
    }


}
