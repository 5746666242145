<div class="remove-padding">
<h1 mat-dialog-title>Документи <mat-icon>policy</mat-icon></h1>

<div mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource.story"
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="nameDoc">
      <th mat-header-cell *matHeaderCellDef>Документ</th>
      <td mat-cell *matCellDef="let element" [class.debt]="element.debtFlag">
        {{element.nameDoc}} №{{element.number}}


      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Дата</th>
      <td mat-cell *matCellDef="let element" [class.debt]="element.debtFlag">
        {{element.dateDocument | date: "dd.MM.yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>Стан</th>
      <td mat-cell *matCellDef="let element" [class.debt]="element.debtFlag">


        <div *ngIf="element.state?.state==='isDeactivated'">Розірваний</div>
        <div *ngIf="element.state?.state!=='isDeactivated'">{{element.state?.state}}</div>


      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex;"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
        >





          <div class="moreTable">
            <div class="example-element-description">
              <div class="moreTable">
                <div class="user">
                  <p>
                    <span *ngIf="element.user !== ''">Користувач: </span>{{
                    element.user }}
                  </p>
                  <p>
                    <span *ngIf="element.tel">Телефон: </span>
                    {{element.tel.area}}{{element.tel.exchange}}{{element.tel.subscriber}}
                    <span *ngIf="element.tel2.area !== ''"> / Додатковий: </span>
                    {{element.tel2.area}}{{element.tel2.exchange}}{{element.tel2.subscriber}}
                  </p>

                  <div
                    style="padding: 10px 10px 2px 10px; border-radius: 5px"
                    [class.vin]="0 === element.address.county.indexOf('Вінниц')"
                    [class.tulchin]="
                        0 === element.address.county.indexOf('Тульчин') ||
                        0 === element.address.county.indexOf('Оратів') ||
                        0 === element.address.county.indexOf('Вапнярка')
                      "
                    [class.litin]="0 === element.address.county.indexOf('Літин')"
                    [class.khmilnik]="0 === element.address.county.indexOf('Хмільни')"
                  >
                    <p>
                      {{ element.address.county }} | {{ element.address.city }} | {{
                      element.address.street }}
                    </p>
                  </div>
                </div>

                <div class="infoDoc">
                  <p>
                    <span *ngIf="element.ketel.name !== ''">Обладнання: </span>{{
                    element.ketel.name }}
                    <span *ngIf="element.ketel.sn !== ''"> / sn: </span>{{ element.sn
                    }} <span *ngIf="element.ketel.kWt !== ''"> / кВт: </span>{{
                    element.ketel.kWt }}
                  </p>

                  <p *ngIf="element.debtFlag" style="color: red">
                    <span>Борг: </span>{{ element.debt }} грн.
                  </p>

                  <p *ngIf="element.reason !== '' && element.reason ">
                    <span>Причина виклику: </span>{{ element.reason }}
                  </p>
                  <p *ngIf="element.conclusion !== '' && element.conclusion">
                    <span>Заключення: </span>{{ element.conclusion }}
                  </p>

                  <p *ngIf="element.master">
                    <span>Майстер: </span> {{ element.master }}
                  </p>
                </div>

                <button
                  mat-raised-button
                  mat-dialog-close
                  [mat-dialog-close]="i"
                >
                  Заповнити
                </button>
              </div>
            </div>
          </div>






        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
      [class.аccepted]="element.state?.state === 'Прийнята'"
      [class.іssued]="element.state?.state  === 'Видана'"
      [class.waiting]="element.state?.state  === 'Очікує підтвердження'"
      [class.canceled]="element.state?.state  === 'Відмінена'"
      [class.іssuedІndependently]="element.state?.state  === 'Видана самостійно'"
      [class.isDeactive]="element.state?.state  === 'isDeactivated'"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>

  </table>

</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()">ОК</button>
</mat-dialog-actions>
</div>
