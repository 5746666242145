import {Injectable} from '@angular/core';
import {Navigate} from '@ngxs/router-plugin';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {DateTime} from 'luxon';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ClearTabs} from '../../../modules/main/components/tabs/tabs.action';
import {ClearOrder} from '../../../modules/orders/state/order/orders.action';
import {ClearOrderList} from '../../../modules/orders/state/orders-list/orders-list.action';
import {PROGRESS_STATUSES} from '../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {AuthService} from '../../services/auth.service';
import {ClearCoreState, InitCoreState, SetPlatform} from '../core/core.action';
import {ShowToast} from '../toast/toast.action';
import {
    GetPermissions,
    GetPermissionsFail,
    GetPermissionsSuccess,
    Login,
    LoginFail,
    LoginSuccess,
    LoginWidthTelegram,
    LoginWidthTelegramAccess,
    LoginWidthTelegramFail,
    LoginWidthTelegramSuccess,
    Logout,
    SetOnlineList,
    SetToken,
} from './auth.action';
import {AUTH_STATE_DEFAULT, AuthStateModel, RoleModel, UserModel} from './auth.model';

@State<AuthStateModel>({
    name: 'authState',
    defaults: AUTH_STATE_DEFAULT,
})
@Injectable()
export class AuthState implements NgxsOnInit {

    constructor(private service: AuthService, private deviceService: DeviceDetectorService) {
    }

    @Selector()
    static isRefusal(state: AuthStateModel): boolean {
        return state.isRefusal;
    }

    @Selector()
    static onlineList(state: AuthStateModel): AuthStateModel['onlineList'] {
        return state.onlineList.sort((a, b) => {

            if (a.online !== b.online) {
                return a.online ? -1 : 1;
            }
            return DateTime.fromISO(b.dateOnline).toMillis() - DateTime.fromISO(a.dateOnline).toMillis();
        });
    }

    @Selector()
    static isAuthError(state: AuthStateModel): PROGRESS_STATUSES {
        return state.userAuthError;
    }

    @Selector()
    static isAuthenticate(state: AuthStateModel): boolean {
        return !!state.user.rolesParams;
    }

    @Selector()
    static loginWidthTelegramStatus(state: AuthStateModel): PROGRESS_STATUSES {
        return state.loginWidthTelegramStatus;
    }

    @Selector()
    static token(state: AuthStateModel): string {
        return state.user.token;
    }

    @Selector()
    static user(state: AuthStateModel): UserModel {
        return state.user;
    }

    @Selector()
    static access(state: AuthStateModel): RoleModel {
        return state.user.rolesParams;
    }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, {data}: Login): void {
        ctx.patchState({userAuthError: PROGRESS_STATUSES.IN_PROGRESS});
        this.service.signIn(data).subscribe({
            next: user => ctx.dispatch(new LoginSuccess(user)),
            error: err => ctx.dispatch(new LoginFail(err)),
        });
    }

    @Action(LoginWidthTelegram)
    loginByTelegram(ctx: StateContext<AuthStateModel>, {tel}: LoginWidthTelegram) {
        ctx.patchState({isAccessLoginWidthTelegram: false, loginWidthTelegramStatus: PROGRESS_STATUSES.IN_PROGRESS, isRefusal: false});
        this.service.authWidthTelegram(tel).subscribe({
            next: v => ctx.dispatch(new LoginWidthTelegramSuccess(v.uuid, v.isExistTelegram)),
            error: err => ctx.dispatch(new LoginWidthTelegramFail(err)),
        });
    }

    @Action(LoginWidthTelegramSuccess)
    loginByTelegramSuccess(ctx: StateContext<AuthStateModel>, {uuid, isExistTelegram}: LoginWidthTelegramSuccess) {
        if (!isExistTelegram) {
            ctx.patchState({loginWidthTelegramStatus: PROGRESS_STATUSES.ERROR});
        }
        ctx.patchState({isAccessLoginWidthTelegram: isExistTelegram, uuidForLoginWidthTelegram: uuid});
    }

    @Action(LoginWidthTelegramFail)
    loginByTelegramFail(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({loginWidthTelegramStatus: PROGRESS_STATUSES.ERROR});
    }


    @Action(LoginWidthTelegramAccess)
    loginWidthTelegram(ctx: StateContext<AuthStateModel>, {data}: LoginWidthTelegramAccess) {
        if (data.isAccess && ctx.getState().uuidForLoginWidthTelegram) {
            this.service.getAuthWidthTelegram(ctx.getState().uuidForLoginWidthTelegram, data.tel).subscribe({
                next: v => ctx.dispatch(new LoginSuccess(v)),
                error: err => ctx.dispatch(new LoginFail(err)),
            });
        } else if (!data.isAccess) {
            ctx.patchState({isRefusal: true});
        }
    }


    @Action(LoginSuccess)
    loginSuccess(ctx: StateContext<AuthStateModel>, {user}: LoginSuccess): void {
        ctx.patchState({user, userAuthError: PROGRESS_STATUSES.SUCCESS, uuidForLoginWidthTelegram: null});
        ctx.dispatch([
            new InitCoreState(),
            new Navigate(['/dashboard'])],
        );
        ctx.dispatch(new SetPlatform(!(this.deviceService.isTablet() || this.deviceService.isMobile())));
    }

    @Action(LoginFail)
    loginFail(ctx: StateContext<AuthStateModel>, {error}: LoginFail) {
        ctx.patchState({
            userAuthError: PROGRESS_STATUSES.ERROR,
            loginWidthTelegramStatus: PROGRESS_STATUSES.ERROR,
            uuidForLoginWidthTelegram: null,
        });
        ctx.dispatch(new ShowToast({severity: 'error', summary: 'Сталась помилка!', detail: error.error.message}));
    }


    @Action(SetToken)
    setToken(ctx: StateContext<AuthStateModel>, {token}: SetToken) {
        const user = {...ctx.getState().user, token};
        ctx.patchState({user});
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.setState(AUTH_STATE_DEFAULT);
        ctx.dispatch([
            new ClearCoreState(),
            new ClearTabs(),
            new ClearOrder(),
            new ClearOrderList(),
            new Navigate(['/login']),
        ]);
    }

    @Action(GetPermissions)
    getPermissions(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({
            loadPermissionsStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.loadRolesParams().subscribe({
            next: roles => ctx.dispatch(new GetPermissionsSuccess(roles)),
            error: err => ctx.dispatch(new GetPermissionsFail(err)),
        });
    }

    @Action(GetPermissionsSuccess)
    getPermissionsSuccess(ctx: StateContext<AuthStateModel>, {roles}: GetPermissionsSuccess) {
        ctx.setState(patch<AuthStateModel>({
                loadPermissionsStatus: PROGRESS_STATUSES.SUCCESS,
                user: patch<UserModel>({
                    rolesParams: roles,
                }),
            }),
        );
    }

    @Action(GetPermissionsFail)
    getPermissionsFail(ctx: StateContext<AuthStateModel>, {error}: GetPermissionsFail) {
        ctx.patchState({
            loadPermissionsStatus: PROGRESS_STATUSES.SUCCESS,
        });

        ctx.dispatch(new ShowToast({
            severity: 'error',
            summary: 'Сталась помилка!',
            detail: 'Не вдалось отримати рівні доступу користувача, спробуйте пізніше.',
        }));

        ctx.patchState({
            user: null,
        });
    }

    @Action(SetOnlineList)
    setOnlineList(ctx: StateContext<AuthStateModel>, {list}: SetOnlineList) {
        ctx.patchState({
            onlineList: list,
        });
    }

    ngxsOnInit(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({uuidForLoginWidthTelegram: null, loginWidthTelegramStatus: PROGRESS_STATUSES.NOT_INITIALIZE});
    }
}
