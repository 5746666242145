import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxsModule} from '@ngxs/store';
import {StorageListService} from '../storage/services/storage-list.service';
import {StorageListState} from '../storage/states/storage-list-state/storage-list.state';
import {ReceiptComponent} from './components/receipt/receipt.component';
import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ReceiptService} from './receipt.service';
import {GoodsSelectorComponent} from './components/goods-selector/goods-selector.component';
import { MatGoodsSelectorComponent } from './components/mat-goods-selector/mat-goods-selector.component';


@NgModule({
    declarations: [
        ReceiptComponent,
        GoodsSelectorComponent,
        MatGoodsSelectorComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        RippleModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        AutoCompleteModule,
        NgxsModule.forFeature([StorageListState])
    ],
    exports: [
        GoodsSelectorComponent,
        MatGoodsSelectorComponent,
        ReceiptComponent
    ],
    providers: [ReceiptService, StorageListService]
})
export class ReceiptModule {
}
