import {Order, Sort} from '../../../../shared/simbol';
import {OrderModel} from '../../order.models';
import {AmountOrders, OrderFiltersModel, OrdersListModel, SplitterModel} from './orders-list.model';


export class UpdateListWS {
    static type = '[OrdersList] UpdateListWS';

    constructor(public order?: OrderModel) {
    }
}

export class InsertListWS {
    static type = '[OrdersList] InsertListWS';

    constructor(public order?: OrderModel) {
    }
}


export class GetOrdersList {
    static type = '[OrdersList] GetOrdersList';

    constructor(public lazyLoad?: boolean, public count?: number, public sort?: Sort, public order?: Order) {
    }
}

export class GetOrdersListSuccess {
    static type = '[OrdersList] GetOrdersListSuccess';

    constructor(public orders: OrdersListModel, public lazyLoad?: boolean) {
    }
}

export class GetOrdersListFail {
    static type = '[OrdersList] GetOrdersListFail';

    constructor(public error: any) {
    }
}


export class SetOrderFilter {
    static type = '[OrdersList] SetOrderFilter';

    constructor(public filter: OrderFiltersModel) {
    }
}


export class GetIssuedTodayOrdersList {
    static type = '[OrdersList] GetIssuedTodayOrdersList';

    constructor(public sort?: Sort, public order?: Order) {
    }
}

export class GetIssuedTodayOrdersListSuccess {
    static type = '[OrdersList] GetIssuedTodayOrdersListSuccess';

    constructor(public orders: OrdersListModel) {
    }
}

export class GetIssuedTodayOrdersListFail {
    static type = '[OrdersList] GetIssuedTodayOrdersListFail';

    constructor(public error: any) {
    }
}


export class UpdateVerticalSplitter {
    static type = '[OrdersList] UpdateVerticalSplitter';

    constructor(public splitter: SplitterModel) {
    }
}


export class UpdateHorizontalSplitter {
    static type = '[OrdersList] UpdateHorizontalSplitter';

    constructor(public splitter: SplitterModel) {
    }
}

export class UpdateScrollPositionOrderList {
    static type = '[OrdersList] UpdateScrollPositionOrderList';

    constructor(public position: number) {
    }
}


export class ClearScrollPositionOrderList {
    static type = '[OrdersList] ClearScrollPositionOrderList';

}


export class ClearOrderList {
    static type = '[OrdersList] ClearOrderList';

}


export class GetAmountOrders {
    static type = '[OrdersList] GetAmountOrders';
}

export class GetAmountOrdersSuccess {
    static type = '[OrdersList] GetAmountOrdersSuccess';

    constructor(public amount: AmountOrders) {
    }
}

export class GetAmountOrdersFail {
    static type = '[OrdersList] GetAmountOrdersFail';

    constructor(public error: any) {
    }
}

export class GetLimitOrders {
    static type = '[OrdersList] GetLimitOrders';
}

export class GetLimitOrdersSuccess {

    static type = '[OrdersList] GetLimitOrdersSuccess';

    constructor(public limit: number) {
    }
}

export class GetLimitOrdersFail {
    static type = '[OrdersList] GetLimitOrdersFail';

    constructor(public error: any) {
    }
}


export class SetLimitOrders {
    static type = '[OrdersList] SetLimitOrders';

    constructor(public limit: number) {
    }

}

export class SetLimitOrdersSuccess {
    static type = '[OrdersList] SetLimitOrdersSuccess';

    constructor(public limit: number) {
    }
}

export class SetLimitOrdersFail {
    static type = '[OrdersList] SetLimitOrdersFail';

    constructor(public error: any) {
    }
}


