import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {Subscription} from 'rxjs';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@Component({
    selector: 'app-docs-picker',
    templateUrl: './data-picker.component.html',
    styleUrls: ['./data-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],

})
export class DataPickerComponent implements OnInit, OnDestroy {


    @Input() format = 'YYYY-MM-DD HH:mm:ss';
    @Input() label = 'docs picker';
    @Output() changeDate = new EventEmitter();

    date: FormControl = new FormControl(null);
    subscription = new Subscription();
    width = 'auto';

    constructor(
        private dateAdapter: DateAdapter<any>,
    ) {
        this.dateAdapter.setLocale('uk_UA');
    }

    @Input() set widthPicker(width: number) {
        this.width = width + 'px';
    }


    @Input() set dateDefault(date: string) {
        this.date.setValue(moment(date).toDate(), {emitEvent: false});
    }

    ngOnInit(): void {
        this.subscription.add(this.date.valueChanges.subscribe((value) => {
            this.changeDate.emit(moment(value).utc(true).format(this.format));
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
