<div class="wrapper" *ngIf="localTalon as talon">
    <form [formGroup]="form" *ngIf="(talon.date | warranty: talon.dateLaunch : talon.warranty | async) as warranty">
        <div class="guaranteed" *ngIf="warranty.dateEndedGuarantee && warranty.state === warrantyStatuses.GUARANTEED">
            Гарантія дійсна до: {{ warranty.dateEndedGuarantee | date: "dd.MM.yyyy" }}
        </div>

        <div class="notGuaranteed" *ngIf="warranty.dateEndedGuarantee && warranty.state === warrantyStatuses.NOT_GUARANTEED">
            Гарантія закінчилась: {{ warranty.dateEndedGuarantee | date: "dd.MM.yyyy" }}
        </div>


        <div class="Boxes">
            <mat-form-field
                    appearance="fill"
                    style="margin-right: 10px; max-width: 100px; min-width: 100px"
            >
                <mat-label>Гарантія</mat-label>
                <input type="number" matInput formControlName="warranty"/>
            </mat-form-field>


            <app-date-picker-dmy
                    [label]="'Дата запуску'"
                    [dateIn]="talon.dateLaunch"
                    (dateOut)="changeDate($event)"
            ></app-date-picker-dmy>

        </div>

        <app-mediator-select-input
                [mediatorId]="talon.mediator"
                (selectedMediatorId)="selectMediator($event)">
        </app-mediator-select-input>


        <mat-form-field appearance="fill" class="full-input">
            <mat-label>Ціна</mat-label>
            <input matInput type="number" formControlName="mediator_price"/>
        </mat-form-field>


        <mat-form-field appearance="fill" class="full-input">
            <mat-label>Монтаж</mat-label>
            <input matInput formControlName="assembling"/>
        </mat-form-field>

        <div class="Boxes">

            <mat-checkbox
                    formControlName="debtFlag"
                    class="example-margin"
                    color="warn"
            ></mat-checkbox>

            <mat-form-field
                    appearance="fill"
                    style="margin-right: 10px; max-width: 90px; min-width: 70px"
            >
                <mat-label>Борг</mat-label>
                <input matInput type="number" formControlName="debt"/>
            </mat-form-field>

            <app-docs-picker
                    [dateDefault]="talon.dateDebt"
                    [widthPicker]="180"
                    [label]="'Крайній термін'"
                    (changeDate)="changeDateDebt($event)"
            >

            </app-docs-picker>
        </div>

        <mat-checkbox
                formControlName="isDeactivated"
                color="warn"
                style="margin-right: 10px"
        >Анульовано
        </mat-checkbox
        >


    </form>

</div>
