import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {StorageModel} from '../../../modules/receipt/symbols';

@Component({
    selector: 'app-input-name-receipt',
    templateUrl: './input-name-receipt.component.html',
    styleUrls: ['./input-name-receipt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNameReceiptComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() select: EventEmitter<string> = new EventEmitter();
    @Input() field;

    subscription = new Subscription();
    nameControl = new FormControl();
    nameLoad: BehaviorSubject<StorageModel[]> = new BehaviorSubject([]);

    constructor() {
    }

    ngOnInit(): void {


        this.nameControl.setValue(this.field.value.name);

        this.subscription.add(this.nameControl.valueChanges.pipe(debounceTime(400)).subscribe((v: string) => {


            this.select.emit(v);
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
