<mat-form-field appearance="outline" style="width: 100%;" class="remove-padding-field ">
    <input type="text"
           autocomplete="off"
           aria-label="Number"
           matInput
           [formControl]="control"
           [matAutocomplete]="auto"
    >
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of nameLoad | async" [value]="option" (click)="selected(option)">
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
