import {TelModel} from '../../../../core/models/tel.models';
import {QrCodeModel} from '../../../barcode/qr-code.model';
import {StoriesModel} from '../../../stories/story.model';
import {TalonModel} from '../../talon.model';

export class OpenTalon {
    static type = '[Talon] OpenTalon';

    constructor(public talon?: TalonModel) {
    }
}


export class OpenTalonById {
    static type = '[Talon] OpenTalonById';

    constructor(public id: string) {
    }
}

export class CloseTalon {
    static type = '[Talon] CloseTalon';

    constructor(public uid: string) {
    }
}

export class GetTalonById {
    static type = '[Talon] GetTalonById';

    constructor(public id?: string) {
    }
}

export class GetTalonByIdSuccess {
    static type = '[Talon] GetTalonByIdSuccess';

    constructor(public talon: TalonModel) {
    }
}

export class GetTalonByIdFail {
    static type = '[Talon] GetTalonByIdFail';

    constructor(public error: any) {
    }
}

export class ResetSelectedIndexTalon {
    static type = '[Talon] ResetSelectedIndexTalon';
}


export class UpdateTalonPartial {
    static type = '[Talon] UpdateTalonPartial';

    constructor(public talon: Partial<TalonModel>, public id: string) {
    }

}

export class CloseTalonForce {
    static type = '[Talon] CloseTalonForce';

    constructor(public uid: string) {
    }
}

export class OpenDialogSaveTalon {
    static type = '[Talon] OpenDialogSaveTalon';
}

export class OpenDialogClosedTalon {
    static type = '[Talon] OpenDialogClosedTalon';
}

export class ResetDialogTalon {
    static type = '[Talon] ResetDialogTalon';
}

export class ClearTalon {
    static type = '[Talon] ClearTalon';
}

export class SetErrorTalon {
    static type = '[Talon] SetErrorTalon';

    constructor(public error: any) {
    }
}


export class ClearErrorTalon {
    static type = '[Talon] ClearErrorTalon';

}


export class SaveTalon {
    static type = '[Talon] SaveTalon';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}

export class SaveTalonSuccess {
    static type = '[Talon] SaveTalonSuccess';

    constructor(public data: TalonModel, public uid: string, public forceClose?: boolean) {
    }
}

export class UpdateTalonSuccess {
    static type = '[Talon] UpdateTalonSuccess';

    constructor(public uid: string, public data: TalonModel, public forceClose?: boolean) {
    }
}


export class CreateTalon {
    static type = '[Talon] CreateTalon';
    constructor(public talon?: TalonModel) {
    }
}

export class PrintReceiptTalon {
    static type = '[Talon] PrintReceiptTalon';
}

export class PrintTalonAct {
    static type = '[Talon] PrintTalonAct';
}

export class PrintTalonContract {
    static type = '[Talon] PrintTalonContract';
}

export class PrintTalon {
    static type = '[Talon] PrintTalon';
}


export class GetStoryForTalon {
    static type = '[Talon] GetStoryForTalon';

    constructor(
        public openDialog: boolean = true,
        public tel?: TelModel,
        public sn?: string,
        public limit?: number) {
    }
}


export class GetStoryForTalonSuccess {
    static type = '[Talon] GetStoryForTalonSuccess';

    constructor(public openDialog: boolean = true, public stories: StoriesModel) {
    }
}

export class GetStoryForTalonFail {
    static type = '[Talon] GetStoryForTalonFail';

    constructor(public error: any) {
    }
}


export class InsertTalonAccordingToQrCode {
    static type = '[Talon] InsertTalonAccordingToQrCode';

    constructor(public qrCode: QrCodeModel) {
    }
}
export class InsertTalonAccordingToQrCodeSuccess {
    static type = '[Talon] InsertTalonAccordingToQrCodeSuccess';

    constructor(public talon: TalonModel) {
    }
}
export class InsertTalonAccordingToQrCodeFail {
    static type = '[Talon] InsertTalonAccordingToQrCodeFail';

    constructor(public error: any) {
    }
}

export class ResetInsertTalonAccordingToQrCodeStatus {
    static type = '[Talon] ResetInsertTalonAccordingToQrCodeStatus';

}


