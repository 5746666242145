import {Injectable} from '@angular/core';

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Select} from '@ngxs/store';

import {Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {RoleModel} from '../states/auth/auth.model';
import {AuthState} from '../states/auth/auth.state';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {

    @Select(AuthState.access) access$: Observable<RoleModel>;
    @Select(AuthState.isAuthenticate) isAuthenticate$: Observable<boolean>;

    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.isAuthenticate$
            .pipe(
                switchMap((isAuth) => {
                        if (isAuth) {
                            return this.access$.pipe(filter((v) => !!v), map(m => {
                               return true;
                            }));
                        } else {
                            this.router.navigate(['/']);
                            return of(false);
                        }
                    },
                ),
            );


    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
