import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {append, patch, updateItem} from '@ngxs/store/operators';
import {ShowToast} from '../../../../core/states/toast/toast.action';
import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {StorageListService} from '../../services/storage-list.service';
import {TreeFolders} from '../../storage.symbols';
import {
    CreateFolderSuccessWS,
    CreateGoodsSuccessWs,
    UpdateGoodsSuccessWS,
} from '../storage-manage-state/storage-manage.actions';
import {
    GetGoodsIntoFolder,
    GetGoodsIntoFolderFail,
    GetGoodsIntoFolderSuccess,
    GetTreeFolders,
    GetTreeFoldersFail,
    GetTreeFoldersSuccess,
    LoadMovementGoodsList,
    LoadMovementGoodsListFail,
    LoadMovementGoodsListSuccess,
    UpdateMovementGoodsFilter,
} from './storage-list.actions';
import {MOVEMENT_GOODS_LIST_FILTER_DEFAULT, STORAGE_STATE_DEFAULT, StorageListStateModel} from './storage-list.models';

@State<StorageListStateModel>({
    name: 'storageList',
    defaults: STORAGE_STATE_DEFAULT,
})
@Injectable()
export class StorageListState {

    constructor(private service: StorageListService) {
    }

    @Selector()
    static folderHaveGoods(state: StorageListStateModel): StorageListStateModel['folderHaveGoods'] {
        return state.folderHaveGoods;
    }

    @Selector()
    static treeFolders(state: StorageListStateModel): StorageListStateModel['treeFolders'] {
        return state.treeFolders;
    }

    @Selector()
    static loadTreeFoldersState(state: StorageListStateModel): StorageListStateModel['loadTreeFoldersState'] {
        return state.loadTreeFoldersState;
    }

    @Selector()
    static goods(state: StorageListStateModel): StorageListStateModel['goods'] {
        return state.goods;
    }

    @Selector()
    static loadGoodsStatus(state: StorageListStateModel): StorageListStateModel['loadGoodsStatus'] {
        return state.loadGoodsStatus;
    }

    @Selector()
    static movementGoodsList(state: StorageListStateModel): StorageListStateModel['movementGoodsList'] {
        return state.movementGoodsList;
    }

    @Selector()
    static movementGoodsListTotal(state: StorageListStateModel): StorageListStateModel['movementGoodsListTotal'] {
        return state.movementGoodsListTotal;
    }

    @Selector()
    static loadMovementGoodsListStatus(state: StorageListStateModel): StorageListStateModel['loadMovementGoodsListStatus'] {
        return state.loadMovementGoodsListStatus;
    }


    @Selector()
    static movementGoodsListFilter(state: StorageListStateModel): StorageListStateModel['movementGoodsListFilter'] {
        return state.movementGoodsListFilter;
    }

    @Action(GetTreeFolders)
    getTreeFolders(ctx: StateContext<StorageListStateModel>, {id}: GetTreeFolders) {
        ctx.patchState({
            loadTreeFoldersState: PROGRESS_STATUSES.IN_PROGRESS,
        });

        this.service.getTreeFolders(id).subscribe({
            next: tree => ctx.dispatch(new GetTreeFoldersSuccess(tree)),
            error: err => ctx.dispatch(new GetTreeFoldersFail(err)),
        });
    }

    @Action(GetTreeFoldersSuccess)
    getTreeFoldersSuccess(ctx: StateContext<StorageListStateModel>, {tree}: GetTreeFoldersSuccess) {
        ctx.patchState({
            loadTreeFoldersState: PROGRESS_STATUSES.SUCCESS,
            treeFolders: tree,
        });
    }

    @Action(GetTreeFoldersFail)
    getTreeFoldersFail(ctx: StateContext<StorageListStateModel>, {error}: GetTreeFoldersFail) {
        ctx.patchState({
            loadTreeFoldersState: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: 'Не вдалось завантажити список директорій, спробуйте пізніше.',
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }


    @Action(GetGoodsIntoFolder)
    getGoodsIntoFolder(ctx: StateContext<StorageListStateModel>, {folderId, name}: GetGoodsIntoFolder) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.getGoodsIntoFolder(folderId, name).subscribe({
            next: goods => ctx.dispatch(new GetGoodsIntoFolderSuccess(goods.goods, goods.folders)),
            error: err => ctx.dispatch(new GetGoodsIntoFolderFail(err)),
        });
    }


    @Action(GetGoodsIntoFolderSuccess)
    getGoodsIntoFolderSuccess(ctx: StateContext<StorageListStateModel>, {goods, folder}: GetGoodsIntoFolderSuccess) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.SUCCESS,
            goods,
            folderHaveGoods: folder,
        });
    }

    @Action(GetGoodsIntoFolderFail)
    getGoodsIntoFolderFail(ctx: StateContext<StorageListStateModel>, {error}: GetGoodsIntoFolderFail) {
        ctx.patchState({
            loadGoodsStatus: PROGRESS_STATUSES.ERROR,
        });

        ctx.dispatch(new ShowToast({
            detail: 'Не вдалось завантажити список товарів.',
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(CreateFolderSuccessWS)
    createFolderSuccess(ctx: StateContext<StorageListStateModel>, {payload}: CreateFolderSuccessWS) {
        ctx.setState(patch<StorageListStateModel>({
                treeFolders: patch<TreeFolders>({
                    childrenFolders: append([payload]),
                }),
            }),
        );
        ctx.dispatch(new ShowToast({
            detail: `Створено нову директорію: ${payload.name}`,
            summary: 'Склад',
            severity: 'success',
        }));
    }

    @Action(CreateGoodsSuccessWs)
    createGoodsSuccessWs(ctx: StateContext<StorageListStateModel>, {payload}: CreateGoodsSuccessWs) {
        ctx.patchState({
            goods: [...ctx.getState().goods, payload],
        });
        ctx.dispatch(new ShowToast({
            detail: `Створено нову позицію: ${payload.name}`,
            summary: 'Склад',
            severity: 'success',
        }));
    }

    @Action(UpdateGoodsSuccessWS)
    updateGoodsSuccessWS(ctx: StateContext<StorageListStateModel>, {payload}: UpdateGoodsSuccessWS) {
        ctx.setState(patch({
            goods: updateItem(item => item.id === payload.id, payload),
        }));
        ctx.dispatch(new ShowToast({
            detail: `${payload.name} | арт.: ${payload.art}`,
            summary: 'Позицію оновлено на складі!',
            severity: 'info',
        }));
    }

    @Action(LoadMovementGoodsList)
    loadMovementGoodsList(ctx: StateContext<StorageListStateModel>, {filter, lazyLoad}: LoadMovementGoodsList) {
        const state = ctx.getState();
        const updateFilter = lazyLoad ?
            {
                ...state.movementGoodsListFilter,
                ...filter,
                first: state.movementGoodsListFilter.first + state.movementGoodsListFilter.count,
            } :
            {...MOVEMENT_GOODS_LIST_FILTER_DEFAULT, ...state.movementGoodsListFilter, ...filter};


        ctx.setState(patch<StorageListStateModel>({
            loadMovementGoodsListStatus: PROGRESS_STATUSES.IN_PROGRESS,
            movementGoodsListFilter: updateFilter,
        }));


        this.service.getMovementList(updateFilter).subscribe({
            next: data => ctx.dispatch(new LoadMovementGoodsListSuccess(data, lazyLoad)),
            error: err => ctx.dispatch(new LoadMovementGoodsListFail(err)),
        });


    }

    @Action(LoadMovementGoodsListSuccess)
    loadMovementGoodsListSuccess(ctx: StateContext<StorageListStateModel>, {
        data,
        lazyLoad,
    }: LoadMovementGoodsListSuccess) {
        ctx.patchState({
            loadMovementGoodsListStatus: PROGRESS_STATUSES.SUCCESS,
            movementGoodsList: lazyLoad ? [...ctx.getState().movementGoodsList, ...data.items] : data.items,
            movementGoodsListTotal: data.totalCount,
        });
    }

    @Action(LoadMovementGoodsListFail)
    loadMovementGoodsListFail(ctx: StateContext<StorageListStateModel>, {error}: LoadMovementGoodsListFail) {
        ctx.patchState({
            loadMovementGoodsListStatus: PROGRESS_STATUSES.ERROR,
        });
        ctx.dispatch(new ShowToast({
            detail: 'Не вдалось завантажити список товарів.',
            summary: 'Сталась помилка!',
            severity: 'error',
        }));
    }

    @Action(UpdateMovementGoodsFilter)
    updateMovementGoodsFilter(ctx: StateContext<StorageListStateModel>, {filter}: UpdateMovementGoodsFilter) {
        ctx.patchState({
            movementGoodsListFilter: filter,
        });
    }

}
