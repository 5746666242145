import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {TalonModel, WarrantyModel} from '../../../modules/talon/talon.model';
import {TalonService} from '../../../modules/talon/services/talon.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@Component({
    selector: 'app-date-picker-launch',
    templateUrl: './date-picker-launch.component.html',
    styleUrls: ['./date-picker-launch.component.scss'],

    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class DatePickerLaunchComponent implements OnDestroy, OnInit {

    @Output() garant = new EventEmitter<WarrantyModel>();
    @Output() changeDate = new EventEmitter<Date>();
    localTalon: TalonModel;
    date: FormControl = new FormControl();
    subscription = new Subscription();


    constructor(
        private dateAdapter: DateAdapter<any>,
        private service: TalonService
    ) {
        this.dateAdapter.setLocale('uk_UA');
    }

    @Input() set talon(talon: TalonModel) {
        this.localTalon = {...talon};
        this.date.setValue(moment(talon.dateLaunch).toDate(), {emitEvent: false});
        this.service.getWarranty(this.localTalon.date, this.localTalon.dateLaunch, this.localTalon.state.guaranteePeriod).subscribe(garant => {
            this.garant.emit(garant);
        });
    }

    ngOnInit(): void {
        this.subscription.add(this.date.valueChanges.subscribe((value) => {
            this.localTalon.dateLaunch = moment(value)
                .utc(true)
                .format('yyyy-MM-DD HH:mm:ss');

            this.changeDate.emit(value);
            this.service.getWarranty(this.localTalon.date, this.localTalon.dateLaunch, this.localTalon.state.guaranteePeriod).subscribe(garant => {
                this.garant.emit(garant);
            });
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
