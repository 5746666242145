import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {cloneDeep} from 'lodash';
import {take} from 'rxjs/operators';
import {FADE_IN, FADE_OUT} from '../../../../shared/animations';
import {StorageListRootComponent} from '../../../storage/containers/storage-list-root/storage-list-root.component';
import {ReceiptModel, StorageModel} from '../../symbols';

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [FADE_IN, FADE_OUT],
})
export class ReceiptComponent {

    @Output() receiptChanged = new EventEmitter<ReceiptModel[]>();
    receiptLocal: ReceiptModel[];
    id: string;
    total: number;
    totalOpt: number;

    constructor(private cd: ChangeDetectorRef, private dialog: MatDialog) {
    }

    @Input() set receipt(data: { receipt: ReceiptModel[]; id: string }) {
        if (this.id !== data.id) {
            this.id = data.id;
            this.receiptLocal = cloneDeep(data.receipt);
            this.getTotal();
            this.getTotalOpt();

        }
    }


    selectedGoods(goods: StorageModel, index: number): void {
        const receiptClone = this.receiptLocal;
        const currentGoods = receiptClone[index];
        currentGoods.name = goods.name;
        currentGoods.art = goods.art;
        currentGoods.id = goods.id;
        currentGoods.count = 1;
        currentGoods.sum = goods.price * currentGoods.count;
        currentGoods.priceOpt = goods.price_opt;
        currentGoods.price = goods.price;
        this.getTotal();
        this.getTotalOpt();
        this.receiptChanged.emit(cloneDeep(this.receiptLocal));
    }

    changeValue(name: string, index: number): void {
        const receiptClone = this.receiptLocal;
        const currentGoods = receiptClone[index];
        currentGoods.name = name;
        this.getTotal();
        this.getTotalOpt();
        this.receiptChanged.emit(cloneDeep(this.receiptLocal));
    }


    changeCount(index: number): void {
        const receiptClone = this.receiptLocal;
        const currentGoods = receiptClone[index];
        currentGoods.sum = currentGoods.price * currentGoods.count;
        this.getTotal();
        this.getTotalOpt();
        this.receiptChanged.emit(cloneDeep(this.receiptLocal));
    }

    addItem(): void {
        this.receiptLocal.push({
            name: '',
            art: null,
            count: 1,
            sum: 0,
            price: 0,
            priceOpt: 0,
            id: null,
        });
        this.receiptChanged.emit(cloneDeep(this.receiptLocal));
        this.cd.detectChanges();
    }

    removeItem(rowIndex: number): void {
        this.receiptLocal.splice(rowIndex, 1);
        this.receiptChanged.emit(cloneDeep(this.receiptLocal));
        this.getTotalOpt();
        this.getTotal();
        this.cd.detectChanges();
    }

    getTotal(): void {
        this.total = this.receiptLocal.reduce((previousValue, currentValue) => previousValue + currentValue.sum, 0);
    }

    getTotalOpt(): void {
        this.totalOpt = this.receiptLocal.reduce((previousValue, currentValue) =>
            previousValue + currentValue.priceOpt * currentValue.count, 0);
    }

    openStorage(rowIndex: number) {
        this.dialog.open(
            StorageListRootComponent,
            {
                data: {isDialog: true},
            },
        ).afterClosed()
            .pipe(take(1))
            .subscribe({
                next: good => {
                    if (good) {
                        this.selectedGoods(good, rowIndex);
                    }
                },
            });
    }
}

