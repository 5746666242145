import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {patch, updateItem} from '@ngxs/store/operators';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {ShowToast} from '../../../../core/states/toast/toast.action';
import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {StoryService} from '../../../stories/services/story.service';
import {AsteriskEndpoint, CallInAsteriskModel, CONNECT_STATUS_WS, TypeCall} from '../../asterisk.symbols';
import {AsteriskService} from '../../services/asterisk.service';
import {ASTERISK_DEFAULT_STATE, AsteriskStateModel} from './asterisk-state.model';
import {
    CallingIn,
    ChangeConnectARI,
    GetChannels,
    GetEndpoints,
    GetEndpointsFail,
    GetEndpointsSuccess,
    GetRoutine,
    GetRoutineFail,
    GetRoutineSuccess,
    GetStoryForPhone,
    GetStoryForPhoneFail,
    GetStoryForPhoneSuccess,
    InitParamsAsterisk,
    RemoveCall,
    SetCountConnected,
    SetRoutine,
    SetVolume,
    ToCall,
    ToCallFail,
    ToCallSuccess,
    UpdateCallState,
    UpdateConnectConfig,
    UpdateConnectConfigFail,
    UpdateConnectConfigSuccess,
    UpdateRoutine,
    UpdateRoutineFail,
    UpdateRoutineSuccess,
} from './asterisk.actions';

@State<AsteriskStateModel>({
    name: 'asterisk',
    defaults: ASTERISK_DEFAULT_STATE,
})
@Injectable()
export class AsteriskState implements NgxsOnInit {
    ws: ReconnectingWebSocket;

    constructor(private service: AsteriskService, private storyService: StoryService) {

    }

    @Selector()
    static updateConnectConfStatus(state: AsteriskStateModel): AsteriskStateModel['updateConnectConfStatus'] {
        return state.updateConnectConfStatus;
    }

    @Selector()
    static routine(state: AsteriskStateModel): AsteriskStateModel['routine'] {
        return state.routine;
    }

    @Selector()
    static getRoutineStatus(state: AsteriskStateModel): AsteriskStateModel['getRoutineStatus'] {
        return state.getRoutineStatus;
    }

    @Selector()
    static volume(state: AsteriskStateModel): AsteriskStateModel['volume'] {
        return state.volume;
    }

    @Selector()
    static toCallStatus(state: AsteriskStateModel): AsteriskStateModel['toCallStatus'] {
        return state.toCallStatus;
    }

    @Selector()
    static statePanel(state: AsteriskStateModel): AsteriskStateModel['isHidePanel'] {
        return state.isHidePanel;
    }

    @Selector()
    static directConnection(state: AsteriskStateModel): AsteriskStateModel['directConnection'] {
        return state.directConnection;
    }

    @Selector()
    static directNumber(state: AsteriskStateModel): AsteriskStateModel['directNumber'] {
        return state.directNumber;
    }

    @Selector()
    static channels(state: AsteriskStateModel): AsteriskStateModel['channels'] {
        return state.channels;
    }

    @Selector()
    static connectStatus(state: AsteriskStateModel): AsteriskStateModel['connectStatus'] {
        return state.connectStatus;
    }

    @Selector()
    static apiKey(state: AsteriskStateModel): AsteriskStateModel['apiKey'] {
        return state.apiKey;
    }

    @Selector()
    static webPort(state: AsteriskStateModel): AsteriskStateModel['webPort'] {
        return state.webPort;
    }

    @Selector()
    static endpoints(state: AsteriskStateModel): AsteriskStateModel['endpoints'] {
        return state.endpoints;
    }

    @Selector()
    static endpointsDict(state: AsteriskStateModel): { [code: string]: AsteriskEndpoint } {
        const endpointDict: { [code: string]: AsteriskEndpoint } = {};
        state.endpoints.forEach(endpoint => endpointDict[endpoint.resource] = endpoint);
        return endpointDict;
    }

    @Selector()
    static loadEndpointStatus(state: AsteriskStateModel): AsteriskStateModel['loadEndpointStatus'] {
        return state.loadEndpointStatus;
    }

    @Selector()
    static isRoutine(state: AsteriskStateModel): AsteriskStateModel['isRoutine'] {
        return state.isRoutine;
    }

    @Action(InitParamsAsterisk)
    initParamsAsterisk(ctx: StateContext<AsteriskStateModel>, {
        hostWS,
        webHost,
        port,
        apiUser,
        apiPassword,
    }: InitParamsAsterisk): void {
        console.log(hostWS);
        console.log(webHost);
        console.log(port);
        console.log(apiUser);
        console.log(apiPassword);
    }

    @Action(ChangeConnectARI)
    changeConnectARI(ctx: StateContext<AsteriskStateModel>, {payload}: ChangeConnectARI): void {
        ctx.patchState({
            connectStatus: payload,
            channels: payload === CONNECT_STATUS_WS.CONNECT ? ctx.getState().channels : [],
        });
    }

    @Action(CallingIn)
    callingIn(ctx: StateContext<AsteriskStateModel>, {payload}: CallingIn): void {

        if (payload.typeCall === TypeCall.InCall) {
            if (ctx.getState().channels.filter(channel => channel.typeCall === TypeCall.InCall).length === 0) {
                this.service.playNotificationFirst(ctx.getState().volume);
            } else if (ctx.getState().channels.filter(channel => channel.typeCall === TypeCall.InCall).length > 0) {
                this.service.playNotificationSecondary(ctx.getState().volume);
            }
        }

        ctx.patchState({channels: [...ctx.getState().channels, payload]});
    }

    @Action(RemoveCall)
    removeCall(ctx: StateContext<AsteriskStateModel>, {payload}: RemoveCall): void {

        const currentChannel = ctx.getState().channels.find(channel => channel.id === payload);

        if (ctx.getState().channels.filter(
            channel => channel.typeCall === TypeCall.InCall,
        )
            .length === 1 && currentChannel.typeCall === TypeCall.InCall) {
            this.service.stopNotification();
        }


        ctx.patchState({channels: [...ctx.getState().channels].filter(item => item.id !== payload)});
    }

    @Action(GetChannels)
    getChannels(ctx: StateContext<AsteriskStateModel>, {channels}: GetChannels): void {
        ctx.patchState({
            channels: ctx.getState().channels.length ? ctx.getState().channels : channels,
        });
    }

    @Action(GetStoryForPhone)
    getStoryForPhone(ctx: StateContext<AsteriskStateModel>, {channel}: GetStoryForPhone): void {
        ctx.patchState({loadingStatusCustomerContact: PROGRESS_STATUSES.IN_PROGRESS});
        this.storyService.getStoryByTel(channel.tel).subscribe({
            next: value => ctx.dispatch(new GetStoryForPhoneSuccess(value, channel)),
            error: err => ctx.dispatch(new GetStoryForPhoneFail(err)),
        });
    }

    @Action(GetStoryForPhoneSuccess)
    getStoryForPhoneSuccess(ctx: StateContext<AsteriskStateModel>, {data, channel}: GetStoryForPhoneSuccess): void {
        ctx.patchState({
            loadingStatusCustomerContact: PROGRESS_STATUSES.SUCCESS,
        });

        ctx.setState(patch<AsteriskStateModel>({
            channels: updateItem<CallInAsteriskModel>(item => item.id === channel.id, patch<CallInAsteriskModel>({
                docs: data.docs,
            })),
        }));
    }

    @Action(GetStoryForPhoneFail)
    getStoryForPhoneFail(ctx: StateContext<AsteriskStateModel>, {error}: GetStoryForPhoneFail): void {
        ctx.patchState({
            loadingStatusCustomerContact: PROGRESS_STATUSES.ERROR,
        });
        ctx.dispatch(new ShowToast({
            severity: error,
            summary: 'Сталась помилка!',
            detail: 'Не вдалось отримати дані поточного телефону.',
        }));
    }

    @Action(UpdateCallState)
    updateState(ctx: StateContext<AsteriskStateModel>, {payload}: UpdateCallState): void {
        // const channels = [...ctx.getState().channels];
        // const index = channels.findIndex(channel => channel.id === payload.id);
        // channels[index] = payload;
        // ctx.patchState({channels});

        ctx.setState(patch<AsteriskStateModel>(
            {
                channels: updateItem<CallInAsteriskModel>(item => item.id === payload.id, patch<CallInAsteriskModel>({
                    answered: payload.answered,
                    isHold: payload.isHold,
                    isDisabled: payload.isDisabled,
                    playbackId: payload.playbackId,
                    nameCaller: payload.nameCaller,
                    state: payload.state,
                })),
            },
        ));


    }

    @Action(SetVolume)
    async setVolume(ctx: StateContext<AsteriskStateModel>, {value}: SetVolume) {
        this.service.stopNotification();
        ctx.patchState({volume: value});
        const audio = new Audio('/assets/notific.mp3');
        audio.volume = value;
        await audio.play();
    }

    @Action(SetCountConnected)
    setCountConnected(ctx: StateContext<AsteriskStateModel>, {count}: SetCountConnected): void {
        ctx.patchState({countConnected: count});
    }

    @Action(ToCall)
    toCall(ctx: StateContext<AsteriskStateModel>, {tel}: ToCall): void {
        ctx.patchState({toCallStatus: PROGRESS_STATUSES.IN_PROGRESS});
        this.service.toCall(tel).subscribe({
            next: () => ctx.dispatch(new ToCallSuccess()),
            error: () => ctx.dispatch(new ToCallFail()),
        });
    }

    @Action(ToCallSuccess)
    toCallSuccess(ctx: StateContext<AsteriskStateModel>): void {
        ctx.patchState({toCallStatus: PROGRESS_STATUSES.SUCCESS});
    }

    @Action(ToCallFail)
    toCallFail(ctx: StateContext<AsteriskStateModel>): void {
        ctx.patchState({toCallStatus: PROGRESS_STATUSES.ERROR});
        ctx.dispatch(new ShowToast({
            severity: 'error',
            detail: 'Не вдалось звершити дзвінок, спробуйте пізніше.',
            summary: 'Сталась помилка!',
            life: 4000,
        }));
    }

    @Action(GetEndpoints)
    getEndpoints(ctx: StateContext<AsteriskStateModel>): void {
        ctx.patchState({
            loadEndpointStatus: PROGRESS_STATUSES.IN_PROGRESS,
        });
        this.service.getEndpoints().subscribe({
            next: res => ctx.dispatch(new GetEndpointsSuccess(res)),
            error: err => ctx.dispatch(new GetEndpointsFail(err)),
        });
    }

    @Action(GetEndpointsSuccess)
    GetEndpointsSuccess(ctx: StateContext<AsteriskStateModel>, {endpoints}: GetEndpointsSuccess): void {
        ctx.patchState({
            loadEndpointStatus: PROGRESS_STATUSES.SUCCESS,
            endpoints,
        });
    }

    @Action(GetEndpointsFail)
    getEndpointsFail(ctx: StateContext<AsteriskStateModel>, {error}: GetEndpointsFail): void {
        ctx.patchState({
            loadEndpointStatus: PROGRESS_STATUSES.ERROR,
        });
    }

    @Action(SetRoutine)
    setRoutine(ctx: StateContext<AsteriskStateModel>, {isRoutine}: SetRoutine): void {
        ctx.patchState({isRoutine});
    }

    @Action(GetRoutine)
    getRoutine(ctx: StateContext<AsteriskStateModel>): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.IN_PROGRESS});
        this.service.getRoutine().subscribe({
            next: res => ctx.dispatch(new GetRoutineSuccess(res)),
            error: err => ctx.dispatch(new GetRoutineFail(err)),
        });
    }

    @Action(GetRoutineSuccess)
    getRoutineSuccess(ctx: StateContext<AsteriskStateModel>, {routine}: GetRoutineSuccess): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.SUCCESS, routine});
    }

    @Action(GetRoutineFail)
    getRoutineFail(ctx: StateContext<AsteriskStateModel>, {error}: GetRoutineFail): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.ERROR});
    }

    @Action(UpdateRoutine)
    updateRoutine(ctx: StateContext<AsteriskStateModel>, {routine}: UpdateRoutine): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.IN_PROGRESS});
        this.service.updateRoutine(routine).subscribe({
            next: res => ctx.dispatch(new UpdateRoutineSuccess(res)),
            error: err => ctx.dispatch(new UpdateRoutineFail(err)),
        });
    }

    @Action(UpdateRoutineSuccess)
    updateRoutineSuccess(ctx: StateContext<AsteriskStateModel>, {routine}: UpdateRoutineSuccess): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.SUCCESS, routine});
    }

    @Action(UpdateRoutineFail)
    updateRoutineFail(ctx: StateContext<AsteriskStateModel>, {error}: UpdateRoutineFail): void {
        ctx.patchState({getRoutineStatus: PROGRESS_STATUSES.ERROR});
    }

    @Action(UpdateConnectConfig)
    updateConnectConfig(ctx: StateContext<AsteriskStateModel>, {config}: UpdateConnectConfig): void {
        ctx.patchState({updateConnectConfStatus: PROGRESS_STATUSES.IN_PROGRESS});
        this.service.updateConnectConfig(config).subscribe({
            next: res => ctx.dispatch(new UpdateConnectConfigSuccess(res)),
            error: err => ctx.dispatch(new UpdateConnectConfigFail(err)),
        });
    }

    @Action(UpdateConnectConfigSuccess)
    updateConnectConfigSuccess(ctx: StateContext<AsteriskStateModel>, {config}: UpdateConnectConfigSuccess): void {
        ctx.patchState({updateConnectConfStatus: PROGRESS_STATUSES.SUCCESS});
    }

    @Action(UpdateConnectConfigFail)
    updateConnectConfigFail(ctx: StateContext<AsteriskStateModel>, {error}: UpdateConnectConfigFail): void {
        ctx.patchState({updateConnectConfStatus: PROGRESS_STATUSES.ERROR});
    }

    ngxsOnInit(ctx: StateContext<AsteriskStateModel>): void {
        ctx.setState({...ASTERISK_DEFAULT_STATE, volume: ctx.getState().volume});
        this.connectWs(ctx);
    }

    private connectWs(ctx: StateContext<AsteriskStateModel>) {
        let protocol = 'ws://';
        if (window.location.protocol === 'https:') {
            protocol = 'wss://';
        }
        this.ws = new ReconnectingWebSocket(
            `${protocol}${window.location.host}/ws-asterisk`, [], {connectionTimeout: 7000},
        );
        this.ws.onclose = () => {
            ctx.dispatch(new ChangeConnectARI(CONNECT_STATUS_WS.DISCONNECT_BACKEND));
        };
        this.ws.onmessage = (res) => {
            const data: { type: string, payload: any } = JSON.parse(res.data);
            switch (data.type) {
                case '[Asterisk] SetCountConnections': {
                    ctx.dispatch(new SetCountConnected(data.payload));
                    break;
                }
                case '[Asterisk] ChangeConnectARI': {
                    ctx.dispatch(new ChangeConnectARI(data.payload));
                    break;
                }
                case '[Asterisk] GetChannels': {
                    ctx.dispatch(new GetChannels(data.payload));
                    break;
                }
                case '[Asterisk] CallingIn': {
                    ctx.dispatch(new CallingIn(data.payload));
                    break;
                }
                case '[Asterisk] RemoveCall': {
                    ctx.dispatch(new RemoveCall(data.payload));
                    break;
                }
                case '[Asterisk] UpdateState': {
                    ctx.dispatch(new UpdateCallState(data.payload));
                    break;
                }
                case '[Asterisk] SetRoutine': {
                    ctx.dispatch(new SetRoutine(data.payload));
                    break;
                }
                default:
                    console.log(data);
            }

        };
    }

}
