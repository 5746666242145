<div class="address-wrap">

      <form>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Район</mat-label>
          <input
            type="text"
            matInput
            [formControl]="controlCounty"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
  
            <mat-option
              *ngFor="let option of county | async"
              [value]="option"
            >
              {{ option }}
          </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
  
  
  
  
      <form class="example-form">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Місцевість</mat-label>
          <input
            type="text"
            matInput
            [formControl]="controlCity"
            [matAutocomplete]="city"
          />
          <mat-autocomplete autoActiveFirstOption #city="matAutocomplete" >
  
            <mat-option
              *ngFor="let option of cities | async"
              [value]="option"
            >
              {{ option }}
          </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
  
  
  
      <form class="example-form">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Адреса</mat-label>
          <input
            type="text"
            matInput
            [formControl]="controlStreet"
            [matAutocomplete]="street"
          />
          <mat-autocomplete autoActiveFirstOption #street="matAutocomplete" >
  
            <mat-option
              *ngFor="let option of streets | async"
              [value]="option"
            >
              {{ option }}
          </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
  </div>
