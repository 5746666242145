<div class="equipment-wrap" *ngIf="(access$ | async).read_equipment">

    <ng-container *ngIf="readonly; else formManage">
        <div class="equipment-info">
            <div class="equipment-info__group">
                <span class="equipment-info__title">Обладнання:</span><span class="equipment-info__text"> {{localKetel?.name}}</span>
            </div>

            <div class="equipment-info__group">
                <span class="equipment-info__title">Серійний номер:</span><span class="equipment-info__text">{{localKetel?.sn}}</span>
            </div>

            <div class="equipment-info__group">
                <span class="equipment-info__title">Потужність:</span><span class="equipment-info__text">{{localKetel?.kWt}} кВт</span>
            </div>
        </div>
    </ng-container>


    <ng-template #formManage>
        <form class="equipment-form">
            <mat-form-field appearance="fill">
                <mat-label>Серійний номер</mat-label>
                <input
                        type="text"
                        placeholder="Серійний номер"
                        aria-label="Number"
                        matInput
                        [formControl]="form.controls.controlSn"
                        [matAutocomplete]="autoSn">
                <mat-autocomplete autoActiveFirstOption #autoSn="matAutocomplete" [panelWidth]="400">
                    <mat-option *ngFor="let option of freeKetels$ | async" [value]="option.ketel.sn">

                        <div class="sn-item" (click)="selectSn(option)">
                            <div class="sn-item__ketel">

                            <span class="sn-item__name">
                            {{option.ketel.name}}
                        </span>
                                <span class="sn-item__sn">
                            {{option.ketel.sn}}
                       </span>
                            </div>


                            <div class="sn-item__service">
                            <span class="sn-item__date">
                            {{option.date | date: 'dd.MM.YYYY'}}
                            </span>
                                <span class="sn-item__master">
                            {{option.masterName}}
                            </span>
                            </div>
                        </div>

                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <ng-container *ngIf="!selectOnly; else selectorEquipment">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Обладнання</mat-label>
                    <input type="text" placeholder="Обладнання" aria-label="Number" matInput
                           [formControl]="form.controls.controlKetel"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of ketels$ | async" [value]="option.name">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>

            <ng-template #selectorEquipment>
                <app-search-selector
                        placeholder="Пошук..."
                        [default]="selectedEquipmentValue?.id"
                        [label]="'Обладнання'"
                        [required]="requiredLocal"
                        [choices]="ketels$ | async"
                        (selectedValue)="selectedEquipment($event)"
                        (formControl)="setFormControlSearchSelector($event)"
                ></app-search-selector>
            </ng-template>


            <mat-form-field appearance="fill">
                <mat-label>кВт</mat-label>
                <input matInput [formControl]="form.controls.controlKwt">
            </mat-form-field>
        </form>
    </ng-template>


</div>
