<ng-container
  *ngIf="{
  isCallAccess:
                 telStr.length === 10 &&
                  (toCallStatus$ | async) !== progressStatuses.IN_PROGRESS &&
                  (connectStatus$ | async) === connectWs.CONNECT} as extracted"
>
  <div class="input-tel">
    <mat-form-field appearance="fill" class="full-input">
      <mat-label>Телефон</mat-label>
      <span class="prefix-text" matTextPrefix="">+38 &nbsp;</span>
      <input

        type='tel'
        matInput
        mask='(000) 000-0000'
        [(ngModel)]="telStr"
        (ngModelChange)="changeTel($event)"
      >

      <div class="controls"
           matIconSuffix>
        <button
          mat-icon-button
          [disabled]="extracted.isCallAccess === false"
          (click)="toCall()"
        >
          <img
            class="phone-img"
            src="assets/img/phone.svg"
            alt="to call"
            [ngStyle]="{'opacity': !extracted.isCallAccess ? 0.3 : 1}"
            *ngIf="(toCallStatus$ | async) !== progressStatuses.IN_PROGRESS; else callProcess"
          >

          <ng-template #callProcess>
            <img

              class="phone-img opacity"
              src="/assets/img/wait.svg" alt="wait"
            >
          </ng-template>
        </button>

        <button
          mat-icon-button
          [disabled]="telStr.length<10"
          (click)="openCustomerCard()">
          <mat-icon>assignment_ind</mat-icon>
        </button>
      </div>

    </mat-form-field>

  </div>
</ng-container>
