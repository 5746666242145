
  <mat-form-field class="example-full-width">
    <input
      [formControl]="nameControl"
      type="text"
      placeholder="Назва"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
    />
    <mat-autocomplete [panelWidth]="350" autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of nameLoad | async"
        [value]="option.name"
      >
        {{ option.name }}
      </mat-option>

    </mat-autocomplete>
  </mat-form-field>
