<div class="storage-create-folder-dialog">
    <mat-form-field>
        <mat-label>Назва папки</mat-label>
        <input matInput [formControl]="form" autocomplete="off">
    </mat-form-field>

<div class="actions">
    <button mat-button (click)="create()">Створити</button>
    <button mat-button (click)="close()">Закрити</button>
</div>

</div>
