import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, NavigationEnd, Router, RouteReuseStrategy} from '@angular/router';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root',
})

export class ScrollService {
    url$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url$.next(event.urlAfterRedirects);
            }
        });

    }
}


export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = route.routeConfig.path;
        return path === 'orders-list' || path === 'talons' ||
            path === 'talons-vg-list' || path === 'stand-list' || path === 'invoices-list' ||
            path === 'contract-list';
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig.path, handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {

        return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path);

    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {

        return this.storedRoutes.get(route.routeConfig.path);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {

        return future.routeConfig === curr.routeConfig;
    }
}
