import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {AuthState} from '../../../core/states/auth/auth.state';
import {
    AsteriskEndpoint,
    CallInAsteriskModel,
    ConfigAsteriskModel,
    RoutineModel,
    ToCallResponseModel,
} from '../asterisk.symbols';
import {CustomerCardExpectDataBaseModel} from '../customer-contact.models';

@Injectable()
export class AsteriskService {
    api = 'asterisk-api/asterisk';
    appName = 'vaillant_v2';
    audio = new Audio('/assets/calling.mp3');

    constructor(private http: HttpClient, private store: Store) {
    }

    public answerOnCall(channel: CallInAsteriskModel): Observable<void> {
        return this.http.post<void>(`${this.api}/answer`, {
            channelId: channel.id,
            nameCaller: channel.nameCaller,
            SIP_number: this.store.selectSnapshot(AuthState.user).code,
            playbackId: channel.playbackId,
        });
    }


    playNotificationFirst(volume: number) {
        this.audio = new Audio('/assets/calling.mp3');
        this.audio.volume = volume;
        this.audio.loop = true;
        this.audio.play().then(r => {
        });
    }

    playNotificationSecondary(volume: number) {
        this.audio = new Audio('/assets/notific.mp3');
        this.audio.volume = volume;
        this.audio.play().then(r => {
        });
    }

    stopNotification() {
        this.audio.pause();
    }

    public toCall(tel: string): Observable<ToCallResponseModel> {
        return this.getEndpoints()
            .pipe(
                switchMap((endpoints: AsteriskEndpoint[]) => {
                        if (endpoints.find((endpoint: AsteriskEndpoint) =>
                            endpoint.resource === this.store.selectSnapshot(AuthState.user).code &&
                            endpoint.state === 'online',
                        )) {
                            return this.http.post<ToCallResponseModel>(`${this.api}/to-call`, {
                                endpoint: `SIP/${this.store.selectSnapshot(AuthState.user).code}`,
                                context: 'extension',
                                callerId: tel,
                                extension: tel,
                                app: this.appName,
                                variables: {'CALLERID(name)': this.store.selectSnapshot(AuthState.user).code},
                                appArgs: 'out',
                            }).pipe(delay(3000));
                        } else {
                            return this.writeCallIfEndpointNotAccessible(
                                {
                                    code_device: this.store.selectSnapshot(AuthState.user).code,
                                    date: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
                                    isAnswer: false,
                                    target: 'free',
                                    typeContact: 'outCall',
                                    tel,
                                });
                        }
                    },
                ),
            );


    }

    public getEndpoints(): Observable<AsteriskEndpoint[]> {
        return this.http.get<AsteriskEndpoint[]>(`${this.api}/get-endpoints`);
    }

    public writeCallIfEndpointNotAccessible(data: CustomerCardExpectDataBaseModel): Observable<any> {
        return this.http.post(`${this.api}/write-call-if-endpoint-not-accessible`, data);
    }

    public getRoutine(): Observable<RoutineModel> {
        return this.http.get<RoutineModel>(`${this.api}/routine`);
    }

    public updateRoutine(routine: RoutineModel): Observable<RoutineModel> {
        return this.http.patch<RoutineModel>(`${this.api}/routine`, routine);
    }

    public getConnectConfig(): Observable<ConfigAsteriskModel> {
        return this.http.get<ConfigAsteriskModel>(`${this.api}/config`);
    }

    public updateConnectConfig(conf: ConfigAsteriskModel): Observable<ConfigAsteriskModel> {
        return this.http.patch<ConfigAsteriskModel>(`${this.api}/config`, conf);
    }
}
