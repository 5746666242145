<form class="equipment" [formGroup]="form">

    <div *ngIf="talonsLocal.length">
        <mat-form-field appearance="fill" class="width-100">
            <mat-label [ngClass]="talonsLocal[indexSelectTalon-1].state">
                Талони
            </mat-label>

            <mat-select [(value)]="indexSelectTalon">
                <mat-option [value]="i + 1" *ngFor="let gt of talonsLocal; let i = index">
                    <div [ngClass]="talonsLocal[i].state"
                    >
                        {{
                        "№ " +
                        gt.number +
                        " " +
                        gt.ketel.name +
                        " (" +
                        (gt.date | date: "dd.MM.yyyy") +
                        ")"
                        }}

                        <span *ngIf="talonsLocal[i].state !== stateTalone.notLaunched">{{
                            talonsLocal[i].state.dateEndedGuarantee | date: "dd.MM.yyyy"
                            }}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="state-talon" [ngClass]="talonsLocal[indexSelectTalon-1].state">
            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state === stateTalone.guaranteed">Гарантія дійсна до: </span>
            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state === stateTalone.notGuaranteed">Гарантія закінчилась: </span>
            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state === stateTalone.notLaunched">Запуск не проведено</span>
            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state !== stateTalone.notLaunched">{{talonsLocal[indexSelectTalon - 1].state.dateEndedGuarantee | date: "dd.MM.YYYY"}}</span>

            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state === stateTalone.guaranteed">Залишок гарантійного періоду:
            <span>{{talonsLocal[indexSelectTalon - 1].state.guaranteePeriod}} днів.</span>
                </span>

            <br>
            <span *ngIf="talonsLocal[indexSelectTalon-1]?.state !== stateTalone.notLaunched">
                <span>Дата запуску: </span>
                {{talonsLocal[indexSelectTalon - 1]?.dateLaunch | date: "dd.MM.YYYY"}}
            </span>
        </div>
    </div>


    <div *ngIf="contractsLocal.length">
        <mat-form-field appearance="fill" class="width-100">
            <mat-label
                    [class.guaranteed]="contractsLocal[indexSelectContract - 1].state === stateContract.valid && !contractsLocal[indexSelectContract - 1].isDeactivated"
                    [class.notGuaranteed]="(contractsLocal[indexSelectContract - 1].state === stateContract.invalid || contractsLocal[indexSelectContract - 1].isDeactivated)">
                Договір
            </mat-label>
            <mat-select value="{{ indexSelectContract }}">
                <mat-option value="{{ i + 1 }}" *ngFor="let contract of contractsLocal; let i = index">
                    <div [class.guaranteed]="
              contractsLocal[i].state === stateContract.valid &&
              !contractsLocal[i].isDeactivated
            " [class.notGuaranteed]="
              contractsLocal[i].state === stateContract.invalid ||
             contractsLocal[i].isDeactivated
            ">
                        {{
                        "№" +
                        contract.number +
                        " (" +
                        (contract.date | date: "dd.MM.yyyy") +
                        ")"
                        }}

                        <span>{{ contractsLocal[i].endDate | date: "dd.MM.yyyy" }}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>


        <div class="state-talon"
             [class.guaranteed]="contractsLocal[indexSelectContract-1].state === 'valid'"
             [class.notGuaranteed]="contractsLocal[indexSelectContract-1].state === 'invalid'"
        >
            <span *ngIf="contractsLocal[indexSelectContract-1].state === 'valid'">Договір дійсний</span>
            <span *ngIf="contractsLocal[indexSelectContract-1].state === 'invalid'">Договір не дійсний</span>
            <br>
            <span>Дата закінчення договору: {{contractsLocal[indexSelectContract-1].endDate | date: 'dd.MM.YYYY'}}</span>
        </div>

    </div>

    <mat-form-field appearance="fill">
        <mat-label>Серійний номер</mat-label>
        <input matInput formControlName="sn"/>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
        <input type="text" placeholder="Обладнання" aria-label="Number" matInput formControlName="name"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of ketels | async" [value]="option.name">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Потужність</mat-label>
        <input matInput formControlName="kWt"/>
    </mat-form-field>

</form>
