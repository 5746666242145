import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-view-pdf',
    templateUrl: './dialog-view-pdf.component.html',
    styleUrls: ['./dialog-view-pdf.component.scss'],
})
export class DialogViewPdfComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogViewPdfComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}


export interface DialogData {
    src: string;
    name: string;
    field_list: any[];
    jsonParams: any[];
}
