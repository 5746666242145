import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {NgxsWebsocketPluginModule} from '@ngxs/websocket-plugin';
import * as Sentry from '@sentry/browser';
import {NgxMaskModule} from 'ngx-mask';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {SharedModule} from 'src/app/shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TokenInterceptor} from './core/classes/token.interceptor';
import {CoreModule} from './core/core.module';
import {AuthState} from './core/states/auth/auth.state';
import {CoreState} from './core/states/core/core.state';
import {ToastState} from './core/states/toast/toast.state';
import {CustomRouteReuseStrategy} from './custom-route-reuse-strategy';
import {DefineAuthComponent} from './define-auth/define-auth.component';
import {ConfirmDialogModule} from './modules/confirm-dialog/confirm-dialog.module';
import {LoginPageComponent} from './modules/login-page/login-page.component';
import {TabsState} from './modules/main/components/tabs/tabs.state';
import {RtRouterDataResolverState} from './modules/rt-router-data-resolver/rt-router-data-resolver.state';
import {StorageManageService} from './modules/storage/services/storage-manage.service';
import {StorageManageState} from './modules/storage/states/storage-manage-state/storage-manage.state';

let protocol = 'ws://';
if (window.location.protocol === 'https:') {
  protocol = 'wss://';
}

Sentry.init({
  dsn: 'https://bf49097254f1e82812b43676369c2a7b@o4506954856660992.ingest.us.sentry.io/4506954859872256',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: Record<string, unknown>): void {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}


@NgModule({
  declarations: [AppComponent, LoginPageComponent, DefineAuthComponent],
  imports: [
    ConfirmDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'asterisk',
        'tabs',
        'orders',
        'ordersList',
        'authState',
        'coreState',
        'talonsList',
        'talon',
        'talonVg',
        'talonsVgList',
        'standList',
        'standManage',
        'invoiceList',
        'invoiceManage',
        'contractList',
        'contractManage',
      ],
    }),
    NgxsModule.forRoot([TabsState, CoreState, AuthState, ToastState, RtRouterDataResolverState, StorageManageState], {
      developmentMode: true,
    }),
    NgxsWebsocketPluginModule.forRoot({
      reconnectInterval: 2000,
    }),

// NgxsLoggerPluginModule.forRoot(),
    NgbModule,
    ToastModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, multi: true, useClass: TokenInterceptor},
    MessageService,
    AuthState,
    StorageManageService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {provide: ErrorHandler, useClass: SentryErrorHandler},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
