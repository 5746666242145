<p-table [value]="receiptLocal">
    <ng-template pTemplate="header">
        <tr>
            <th width="50" class="header-table"></th>
            <th width="400">Назва</th>
            <th class="header-table">Ціна</th>
            <th class="header-table">Ціна Опт</th>
            <th class="header-table">Кількість</th>
            <th class="header-table">Сума</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
        <tr [pEditableRow]="product" @fadeIn @fadeOut>
            <td pEditableColumn class="header-table">
                <div class="action-group">
                    <button
                            pButton
                            pRipple
                            icon="pi pi-trash"
                            class="p-button-danger p-button-rounded"
                            style="width: 30px; height: 30px;"
                            (click)="removeItem(rowIndex)"
                    ></button>

                    <button
                            pButton
                            pRipple
                            icon="pi pi-folder-open"
                            class="p-button-warning p-button-rounded"
                            style="width: 30px; height: 30px;"
                            (click)="openStorage(rowIndex)"
                    ></button>
                </div>
            </td>


            <td pEditableColumn class="header-table">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <app-goods-selector
                                (valueChanged)="changeValue($event, rowIndex)"
                                (selectedGoods)="selectedGoods($event, rowIndex)"
                                [defaultValue]="product">
                        </app-goods-selector>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{product.name}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td pEditableColumn class="header-table">
                <p-cellEditor (keyup)="changeCount(rowIndex)">
                    <ng-template pTemplate="input">
                        <input
                                class="input-editor"
                                pInputText
                                type="number"
                                [(ngModel)]="product.price"
                                style="width: 100%"
                        >
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{product.price |currency: " ":"symbol" }} ₴
                    </ng-template>
                </p-cellEditor>
            </td>

            <td pEditableColumn class="header-table">
                <p-cellEditor (keyup)="changeCount(rowIndex)">
                    <ng-template pTemplate="input">
                        <input
                                class="input-editor"
                                pInputText type="number"
                                [(ngModel)]="product.priceOpt"
                                style="width: 100%"
                        >
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{product.priceOpt | currency: " ":"symbol" }} ₴
                    </ng-template>
                </p-cellEditor>
            </td>

            <td pEditableColumn class="header-table">
                <p-cellEditor (keyup)="changeCount(rowIndex)">
                    <ng-template pTemplate="input">
                        <input
                                class="input-editor"
                                pInputText type="number"
                                [(ngModel)]="product.count"
                                style="width: 100%">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{product.count}}
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                {{product.sum | currency: " ":"symbol" }}
            </td>

        </tr>

    </ng-template>
</p-table>

<div class="receipt-footer">
    <button
            pButton
            pRipple
            icon="pi pi-plus"
            class="p-button-rounded p-button-outlined add-button"
            style="width: 30px; height: 30px;"
            (click)="addItem();"
    ></button>

    <div class="sum">
        <div class="total">Всього опт:  {{totalOpt | currency: " ":"symbol" }} ₴</div>
        <div class="total">Всього:  {{total | currency: " ":"symbol" }} ₴</div>
    </div>
</div>
