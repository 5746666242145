import {Order, Sort} from '../../../../shared/simbol';
import {StorageModel} from '../../../receipt/symbols';
import {
    FolderExpectDataBase,
    StorageMovementFilterModel,
    StorageMovementList,
    TreeFolders,
} from '../../storage.symbols';

export class GetTreeFolders {
    static type = '[StorageList] GetTreeFolders';

    constructor(public id?: number) {
    }
}

export class GetTreeFoldersSuccess {
    static type = '[StorageList] GetTreeFoldersSuccess';

    constructor(public tree: TreeFolders) {
    }
}

export class GetTreeFoldersFail {
    static type = '[StorageList] GetTreeFoldersFail';

    constructor(public error: any) {
    }
}


export class GetGoodsIntoFolder {
    static type = '[StorageList] GetGoodsIntoFolder';

    constructor(public folderId?: number, public name?: string, public isArchive = false) {
    }
}

export class GetGoodsIntoFolderSuccess {
    static type = '[StorageList] GetGoodsIntoFolderSuccess';

    constructor(public goods: StorageModel[], public folder: FolderExpectDataBase[]) {
    }
}

export class GetGoodsIntoFolderFail {
    static type = '[StorageList] GetGoodsIntoFolderFail';

    constructor(public error: any) {
    }
}

export class ClearTreeFolders {
    static type = '[StorageList] ClearTreeFolders';

}

export class LoadMovementGoodsList {
    static type = '[StorageList] loadMovementGoodsList';

    constructor(public lazyLoad?: boolean, public filter?: StorageMovementFilterModel) {
    }
}

export class LoadMovementGoodsListSuccess {
    static type = '[StorageList] loadMovementGoodsListSuccess';

    constructor(public data: StorageMovementList, public lazyLoad?: boolean) {
    }
}

export class LoadMovementGoodsListFail {
    static type = '[StorageList] loadMovementGoodsListFail';

    constructor(public error: any) {
    }
}

export class UpdateMovementGoodsFilter {
    static type = '[StorageList] UpdateMovementGoodsFilter';

    constructor(public filter: StorageMovementFilterModel) {
    }
}


