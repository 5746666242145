import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TalonVgModel} from '../../modules/talon-new/talons-vg.models';
import {TalonService} from '../../modules/talon/services/talon.service';
import {TalonModel, WarrantyEnum} from '../../modules/talon/talon.model';

@Pipe({
    name: 'warranty',
})
export class WarrantyPipe implements PipeTransform {
    constructor(private service: TalonService) {
    }

    transform(date: string, dateLaunch: string, warranty: number): Observable<{dateEndedGuarantee: string; state: WarrantyEnum}> {

        return this.service.getWarranty(date, dateLaunch, warranty).pipe(map(guarantee => ({
            dateEndedGuarantee: guarantee.dateEndedGuarantee,
            state: guarantee.state,
        })));
    }
}

