import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {StorageListStateModel} from '../../states/storage-list-state/storage-list.models';
import {StorageListState} from '../../states/storage-list-state/storage-list.state';
import {StorageManageStateModel} from '../../states/storage-manage-state/storage-manage.models';
import {StorageManageState} from '../../states/storage-manage-state/storage-manage.state';

@Component({
    selector: 'app-storage-tree',
    templateUrl: './storage-tree.component.html',
    styleUrls: ['./storage-tree.component.scss'],
})
export class StorageTreeComponent {
    @Select(StorageListState.treeFolders) treeFolders$: Observable<StorageListStateModel['treeFolders']>;
    @Select(StorageManageState.currentGoods) currentGoods$: Observable<StorageManageStateModel['currentGoods']>;
    @Input() isDialog = false;
    @Output() idFolder = new EventEmitter<number>();
}
