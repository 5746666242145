import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AsteriskService} from 'src/app/modules/asterisk/services/asterisk.service';

interface Mediator {
    id: number;
    adress: string;
    tel: string;
    name: string;
}

@Component({
    selector: 'app-mediator-select-input',
    templateUrl: './mediator-select-input.component.html',
    styleUrls: ['./mediator-select-input.component.scss']
})
export class MediatorSelectInputComponent implements OnChanges, OnDestroy, AfterViewInit {


    @Input() mediatorId: number;
    @Output() selectedMediatorId: EventEmitter<number> = new EventEmitter();

    mediators: Mediator[] = [];
    mediatorsFormControl: FormControl = new FormControl();
    public mediatorsFilterCtrl: FormControl = new FormControl();
    public filteredMediators: Subject<Mediator[]> = new Subject<Mediator[]>();
    public isCall = false;
    protected onDestroy = new Subject<void>();


    constructor(private http: HttpClient, private callService: AsteriskService) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.http.get<{ items: Mediator[] }>('/api/contractors/').pipe(tap(v => {
                this.mediators = v.items;
                this.mediatorsFormControl.setValue(this.mediators.find(f => f.id === this.mediatorId)?.id);
                this.filteredMediators.next(this.mediators.slice());

            }),
            switchMap(() =>
                this.mediatorsFilterCtrl.valueChanges
                    .pipe(takeUntil(this.onDestroy))
            )).subscribe(() => {
            this.filterMediator();
        });
    }


    ngAfterViewInit(): void {
        this.setInitialValue();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }


    select(mediator: number): void {
        this.selectedMediatorId.emit(mediator);
    }


    theCall(id: number): void {
        const mediator = this.mediators.find(f => f.id === id);
        if (mediator.tel.length) {
            this.isCall = true;
            setInterval(() => {
                this.isCall = false;
            }, 5000);
        }


    }

    protected setInitialValue(): void {
        this.filteredMediators
            .pipe(take(1), takeUntil(this.onDestroy))
            .subscribe(() => {
            });
    }

    protected filterMediator(): void {
        if (!this.mediators) {
            return;
        }
        // get the search keyword
        let search = this.mediatorsFilterCtrl.value;
        if (!search) {
            this.filteredMediators.next(this.mediators.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredMediators.next(
            this.mediators.filter(v => v.name.toLowerCase().indexOf(search) > -1)
        );
    }


}
