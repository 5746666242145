 <form class="contacts" [formGroup]="form">
    <mat-form-field appearance="fill">
        <mat-label>Район</mat-label>
        <input matInput formControlName="county"/>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Місцевість</mat-label>
        <input matInput formControlName="city"/>
    </mat-form-field>



    <mat-form-field appearance="fill">
        <mat-label>Адреса</mat-label>
        <input matInput formControlName="street"/>
    </mat-form-field>


    <app-input-tel [telNumber]="orderLocal.tel" (tel)="setTel($event)"></app-input-tel>
    <app-input-tel  [telNumber]="orderLocal.tel2" (tel)="setTel2($event)"></app-input-tel>
    </form>
