import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngxs/store';
import {Subscription} from 'rxjs';
import {TalonService} from '../../../modules/talon/services/talon.service';
import {TalonModel, WarrantyEnum} from '../../../modules/talon/talon.model';

@Component({
    selector: 'app-talon-other',
    templateUrl: './talon-other.component.html',
    styleUrls: ['./talon-other.component.scss'],
})
export class TalonOtherComponent implements OnInit, OnDestroy {

    @Output() talonOtherChanged = new EventEmitter<any>();

    public readonly warrantyStatuses = WarrantyEnum;
    id: number;
    localTalon: TalonModel;
    subscription = new Subscription();

    form = new FormGroup({
        warranty: new FormControl(),
        mediator_price: new FormControl(),
        assembling: new FormControl(),
        debtFlag: new FormControl(),
        debt: new FormControl(),
        isDeactivated: new FormControl(),
    });

    constructor(private store: Store, private service: TalonService) {
    }

    @Input() set talon(talon: any) {

        this.localTalon = {...talon, state: {...talon.state}};
        this.form.controls.warranty.setValue(talon.warranty, {emitEvent: false});
        this.form.controls.mediator_price.setValue(talon.mediator_price, {emitEvent: false});
        this.form.controls.assembling.setValue(talon.assembling, {emitEvent: false});
        this.form.controls.debtFlag.setValue(talon.debtFlag, {emitEvent: false});
        this.form.controls.debt.setValue(talon.debt, {emitEvent: false});
        this.form.controls.isDeactivated.setValue(talon.isDeactivated, {emitEvent: false});

        this.id = talon.id;
    }


    ngOnInit(): void {
        this.subscription.add(
            this.form.valueChanges.subscribe(value => {
                this.localTalon = {...this.localTalon, ...value};
                this.talonOtherChanged.emit(this.localTalon);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    changeDate(date: string): void {
        this.localTalon.dateLaunch = date;
        this.talonOtherChanged.emit(this.localTalon);
    }

    selectMediator(mediatorId: number): void {
        this.localTalon.mediator = mediatorId;
        this.talonOtherChanged.emit(this.localTalon);
    }

    changeDateDebt(date: string): void {
        this.localTalon.dateDebt = date;
        this.talonOtherChanged.emit(this.localTalon);
    }
}
