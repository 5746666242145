<mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>{{label}}</mat-label>
    <input type="text"
           autocomplete="off"
           [placeholder]="placeholder"
           aria-label="Number"
           matInput
           [formControl]="goodControl"
           [matAutocomplete]="auto"
    >
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of goods$ | async" [value]="option.name" (click)="selectItem(option)">
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
