import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {StorageModel} from '../../../receipt/symbols';
import {StorageListStateModel} from '../../states/storage-list-state/storage-list.models';
import {StorageListState} from '../../states/storage-list-state/storage-list.state';

@Component({
    selector: 'app-storage-list',
    templateUrl: './storage-list.component.html',
    styleUrls: ['./storage-list.component.scss'],
})
export class StorageListComponent {
    @Select(StorageListState.treeFolders) treeFolders$: Observable<StorageListStateModel['treeFolders']>;
    @Select(StorageListState.folderHaveGoods) folderHaveGoods$: Observable<StorageListStateModel['folderHaveGoods']>;
    @Select(StorageListState.goods) goods$: Observable<StorageListStateModel['goods']>;
    @Input() isDialog = false;
    @Output() idFolder = new EventEmitter<number>();
    @Output() selectedGood = new EventEmitter<StorageModel>();

}
