import {Directive, ElementRef, Input} from '@angular/core';
import {AddressModel} from '../models/adress.models';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[addressStyle]',
})
export class AddressStyleDirective {

    constructor(private el: ElementRef) {
    }

    @Input() set address(value: AddressModel) {
        const vin = 'rgb(156, 236, 210)';
        const tulchin = 'rgb(241, 184, 129)';
        const litin = 'rgb(146, 188, 207)';
        const khmilnik = 'rgb(205, 146, 207)';

        if (value.county.indexOf('Вінниц') === 0 || value.city.indexOf('Вінниц') === 0) {
            this.el.nativeElement.style.background = vin;
        } else if (value.county.indexOf('Тульчин') === 0 || value.city.indexOf('Тульчин') === 0) {
            this.el.nativeElement.style.background = tulchin;
        } else if (value.county.indexOf('Оратів') === 0 || value.city.indexOf('Оратів') === 0) {
            this.el.nativeElement.style.background = tulchin;
        } else if (value.county.indexOf('Вапнярка') === 0 || value.city.indexOf('Вапнярка') === 0) {
            this.el.nativeElement.style.background = tulchin;
        } else if (value.county.indexOf('Літин') === 0 || value.city.indexOf('Літин') === 0) {
            this.el.nativeElement.style.background = litin;
        } else if (value.county.indexOf('Хмільни') === 0 || value.city.indexOf('Хмільни') === 0) {
            this.el.nativeElement.style.background = khmilnik;
        }
    }


}
