import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {WebSocketConnected} from '@ngxs/websocket-plugin';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {Login, LoginWidthTelegram, LoginWidthTelegramAccess} from '../../core/states/auth/auth.action';
import {AuthState} from '../../core/states/auth/auth.state';
import {PROGRESS_STATUSES} from '../../shared/models/loadingStatus.model/PROGRESS_STATUSES';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss',
        '../../../assets/style/vendor/bootstrap/css/bootstrap.min.css',
        '../../../assets/style/fonts/font-awesome-4.7.0/css/font-awesome.min.css',
        '../../../assets/style/vendor/animate/animate.css',
        '../../../assets/style/vendor/css-hamburgers/hamburgers.min.css',
        '../../../assets/style/vendor/select2/select2.min.css',
        '../../../assets/style/css/util.css',
        '../../../assets/style/css/main.css'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
    @Select(AuthState.isAuthError) errorAuth$: Observable<boolean>;
    @Select(AuthState.loginWidthTelegramStatus) loginWidthTelegramStatus$: Observable<PROGRESS_STATUSES>;
    @Select(AuthState.isRefusal) isRefusal$: Observable<boolean>;

    progressStatuses = PROGRESS_STATUSES;
    subscription = new Subscription();
    authForm: FormGroup;
    isOtherWay = false;
    loginWidthTelegramStatusDebounce$: Observable<PROGRESS_STATUSES>;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
        private actions$: Actions,
    ) {
    }


    ngOnInit() {

        this.loginWidthTelegramStatusDebounce$ = this.loginWidthTelegramStatus$.pipe(debounceTime(500));
        this.authForm = this.formBuilder.group({
            password: ['', Validators.required],
            tel: ['', [Validators.maxLength(10), Validators.minLength(10)]],
        });


        this.subscription.add(
            combineLatest(
                [
                    this.actions$
                        .pipe(ofActionDispatched(WebSocketConnected)),
                    this.authForm.controls.tel.valueChanges,
                ],
            )
                .pipe(filter(([, tel]) => tel.length === 10))
                .subscribe({
                    next: ([, tel]) => this.store.dispatch(new LoginWidthTelegramAccess({
                        isAccess: true,
                        tel,
                    })),
                }),
        );


        this.subscription.add(
            this.authForm.controls.tel.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(v => {
                    if (v.length === 10) {
                        this.store.dispatch(new LoginWidthTelegram(v));
                    }
                }));


    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async signIn() {

        this.authForm.disable();
        if (this.authForm.invalid) {
            return;
        }
        this.store.dispatch(new Login(this.authForm.value));
    }

    anotherWay() {
        this.isOtherWay = !this.isOtherWay;
    }
}
