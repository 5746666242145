<div class="client-wrap" *ngIf="(access$ | async).read_contacts">

    <div class="telBlock">
        <app-input-tel [telNumber]="telLocal" (tel)="getTel($event)"></app-input-tel>
    </div>

    <mat-form-field appearance="fill" class="full-input">
        <mat-label>Користувач</mat-label>
        <input matInput [formControl]="controlUser"/>
    </mat-form-field>

</div>
