import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {ConfigSystemModel} from '../../models/config_system.models';
import {MasterModel} from '../../models/models-for-service.models';
import {ParamsApplicationService} from '../../services/params-application.service';
import {PrinterService, PrinterType} from '../../services/qz-tray.service';
import {AuthState} from '../auth/auth.state';
import {ShowToast} from '../toast/toast.action';
import {
    ClearCoreState,
    ConnectFromWSForPrinting,
    GetAllUsers,
    GetConfigSystem,
    GetCourse,
    GetCurrentMaster,
    GetMaketsForPrinting,
    GetMasters,
    InitCoreState,
    SaveConfigFromServer,
    SetIsQzPrint,
    SetPlatform,
    SetPrinter,
} from './core.action';
import {CoreStateModel, DEFAULT_CORE_STATE} from './core.model';
import {MaketsPrint} from '../../models/makets_print.models';

@State<CoreStateModel>({
    name: 'coreState',
    defaults: DEFAULT_CORE_STATE,
})
@Injectable()
export class CoreState {
    constructor(private service: ParamsApplicationService, public printerService: PrinterService, private store: Store) {
    }

    @Selector()
    static usersDictCode(state: CoreStateModel): CoreStateModel['usersDictCode'] {
        return state.usersDictCode;
    }

    @Selector()
    static isDesktop(state: CoreStateModel): CoreStateModel['isDesktop'] {
        return state.isDesktop;
    }

    @Selector()
    static course(state: CoreStateModel): CoreStateModel['course'] {
        return state.course;
    }

    @Selector()
    static getPrinters(state: CoreStateModel): ConfigSystemModel {
        return state.configSystem.config;
    }

    @Selector()
    static isQzPrint(state: CoreStateModel): boolean {
        return state.configSystem.config.isQzPrint;
    }

    @Selector()
    static getInfoForReceipt(state: CoreStateModel): string {
        return state.configSystem.info_for_receipt;
    }

    @Selector()
    static getMaketsForPrinting(state: CoreStateModel): MaketsPrint['config_system'] {
        return state.configSystem.models_for_printing;
    }

    @Selector()
    static availableMakets(state: CoreStateModel): CoreStateModel['maketsPrint'] {
        return state.maketsPrint;
    }


    @Selector()
    static currentMaster(state: CoreStateModel): MasterModel {
        return state.currentMaster;
    }

    @Selector()
    static masterList(state: CoreStateModel): MasterModel[] {
        return state.masters;
    }


    @Action(InitCoreState)
    initCoreState(ctx: StateContext<CoreStateModel>) {
        ctx.dispatch([
            new GetAllUsers(),
            new GetCourse(),
            new GetMasters(),
            new GetConfigSystem(),
            new GetMaketsForPrinting(),
        ]);
    }

    @Action(SetPlatform)
    setPlatform(ctx: StateContext<CoreStateModel>, {isDesktop}: SetPlatform) {
        ctx.patchState({isDesktop});
    }


    @Action(SetIsQzPrint)
    setIsQzPrint(ctx: StateContext<CoreStateModel>, {isQzPrint}: SetIsQzPrint) {
        const configSystem = {...ctx.getState().configSystem};
        configSystem.config.isQzPrint = isQzPrint;
        ctx.patchState({configSystem});
        ctx.dispatch(new ConnectFromWSForPrinting());
    }

    @Action(SetPrinter)
    setPrinter(ctx: StateContext<CoreStateModel>, {printerType, name}: SetPrinter) {
        const configSystem = {...ctx.getState().configSystem};

        switch (printerType) {
            case PrinterType.DOCUMENT:
                configSystem.config.printer_standart = name;
                break;
            case PrinterType.STICKER:
                configSystem.config.printer_sticker = name;
                break;
            case PrinterType.RECEIPT:
                configSystem.config.printer_receipt = name;
                break;
        }
        ctx.patchState({configSystem});
    }


    @Action(SaveConfigFromServer)
    saveConfigFromServer(ctx: StateContext<CoreStateModel>) {
        this.service.setConfigFromServer(ctx.getState().configSystem).subscribe();
    }

    @Action(GetMasters)
    getMasters(ctx: StateContext<CoreStateModel>) {
        this.service.getMasters().subscribe({
            next: masters => {
                ctx.patchState({masters});
                ctx.dispatch(new GetCurrentMaster(this.store.selectSnapshot(AuthState.user)?.code));
            },
            error: err => ctx.dispatch(new ShowToast({
                summary: 'Помилка!',
                detail: 'Не вдалось завантажити список майстрів, спробуйте пізніше.',
                severity: 'warn',
                life: 7000,
            })),

        });
    }


    @Action(GetCourse)
    getCourse(ctx: StateContext<CoreStateModel>) {
        this.service.getCourse().subscribe({
            next: course => ctx.patchState({course}),
            error: err => ctx.dispatch(new ShowToast({
                summary: 'Помилка!',
                detail: 'Не вдалось завантажити курс валют, спробуйте пізніше.',
                severity: 'warn',
                life: 7000,
            })),
        });
    }

    @Action(GetConfigSystem)
    getConfig(ctx: StateContext<CoreStateModel>) {
        this.service.getConfig().subscribe({
            next: configSystem => ctx.patchState({configSystem}),
            error: err => ctx.dispatch(new ShowToast({
                summary: 'Помилка!',
                detail: 'Не вдалось завантажити список конфігурацію системи, спробуйте пізніше.',
                severity: 'warn',
                life: 7000,
            })),
        });
    }

    @Action(GetMaketsForPrinting)
    getMaketsForPrinting(ctx: StateContext<CoreStateModel>) {
        this.service.getMakets().subscribe({
                next: maketsPrint => ctx.patchState({maketsPrint}),
                error: err => ctx.dispatch(new ShowToast({
                    summary: 'Помилка!',
                    detail: 'Не вдалось завантажити список макетів для друку, спробуйте пізніше.',
                    severity: 'warn',
                    life: 7000,
                })),
            },
        );
    }

    @Action(ConnectFromWSForPrinting)
    connectFromWSForPrinting(ctx: StateContext<CoreStateModel>) {
        if (
            !this.printerService.isAvtive() &&
            ctx.getState().configSystem.config.isQzPrint
        ) {
            this.printerService.connect();
        }
    }

    @Action(GetCurrentMaster)
    getCurrentMaster(ctx: StateContext<CoreStateModel>, {numberMaster}: GetCurrentMaster) {
        const currentMaster = ctx.getState().masters.find(master => master.code === numberMaster);
        ctx.patchState({currentMaster});
    }


    @Action(ClearCoreState)
    clearCoreState(ctx: StateContext<CoreStateModel>) {
        ctx.setState(DEFAULT_CORE_STATE);
    }

    @Action(GetAllUsers)
    getAllUsers(ctx: StateContext<CoreStateModel>) {
        this.service.getAllUsers().subscribe({
            next: users => {
                const usersDict: CoreStateModel['usersDictCode'] = {};
                users.forEach(user => {
                    usersDict[user.code] = user;
                });
                ctx.patchState({
                    usersDictCode: usersDict,
                });
            },
            error: err => ctx.dispatch(new ShowToast({
                summary: 'Помилка!',
                detail: 'Не вдалось завантажити список користувачів, спробуйте пізніше.',
                severity: 'warn',
                life: 7000,
            })),
        });
    }
}
