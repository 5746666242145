<div class="wrapper" [ngClass]="{'read-only': readOnly}">

    <mat-form-field appearance="fill" class="formField" *ngIf="!readOnly; else masterNameReadOnly">
        <mat-label>Майстер</mat-label>
        <mat-select [formControl]="mastersFormControl" placeholder="Майстер">
            <mat-option>
                <ngx-mat-select-search [formControl]="mastersFilterCtrl" placeholderLabel="Пошук..."
                                       noEntriesFoundLabel="Жодного результату"></ngx-mat-select-search>
            </mat-option>


            <mat-option [value]="">
                Не обрано
            </mat-option>


            <mat-option *ngFor="let master of filteredMasters | async" [value]="master.code" >
                <span>{{master.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-template #masterNameReadOnly>
        <ng-content></ng-content>
        {{masterName}}
    </ng-template>
    <ng-container
            *ngIf="{
            isCallNumber: mastersFormControl.value,
            inProgress: (toCallStatus$ | async) === progressStatuses.IN_PROGRESS,
            connectStatus: connectStatus$ | async
            } as extracted"
    >

        <div class="btn" [class.whiteBackground]="extracted.isCallNumber && extracted.inProgress">

            <button mat-icon-button
                    [disabled]="extracted.inProgress || !extracted.isCallNumber || extracted.connectStatus !== connectWs.CONNECT"
                    (click)="toCall()">
                <mat-icon *ngIf="extracted.inProgress === false">phone</mat-icon>
                <img src="../../../../assets/animation/call.gif" *ngIf="extracted.inProgress" alt="">
            </button>

        </div>
    </ng-container>
</div>

