import {InvoiceModel} from '../../invoice.model';


export class OpenInvoice {
    static type = '[InvoiceManage] OpenInvoice';

    constructor(public invoice: InvoiceModel) {
    }
}

export class CloseInvoice {
    static type = '[InvoiceManage] CloseInvoice';

    constructor(public uid: string) {
    }
}


export class GetInvoiceById {
    static type = '[InvoiceManage] GetInvoiceById';

    constructor(public id: number) {
    }
}


export class GetInvoiceByIdSuccess {
    static type = '[InvoiceManage] GetInvoiceByIdSuccess';

    constructor(public invoice: InvoiceModel) {
    }
}

export class GetInvoiceByIdFail {
    static type = '[InvoiceManage] GetInvoiceByIdFail';

    constructor(public error: any) {
    }
}

export class UpdateInvoiceField {
    static type = '[InvoiceManage] UpdateInvoiceField';

    constructor(public data: Partial<InvoiceModel>, public id: string) {
    }
}


export class PrintInvoice {
    static type = '[InvoiceManage] PrintInvoice';
}

export class PrintContractSales {
    static type = '[InvoiceManage] PrintContractSales';
}

export class PrintExpenditure {
    static type = '[InvoiceManage] PrintExpenditure';
}

export class PrintActInvoice {
    static type = '[InvoiceManage] PrintActInvoice';
}

export class PrintServiceAgreement {
    static type = '[InvoiceManage] PrintServiceAgreement';
}

export class PrintSuccess {
    static type = '[InvoiceManage] PrintSuccess';

    constructor(public url: string) {
    }
}


export class CloseInvoiceForce {
    static type = '[InvoiceManage] CloseInvoiceForce';

    constructor(public uid: string) {
    }
}


export class ResetSelectedIndexInvoice {
    static type = '[InvoiceManage] ResetSelectedIndexInvoice';

}

export class OpenDialogSaveInvoice {
    static type = '[InvoiceManage] OpenDialogSaveInvoice';

}


export class OpenDialogClosedInvoice {
    static type = '[InvoiceManage] OpenDialogClosedInvoice';

}

export class ResetDialogInvoice {
    static type = '[InvoiceManage] ResetDialogInvoice';

}


export class ClearInvoice {
    static type = '[InvoiceManage] ClearInvoice';
}

export class ClearErrorInvoice {
    static type = '[InvoiceManage] ClearErrorInvoice';
}

export class SetErrorInvoice {
    static type = '[InvoiceManage] SetErrorInvoice';

    constructor(public error: any) {
    }
}

export class SaveInvoice {
    static type = '[InvoiceManage] SaveInvoice';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}


export class SaveInvoiceSuccess {
    static type = '[InvoiceManage] SaveInvoiceSuccess';

    constructor(public data: InvoiceModel, public uid: string, public forceClose: boolean) {
    }
}

export class UpdateInvoiceSuccess {
    static type = '[InvoiceManage] UpdateInvoiceSuccess';

    constructor(public uid: string, public data: InvoiceModel, public forceClose?: boolean) {
    }
}

export class CreateInvoice {
    static type = '[InvoiceManage] CreateInvoice';

    constructor(public invoice: InvoiceModel) {
    }
}
