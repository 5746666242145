import {Injectable} from '@angular/core';
import {RouterDataResolved} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export interface RtRouterDataResolverModel {
  url: string;
  params: any;
  queryParams: any;
  data: any;
  wholeData: { [url: string]: any };
}

@State<RtRouterDataResolverModel>({
  name: 'routerState',
  defaults: {
    url: '',
    params: {},
    queryParams: {},
    data: {},
    wholeData: {},
  },
})
@Injectable()
export class RtRouterDataResolverState {

  @Selector()
  static url(state: RtRouterDataResolverModel): string {
    return state.url;
  }

  @Selector()
  static params(state: RtRouterDataResolverModel): unknown {
    return state.params;
  }

  @Selector()
  static queryParams(state: RtRouterDataResolverModel): unknown {
    return state.queryParams;
  }

  @Selector()
  static currentRouteData(state: RtRouterDataResolverModel): unknown {
    return state.data;
  }

  @Selector()
  static routeWholeDataDict(state: RtRouterDataResolverModel): { [url: string]: any } {
    return state.wholeData;
  }

  @Selector()
  static routeMergedData(state: RtRouterDataResolverModel): Record<string, unknown> {
    const mergedData = {};
    for (const url in state.wholeData) {
      if (!Object.getOwnPropertyDescriptor(state.wholeData, url)) {
        continue;
      }
      Object.assign(mergedData, state.wholeData[url]);
    }
    return mergedData;
  }

  @Action(RouterDataResolved)
  resolveRouteData(ctx: StateContext<RtRouterDataResolverModel>, {routerState}: RouterDataResolved): void {
    const {
      url,
      root: {queryParams},
    } = routerState;

    const wholeData = {};

    let {root: route} = routerState;
    while (route.firstChild) {
      route = route.firstChild;
      if (route.url[0]?.path) {
        wholeData[route.url[0]?.path] = route.data;
      }
    }

    const {params, data} = route;

    ctx.patchState({
      url,
      params,
      queryParams,
      data,
      wholeData,
    });
  }
}
