import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    URL = '/api/directory_adress/';

    constructor(private _http: HttpClient) {
    }

    getStreet(street: string, city: string): Observable<string[]> {
        return this._http.get<{ data: string[] }>(`${this.URL}getStreet?street=${street}&city=${city}`).pipe(map(v => v.data));
    }

    getCity(county: string, city: string): Observable<string[]> {
        return this._http.get<{ data: string[] }>(`${this.URL}getCity?county=${county}&city=${city}`).pipe(map(v => v.data));
    }

    getCounty(county: string): Observable<string[]> {
        return this._http.get<{ data: string[] }>(`${this.URL}getCounty?county=${county}`).pipe(map(v => v.data));
    }


}
