<div *ngIf="treeFolders$ | async as tree">
    <div class="breadcrumb">
        <div
                class="breadcrumb__parent"
                [routerLink]="isDialog ? null : '/dashboard/storage/list/'"
                (click)="idFolder.next(null)"
        >
            <span class="path-text">Склад</span>
        </div>
        <div *ngFor="let parent of tree.parentFolders">
            <div
                    class="breadcrumb__parent"
                    [routerLink]="isDialog ? null : '/dashboard/storage/list/'+parent.id"
                    (click)="idFolder.next(parent.id)"
            >
                <img class="breadcrumb__img" src="/assets/img/arrow-right.svg" alt="">
                <span class="path-text">{{parent.name}}</span>
            </div>
        </div>

        <div
                class="breadcrumb__parent"
                [routerLink]="isDialog ? null : '/dashboard/storage/list/'+tree.currentFolder.id"
                *ngIf="tree.currentFolder?.id"
                (click)="idFolder.next(+tree.currentFolder.id)"
        >
            <img class="breadcrumb__img" src="/assets/img/arrow-right.svg" alt="">
            <span class="path-text">{{tree.currentFolder?.name}}</span>
        </div>

        <div class="breadcrumb__parent" *ngIf="currentGoods$ | async as currentGoods">
            <img class="breadcrumb__img" src="/assets/img/arrow-right.svg" alt="">
            <span class="path-text_goods">{{currentGoods.name}}</span>
        </div>
    </div>

</div>
