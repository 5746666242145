import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import jwt_decode from 'jwt-decode';
import {DateTime} from 'luxon';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {AuthService} from 'src/app/core/services/auth.service';
import {SetToken} from '../states/auth/auth.action';
import {UserModel} from '../states/auth/auth.model';
import {AuthState} from '../states/auth/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private AUTH_HEADER = 'Authorization';
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private tokenExpirationTime: number;

    constructor(private auth: AuthService, private router: Router, private store: Store) {
        this.store.select(AuthState.user)
            .pipe(filter(user => !!user && !!user.token))
            .subscribe(user => {
            this.tokenExpirationTime = (jwt_decode(user.token) as any).exp;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.shouldRefreshToken() && !this.refreshTokenInProgress) {

            this.refreshTokenInProgress = true;

            return this.refreshAccessToken().pipe(
                switchMap((result) => {
                    this.store.dispatch(new SetToken(result.token));
                    this.refreshTokenInProgress = false;
                    return next.handle(this.addAuthenticationToken(req));
                }),
            );
        }

        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status >= 400 && req.url === '/api/auth/refresh-token') {
                    this.router.navigate(['/dashboard/logout']);
                    return throwError(error);
                }
                if (error && error.status === 401) {
                    if (this.refreshTokenInProgress) {
                        return this.refreshTokenSubject.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.addAuthenticationToken(req))),
                        );
                    } else {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);

                        return this.refreshAccessToken().pipe(
                            switchMap((result) => {
                                this.store.dispatch(new SetToken(result.token));
                                this.refreshTokenSubject.next(!!result);

                                return next.handle(this.addAuthenticationToken(req));
                            }),
                            finalize(() => {
                                this.refreshTokenInProgress = false;
                            }),
                        );
                    }
                } else {
                    if (req.url === '/api/auth/refresh-token') {
                        this.router.navigate(['/dashboard/logout']);
                    }
                    return throwError(error);
                }
            }),
        );
    }

    private shouldRefreshToken(): boolean {
        const currentTime = DateTime.now().toUnixInteger();
        const refreshTime = this.tokenExpirationTime - 600;
        return refreshTime < currentTime;
    }

    private refreshAccessToken(): Observable<UserModel> {
        return this.auth.refreshToken();
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        if (!this.store.selectSnapshot(AuthState.isAuthenticate)) {
            return request;
        }
        return request.clone({
            headers: request.headers.set(this.AUTH_HEADER, this.store.selectSnapshot(AuthState.token)),
        });
    }
}
