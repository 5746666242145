import {StateOrder} from 'src/app/modules/orders/order.models';
import {TelModel} from '../../../../core/models/tel.models';
import {ContractModel} from '../../../contracts/contract.models';
import {StoriesModel} from '../../../stories/story.model';
import {TalonModel} from '../../../talon/talon.model';
import {OrderModel} from '../../order.models';
import {ResponseTurboSMSModel} from '../../order.symbols';

export class ResponseOrderWS {
    static type = '[Order] Response Order WS';

    constructor(public order: { rating: number, comment: string, id: number }) {
    }
}


export class WSUpdateOrders {
    static type = '[Orders] UpdateOrders';
}

export class ResetSelectedIndexOrder {
    static type = '[Orders] ResetSelectedIndexOrder';
}

export class OpenOrder {
    static type = '[Orders] OpenOrder';

    constructor(public order: OrderModel) {
    }
}

export class CloseOrder {
    static type = '[Orders] CloseOrder';

    constructor(public uid: string) {
    }
}


export class CloseOrderForce {
    static type = '[Orders] CloseOrderForce';

    constructor(public uid: string) {
    }
}

export class OpenOrderById {
    static type = '[Orders] OpenOrderById';

    constructor(public id: string) {
    }
}

export class GetOrderById {
    static type = '[Orders] GetOrderById';

    constructor(public id: string) {
    }
}

export class GetOrderByIdSuccess {
    static type = '[Orders] GetOrderByIdSuccess';

    constructor(public order: OrderModel) {
    }
}

export class GetOrderByIdFail {
    static type = '[Orders] GetOrderByIdFail';

    constructor(public error: any) {
    }
}


export class SetOrderFields {
    static type = '[Orders] SetOrderFields';

    constructor(public orderPartial: Partial<OrderModel>) {
    }
}

export class SetStateOrder {
    static type = '[Orders] SetStateOrder';

    constructor(public state: StateOrder) {
    }
}

export class SetDateIssued {
    static type = '[Orders] SetDateIssued';

    constructor(public isUpdate: boolean) {
    }
}


export class OpenDialogSaveOrder {
    static type = '[Orders] OpenDialogSaveOrder';
}

export class ResetDialogOrder {
    static type = '[Orders] ResetDialogOrder';
}

export class OpenDialogClosedOrder {
    static type = '[Orders] OpenDialogClosedOrder';
}


export class SetErrorOrder {
    static type = '[Orders] SetErrorOrder';

    constructor(public error: any) {
    }
}

export class ClearErrorOrder {
    static type = '[Orders] ClearErrorOrder';
}


export class SaveOrder {
    static type = '[Orders] SaveOrder';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}

export class SaveOrderSuccess {
    static type = '[Orders] SaveOrderSuccess';

    constructor(public order: OrderModel, public uid: string, public forceClose?: boolean) {
    }
}


export class UpdateOrderSuccess {
    static type = '[Orders] UpdateOrderSuccess';

    constructor(public uid: string, public data: { success: boolean; message: string; version: number }, public forceClose?: boolean) {
    }
}


export class CreateOrder {
    static type = '[Orders] CreateOrder';

    constructor(public order?: OrderModel) {
    }
}

export class GetStoryForOrder {
    static type = '[Orders] GetStoryForOrder';

    constructor(
        public openDialog: boolean = true,
        public tel?: TelModel,
        public tel2?: TelModel,
        public sn?: string,
        public limit?: number,
    ) {
    }
}

export class GetStoryForOrderSuccess {
    static type = '[Orders] GetStoryForOrderSuccess';

    constructor(public stories: StoriesModel, public openDialog: boolean = true) {
    }
}

export class GetStoryForOrderFail {
    static type = '[Orders] GetStoryForOrderFail';

    constructor(public error: any) {
    }
}

export class GetTalonsForOrder {
    static type = '[Orders] GetTalonsForOrder';

}

export class GetTalonesForOrderSuccess {
    static type = '[Orders] GetTalonesForOrderSuccess';

    constructor(public talons: TalonModel[]) {
    }
}

export class GetContractsForOrder {
    static type = '[Orders] GetContractsForOrder';

}

export class GetContractsForOrderSuccess {
    static type = '[Orders] GetContractsForOrderSuccess';

    constructor(public contracts: ContractModel[]) {
    }
}

export class OpenDialogStoriesOrder {
    static type = '[Orders] OpenDialogStoriesOrder';

    constructor(public tel?: TelModel, public tel2?: TelModel, public sn?: string, public limit?: number) {
    }
}

export class OpenDialogStoriesOrderSuccess {
    static type = '[Orders] OpenDialogStoriesOrderSuccess';

    constructor(public stories: StoriesModel) {
    }
}

export class OpenDialogStoriesOrderFail {
    static type = '[Orders] OpenDialogStoriesOrderFail';

    constructor(public error: any) {
    }
}

export class ClearOrder {
    static type = '[Orders] ClearOrder';

}

export class ConductOrder {
    static type = '[Orders] ConductOrder';

}


export class GetFeedbackForOrder {
    static type = '[Orders] GetFeedbackOrder';
}

export class GetFeedbackForOrderSuccess {
    static type = '[Orders] GetFeedbackOrderSuccess';

    constructor(public result: ResponseTurboSMSModel) {
    }
}

export class GetFeedbackForOrderFail {
    static type = '[Orders] GetFeedbackOrderFail';

    constructor(public error: any) {
    }
}

export class AcceptOrder {
    static type = '[Orders] AcceptOrder';

    constructor(public order: OrderModel) {
    }
}


export class AcceptOrderSuccess {
    static type = '[Orders] AcceptOrderSuccess';

    constructor(public order: OrderModel) {
    }
}


export class AcceptOrderFail {
    static type = '[Orders] AcceptOrderFail';

    constructor(public error: any) {
    }
}



