import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';

import {isPlatformBrowser} from '@angular/common';
import {BASE_URL} from './models';

@Injectable({
    providedIn: 'root',
})
export class RtPlatformService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        @Optional() @Inject(BASE_URL) private serverBaseUrl: string,
    ) {
    }


    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

}
