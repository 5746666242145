import {StoriesModel} from '../../../stories/story.model';
import {
    AsteriskEndpoint,
    CallInAsteriskModel,
    ConfigAsteriskModel,
    CONNECT_STATUS_WS,
    RoutineModel,
} from '../../asterisk.symbols';

export class InitParamsAsterisk {
    static type = '[Asterisk] InitParamsAsterisk';

    constructor(
        public port: number, public hostWS: string,
        public apiUser: string, public apiPassword: string,
        public webHost: string, protocol: string) {
    }
}

export class ChangeConnectARI {
    static type = '[Asterisk] ChangeConnectARI';

    constructor(public payload: CONNECT_STATUS_WS) {
    }
}

export class CallingIn {
    static type = '[Asterisk] CallingIn';

    constructor(public payload: CallInAsteriskModel) {
    }
}

export class UpdateCallState {
    static type = '[Asterisk] UpdateState';

    constructor(public payload: CallInAsteriskModel) {
    }
}

export class RemoveCall {
    static type = '[Asterisk] RemoveCall';

    constructor(public payload: string) {
    }
}

export class GetChannels {
    static type = '[Asterisk] GetChannels';

    constructor(public channels: CallInAsteriskModel[]) {
    }
}

export class GetStoryForPhone {
    static type = '[Asterisk] GetStoryForPhone';

    constructor(public channel: CallInAsteriskModel) {
    }
}

export class GetStoryForPhoneSuccess {
    static type = '[Asterisk] GetStoryForPhoneSuccess';

    constructor(public data: StoriesModel, public channel: CallInAsteriskModel) {
    }
}

export class GetStoryForPhoneFail {
    static type = '[Asterisk] GetStoryForPhoneFail';

    constructor(public error: any) {
    }
}

export class SetVolume {
    static type = '[Asterisk] SetVolume';

    constructor(public value: number) {
    }
}

export class SetCountConnected {
    static type = '[Asterisk] SetCountConnected';

    constructor(public count: number) {
    }
}

export class ToCall {
    static type = '[Asterisk] ToCall';

    constructor(public tel: string) {
    }
}

export class ToCallSuccess {
    static type = '[Asterisk] ToCallSuccess';

}

export class ToCallFail {
    static type = '[Asterisk] ToCallFail';

}

export class GetEndpoints {
    static type = '[Asterisk] GetEndpoints';

}

export class GetEndpointsSuccess {
    static type = '[Asterisk] GetEndpointsSuccess';

    constructor(public endpoints: AsteriskEndpoint[]) {
    }
}

export class GetEndpointsFail {
    static type = '[Asterisk] GetEndpointsFail';

    constructor(public error: any) {
    }
}


export class SetRoutine {
    static type = '[Asterisk] SetRoutine';

    constructor(public isRoutine: boolean) {
    }
}

export class GetRoutine {
    static type = '[Asterisk] GetRoutine';

}

export class GetRoutineSuccess {
    static type = '[Asterisk] GetRoutineSuccess';

    constructor(public routine: RoutineModel) {
    }
}

export class GetRoutineFail {
    static type = '[Asterisk] GetRoutineFail';

    constructor(public error: any) {
    }
}

export class UpdateRoutine {
    static type = '[Asterisk] UpdateRoutine';

    constructor(public routine: RoutineModel) {
    }
}

export class UpdateRoutineSuccess {
    static type = '[Asterisk] UpdateRoutineSuccess';

    constructor(public routine: RoutineModel) {
    }
}

export class UpdateRoutineFail {
    static type = '[Asterisk] UpdateRoutineFail';

    constructor(public error: any) {
    }
}

export class UpdateConnectConfig {
    static type = '[Asterisk] UpdateConnectConfig';

    constructor(public config: ConfigAsteriskModel) {
    }
}

export class UpdateConnectConfigSuccess {
    static type = '[Asterisk] UpdateConnectConfigSuccess';

    constructor(public config: ConfigAsteriskModel) {
    }
}

export class UpdateConnectConfigFail {
    static type = '[Asterisk] UpdateConnectConfigFail';

    constructor(public error: any) {
    }
}
