import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, startWith, tap} from 'rxjs/operators';
import {EquipmentService} from '../../../../shared/components/equipment/equipment.service';
import {StorageModel} from '../../symbols';

@Component({
    selector: 'app-goods-selector',
    templateUrl: './goods-selector.component.html',
    styleUrls: ['./goods-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoodsSelectorComponent implements OnInit {
    @Output() valueChanged: EventEmitter<string> = new EventEmitter();
    @Output() selectedGoods: EventEmitter<StorageModel> = new EventEmitter();
    @Input() defaultValue: StorageModel;
    @Input() addKetel = true;
    nameLoad: BehaviorSubject<StorageModel[]> = new BehaviorSubject([]);
    control = new FormControl();


    formFieldValue = '';
    selectedGoodsValue: StorageModel;

    constructor(private service: EquipmentService) {
    }

    ngOnInit(): void {
        this.control.setValue(this.defaultValue.name, {emitEvent: false});

        this.control.valueChanges
            .pipe(
                tap(value => {
                    this.formFieldValue = '';
                    this.selectedGoodsValue = null;
                    if (typeof value === 'string') {
                        this.formFieldValue = value;
                        this.valueChanged.emit(this.formFieldValue);
                    } else {
                        this.selectedGoodsValue = value;
                    }
                }),
                startWith(this.control.value),
                debounceTime(300))
            .subscribe(value => {
                this.service.getLibraryEquipment(value, 'all', 'all')
                    .subscribe((storage) => {
                        this.nameLoad.next(storage);
                    });


            });
    }

    selected(value: StorageModel): void {
        this.selectedGoodsValue = value;
        this.change();
    }

    loadItems(): void {
        let search = '';
        if (typeof this.control.value === 'string' || !this.control.value) {
            search = this.control.value;
        } else {
            search = this.control.value.name;
        }
        this.service.getLibraryEquipment(search, 'all', 'all')
            .pipe(debounceTime(500))
            .subscribe((v) => {
                this.nameLoad.next(v);
            });

    }

    change(): void {
        if (this.selectedGoodsValue) {
            this.selectedGoods.emit(this.selectedGoodsValue);
        } else if (this.formFieldValue) {
            this.valueChanged.emit(this.formFieldValue);
        }
    }


}
