import {Injectable} from '@angular/core';
import {from as fromPromise, Observable} from 'rxjs';

import * as qz from 'qz-tray';
import {sha256} from 'js-sha256';
import {Store} from '@ngxs/store';
import {CoreState} from '../states/core/core.state';

export enum PrinterType {
    STICKER = 'sticker',
    DOCUMENT = 'document',
    RECEIPT = 'receipt'
}


@Injectable({
    providedIn: 'root',
})
export class PrinterService {
    // npm install qz-tray js-sha256 rsvp --save
    constructor(private store: Store) {
        qz.api.setSha256Type((data) => sha256(data));
        qz.api.setPromiseType((resolver) => new Promise(resolver));
    }

    getPrinters(): Observable<any> {
        if (!qz.websocket.isActive()) {
            console.log('not connect');
            return fromPromise(qz.websocket.connect().then(() => qz.printers.find()));
        } else {
            return fromPromise(qz.printers.find());
        }
    }

    // Get the SPECIFIC connected printer
    getPrinter(printerName: string): Observable<any> {
        if (qz.websocket.isActive()) {
            console.log('not connect');
            this.connect();
        }

        return fromPromise(qz.printers.find(printerName));
    }

    connect(): void {
        if (!qz.websocket.isActive()) {
            console.log('wait...');
            qz.websocket.connect().then(
                function() {
                    console.log('connect');
                },
                function() {
                    console.log('error connect websocket qz-tray');
                }
            );
        }
    }

    disconnect(): void {
        qz.websocket.disconnect();
    }

    isAvtive(): boolean {
        return qz.websocket.isActive();
    }


    printData(printer: PrinterType, data: any): Observable<any> {
        if (!qz.websocket.isActive()) {
            console.log('not connect');
            this.connect();
        }

        const printers = this.store.selectSnapshot(CoreState.getPrinters);
        let printerName = '';

        switch (printer) {
            case PrinterType.STICKER:
                printerName = printers.printer_sticker;
                break;
            case PrinterType.RECEIPT:
                printerName = printers.printer_receipt;
                break;
            case PrinterType.DOCUMENT:
                printerName = printers.printer_standart;
                break;

            default:
                printerName = printers.printer_standart;
                break;
        }

        const config = qz.configs.create(printerName);
        return fromPromise(qz.print(config, data));
    }


    tryPrint(fileUrl: string, printer: PrinterType): void {
        const data = [
            {
                type: 'pdf',
                format: 'pdf',
                data: fileUrl,
            },
        ];
        if (this.isAvtive() && printer) {
            this
                .printData(
                    printer,
                    data
                )
                .subscribe(
                    () => {
                        console.log('printing...');
                    },
                    (err) => {
                        window.open(fileUrl, '_blank');
                    }
                );
        } else {
            window.open(fileUrl, '_blank');
        }
    }
}
