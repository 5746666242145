export enum TabCategoriesModel {
    Talon = 'talon',
    Movement = 'movement',
    TalonVg = 'talon-vg',
    Order = 'order',
    Journal = 'journal',
    Stand = 'stand/manage',
    WorkPerformed = 'wp-manage',
    Contract = 'contracts/contract',
    CustomerCard = 'customer-card',
    Role = 'role',
    User = 'user',
    Master = 'master',
    Rediscount = 'rediscount_doc',
    Departure = 'departure',
    Contractors = 'contractors',
    Invoice = 'invoice',
    Enterprise = 'enterprise',
}

export interface TabsStructure {
    label: string;
    uid?: string;
    app: TabCategoriesModel;
    id?: number | string;
    isChanged: boolean;
    url: string;
}

export interface StateTabs {
    selectedIndex: number;
    previousTabs: {
        first?: TabsStructure;
        last?: TabsStructure;
    };
    tabs: TabsStructure[];
}
