import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-storage-create-folder-dialog',
    templateUrl: './storage-create-folder-dialog.component.html',
    styleUrls: ['./storage-create-folder-dialog.component.scss'],
})
export class StorageCreateFolderDialogComponent {
    form = new FormControl(null);

    constructor(public dialogRef: MatDialogRef<StorageCreateFolderDialogComponent>) {
    }

    close() {
        this.dialogRef.close(false);
    }

    create() {
        this.dialogRef.close(this.form.value);
    }
}
