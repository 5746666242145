import {Subscription} from 'rxjs';
import {
    Component,
    EventEmitter,
    Output,
    OnInit,
    Input, OnDestroy,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@Component({
    selector: 'app-date-picker-dmy',
    templateUrl: './date-picker-dmy.component.html',
    styleUrls: ['./date-picker-dmy.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class DatePickerDMYComponent implements OnInit, OnDestroy {
    @Output() dateOut: EventEmitter<string> = new EventEmitter();
    @Input() label: string;


    date = new FormControl(moment());

    subscription = new Subscription();

    constructor(private dateAdapter: DateAdapter<any>) {
        this.dateAdapter.setLocale('uk_UA');

    }

    @Input() set dateIn(date: string) {
        this.date.setValue(moment(date), {emitEvent: false});
    }


    ngOnInit(): void {
        this.subscription.add(
            this.date.valueChanges.subscribe(v => {
                this.dateOut.emit(v ? moment(v).add().format('YYYY-MM-DD') : null);
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
