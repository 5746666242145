import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-storage-filter',
    templateUrl: './storage-filter.component.html',
    styleUrls: ['./storage-filter.component.scss'],
})
export class StorageFilterComponent implements OnInit, OnDestroy {
    @Output() filter = new EventEmitter();
    form = new FormGroup({
        name: new FormControl(),
    });
    private readonly subscription = new Subscription();


    ngOnInit(): void {

        this.subscription.add(
            this.form.controls.name.valueChanges
                .pipe(debounceTime(300))
                .subscribe({
                    next: (value) => this.filter.next(value),
                }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
