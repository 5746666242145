import {Directive, ElementRef, Input} from '@angular/core';
import {TalonModel, WarrantyEnum} from '../../modules/talon/talon.model';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[guaranteedStyle]'
})
export class GuaranteedStyleDirective {

    constructor(private el: ElementRef) {
    }

    @Input() set talon(value: TalonModel) {
        const guaranteedColor = 'rgb(0, 131, 136)';
        const notGuaranteedColor = 'rgb(155, 0, 0)';
        const notLaunchedColor = 'rgb(151, 128, 24)';

        this.el.nativeElement.style.color = guaranteedColor;
        if (value.state.state === WarrantyEnum.GUARANTEED) {
            this.el.nativeElement.style.color = guaranteedColor;
        } else if (value.state.state === WarrantyEnum.NOT_GUARANTEED) {
            this.el.nativeElement.style.color = notGuaranteedColor;
        } else if (value.state.state === WarrantyEnum.NOT_LAUNCHED) {
            this.el.nativeElement.style.color = notLaunchedColor;
        }
    }


}
