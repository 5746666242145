<div class="wrapper">

  <mat-form-field appearance="fill" class="formField">
    <mat-label>Посередник</mat-label>
    <mat-select [formControl]="mediatorsFormControl" placeholder="Посередник" #singleSelect>
      <mat-option>
        <ngx-mat-select-search [formControl]="mediatorsFilterCtrl" placeholderLabel="Пошук..."
          noEntriesFoundLabel="Жодного результату"></ngx-mat-select-search>
      </mat-option>



      <mat-option [value]="" (click)="select(null)">
        Не обрано
      </mat-option>


      <mat-option *ngFor="let mediator of filteredMediators | async" [value]="mediator.id"
        (click)="select(mediator.id)">
        {{mediator.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <div class="btn" [class.whiteBackground]="isCall">

    <button mat-icon-button [disabled]="isCall || !mediatorsFormControl.value"
      (click)="theCall(mediatorsFormControl.value)">
      <mat-icon *ngIf="!isCall">phone</mat-icon>
      <img
              src="../../../../assets/animation/call.gif"
              *ngIf="isCall" alt="">

    </button>

  </div>
</div>
