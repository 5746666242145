<div class="storage-list" *ngIf="{tree: treeFolders$ | async, folderHaveGoods: folderHaveGoods$ | async} as extracted">

    <ng-container *ngIf="extracted.folderHaveGoods?.length || !extracted.folderHaveGoods; else folders">
        <ng-container *ngTemplateOutlet="backFolder"></ng-container>
        <div *ngFor="let folder of extracted?.folderHaveGoods">

            <div
                    class="folder"
                    *ngIf="folder?.id !== extracted.tree?.currentFolder?.id && folder?.id !== extracted.tree?.currentFolder?.parent_id"
                    [routerLink]="isDialog ? null : '/dashboard/storage/list/' + folder?.id"
                    (click)="idFolder.next(folder?.id)"
            >
                <img class="folder-img" src="/assets/img/folder.svg" alt="folder">
                <span class="folder-text">{{folder.name}}</span>

            </div>

        </div>

    </ng-container>

    <ng-template #folders>
        <ng-container *ngTemplateOutlet="backFolder"></ng-container>
        <div class="folder" *ngFor="let folder of extracted?.tree?.childrenFolders"
             [routerLink]="isDialog ? null : '/dashboard/storage/list/' + folder.id"
             (click)="idFolder.next(folder.id)"
        >
            <img class="folder-img" src="/assets/img/folder.svg" alt="folder">
            <span class="folder-text">{{folder.name}}</span>
        </div>
    </ng-template>

    <ng-template #backFolder>
        <div
                *ngIf="extracted.tree?.currentFolder"
                class="folder"
                [routerLink]="isDialog ? null :'/dashboard/storage/list/' + (extracted.tree?.currentFolder.parent_id ? extracted.tree.currentFolder.parent_id : '')"
                (click)="idFolder.next(extracted.tree?.currentFolder.parent_id ? extracted.tree.currentFolder.parent_id : null)"
        >
            <img class="folder-img" src="/assets/img/folder.svg" alt="folder">
            <span class="folder-text">...</span>
        </div>

    </ng-template>


    <div
            *ngFor="let item of goods$ | async"
            [routerLink]="isDialog ? null : '/dashboard/storage/manage/'+item.id"
            (click)="selectedGood.next(item)"
            class="goods"
            [matTooltip]="'Ціна: '+item.price + 'грн. | ' + 'Оптова: '+ item.price_opt + 'грн. | ' + ' На складі: '+ item.count + ' од.'"
            [matTooltipPosition]="'right'"

    >
        <img class="goods-img" src="/assets/img/article.svg" alt="">
        <span class="goods-text">{{item.name}}</span>
    </div>

</div>

