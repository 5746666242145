import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './components/confirm/confirm-dialog.component';
import {InformDialogComponent} from './components/inform-dialog/inform-dialog.component';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        InformDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
    ],
    exports: [
        ConfirmDialogComponent,
        InformDialogComponent
    ]
})
export class ConfirmDialogModule {
}
