import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MessageModule} from 'primeng/message';
import {CoreModule} from '../core/core.module';
import {DataPickerModule} from '../modules/data-picker/data-picker.module';
import {OpenIAModule} from '../modules/open-ia/open-ia.module';
import {ContactsComponent} from '../modules/orders/components/contacts/contacts.component';
import {ReceiptModule} from '../modules/receipt/receipt.module';
import {SearchSelectorModule} from '../modules/search-selector/search-selector.module';
import {TelFieldModule} from '../modules/tel-field/tel-field.module';
import {AddressComponent} from './components/address/address.component';
import {ClientFullComponent} from './components/client-full/client-full.component';
import {ClientComponent} from './components/client/client.component';
import {DatePickerDMYComponent} from './components/date-picker-dmy/date-picker-dmy.component';
import {DatePickerLaunchComponent} from './components/date-picker-launch/date-picker-launch.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {DialogViewPdfComponent} from './components/dialog-view-pdf/dialog-view-pdf.component';
import {
    DialogChangeStateComponent,
    DialogCloseComponent,
    DialogComponent,
    DialogErrorComponent,
    DialogStoryComponent,
    PrintingDialogCloseComponent,
    WarningDebtDialogComponent,
} from './components/dialog/dialog.component';
import {EquipmentMobileComponent} from './components/equipment-mobile/equipment-mobile.component';
import {EquipmentComponent} from './components/equipment/equipment.component';
import {InputNameReceiptComponent} from './components/input-name-receipt/input-name-receipt.component';
import {MastersSelectInputComponent} from './components/masters-select-input/masters-select-input.component';
import {MediatorSelectInputComponent} from './components/mediator-select-input/mediator-select-input.component';
import {TalonOtherComponent} from './components/talon-other/talon-other.component';
import {ToastsContainerComponent} from './components/toast-global/toasts-container.component';
import {GuaranteedStyleDirective} from './directives/guarant.style.directive';

@NgModule({
    declarations: [
        DatePickerComponent,
        ToastsContainerComponent,
        DialogViewPdfComponent,
        DialogComponent,
        DialogErrorComponent,
        DialogCloseComponent,
        DialogChangeStateComponent,
        WarningDebtDialogComponent,
        DialogStoryComponent,
        PrintingDialogCloseComponent,
        DatePickerDMYComponent,
        MastersSelectInputComponent,
        MediatorSelectInputComponent,
        ClientFullComponent,
        AddressComponent,
        EquipmentComponent,
        GuaranteedStyleDirective,
        ContactsComponent,
        EquipmentMobileComponent,
        ClientComponent,
        InputNameReceiptComponent,
        TalonOtherComponent,
        DatePickerLaunchComponent,
    ],
    exports: [
        DialogViewPdfComponent,
        DialogComponent,
        DialogErrorComponent,
        DialogCloseComponent,
        DialogChangeStateComponent,
        WarningDebtDialogComponent,
        DialogStoryComponent,
        DatePickerDMYComponent,
        MastersSelectInputComponent,
        MediatorSelectInputComponent,
        ClientFullComponent,
        AddressComponent,
        EquipmentComponent,
        GuaranteedStyleDirective,
        ContactsComponent,
        EquipmentMobileComponent,
        ClientComponent,
        InputNameReceiptComponent,
        TalonOtherComponent,
        DatePickerComponent,
        ToastsContainerComponent,


    ],
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        MatButtonToggleModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        MatAutocompleteModule,
        MessageModule,
        DataPickerModule,
        ReceiptModule,
        ClipboardModule,
        SearchSelectorModule,
        TelFieldModule,
        CoreModule,
        OpenIAModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatCheckboxModule,

    ],

})
export class SharedModule {
}
