<div class="storage-create-goods-dialog">
    <form [formGroup]="form" class="storage-create-goods-dialog__form">
        <mat-form-field>
            <mat-label>Найменування товару</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Артикул товару</mat-label>
            <input matInput formControlName="art" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Ціна</mat-label>
            <input matInput formControlName="price" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Оптова ціна</mat-label>
            <input matInput formControlName="price_opt" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Оплата майсту</mat-label>
            <input matInput formControlName="master_price" autocomplete="off">
        </mat-form-field>


        <mat-radio-group color="warn" class="radio-group" aria-label="Тип" formControlName="isAccessories">
            <mat-radio-button [value]="true">Комплектуючі</mat-radio-button>
            <mat-radio-button [value]="false">Агригат</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group color="warn" class="radio-group" aria-label="Стан" formControlName="used_equipment">
            <mat-radio-button [value]="true">Вживане</mat-radio-button>
            <mat-radio-button [value]="false">Нове</mat-radio-button>
        </mat-radio-group>

    </form>

    <div class="actions">
        <button mat-button (click)="create()">Створити</button>
        <button mat-button (click)="close()">Закрити</button>
    </div>

</div>
