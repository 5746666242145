
<p mat-dialog-title style="text-align: center">
  <mat-icon style="font-size: 60px; width: 100px; height: 100%">{{data.icon}}</mat-icon>
</p>
<h1 mat-dialog-title style="text-align: center;">{{data.title}}</h1>
<div mat-dialog-content style="max-width: 500px;">{{data.message}} <a *ngIf="data.link" [href]="data.link">{{data.link}}</a></div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>ОК</button>
</mat-dialog-actions>
