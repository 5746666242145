import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {RtLoadingModule} from '../rt-loading/rt-loading.module';
import { OpenIaComponent } from './open-ia.component';
import {OpenAIService} from './openAI.service';



@NgModule({
  declarations: [
    OpenIaComponent,
  ],
  imports: [
    CommonModule,
    RtLoadingModule,
    MatButtonModule,
  ],
  exports: [
    OpenIaComponent,
  ],
  providers: [OpenAIService]
})
export class OpenIAModule { }
