import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {Order, Sort} from '../../../../shared/simbol';
import {StorageModel} from '../../../receipt/symbols';
import {
    FolderExpectDataBase,
    StorageMovementFilterModel,
    StorageMovementModel,
    TreeFolders,
} from '../../storage.symbols';

export interface StorageListStateModel {
    loadTreeFoldersState: PROGRESS_STATUSES;
    treeFolders: TreeFolders;
    goods: StorageModel[];
    loadGoodsStatus: PROGRESS_STATUSES;
    folderHaveGoods: FolderExpectDataBase[];
    movementGoodsList: StorageMovementModel[];
    movementGoodsListTotal: number;
    loadMovementGoodsListStatus: PROGRESS_STATUSES;
    movementGoodsListFilter: StorageMovementFilterModel;
}

export const MOVEMENT_GOODS_LIST_FILTER_DEFAULT: StorageMovementFilterModel = {
    first: 0,
    count: 50,
    typeMovement: null,
    order: Order.DESC,
    sort: Sort.CREATED,
    name: null,
};


export const STORAGE_STATE_DEFAULT: StorageListStateModel = {
    loadTreeFoldersState: PROGRESS_STATUSES.NOT_INITIALIZE,
    treeFolders: null,
    goods: [],
    folderHaveGoods: [],
    loadGoodsStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    movementGoodsList: [],
    movementGoodsListTotal: null,
    loadMovementGoodsListStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    movementGoodsListFilter: MOVEMENT_GOODS_LIST_FILTER_DEFAULT,
};


