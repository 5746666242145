import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {StoryDoc} from 'src/app/core/models/story.models';
import {CloseDialog} from '../../../modules/confirm-dialog/state/dialog.action';
import {SetDateIssued} from '../../../modules/orders/state/order/orders.action';

export interface DialogData {
    message: string;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { title: string; message: string; permission: boolean },
    ) {
    }


}

@Component({
    selector: 'app-dialog-error',
    templateUrl: './dialogError.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogErrorComponent {
    // error = 'Версія документу не співпадає.';
    constructor(
        public dialogRef: MatDialogRef<WarningDebtDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            message: string;
            icon: string;
            link?: string;
        },
    ) {
    }

}

@Component({
    selector: 'app-close-close',
    templateUrl: './dialogClose.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogCloseComponent {
    constructor() {
    }

}

@Component({
    selector: 'app-close-change-state',
    templateUrl: './dialogChangeState.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogChangeStateComponent {
    constructor(public dialogRef: MatDialogRef<DialogChangeStateComponent>, private store: Store) {
    }

    close(value: boolean): void {
        this.dialogRef.close(value);
        this.store.dispatch(new SetDateIssued(value));
    }
}

@Component({
    selector: 'app-warning-debt-dialog',
    templateUrl: './warningDebtDialog.html',
    styleUrls: ['./dialog.component.scss'],
})
export class WarningDebtDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<WarningDebtDialogComponent>,
        private store: Store,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            // eslint-disable-next-line id-blacklist
            message: { nameDoc: string; dateDoc: Date; debt: number; number: number; typeDoc: string }[];
        },
    ) {
    }


    close(): void {
        this.dialogRef.close();
        this.store.dispatch(new CloseDialog());
    }

}

@Component({
    selector: 'app-story-dialog',
    templateUrl: './dialogStory.html',
    styleUrls: ['./dialog.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
            ),
        ]),
    ],
})
export class DialogStoryComponent {

    columnsToDisplay: string[] = ['nameDoc', 'date', 'state'];
    expandedElement: StoryDoc | null;

    constructor(
        public dialogRef: MatDialogRef<DialogStoryComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dataSource: {
            story: StoryDoc[];
        },
        private store: Store,
    ) {
    }


    closeDialog(): void {
        this.dialogRef.close();
        this.store.dispatch(new CloseDialog());
    }
}


@Component({
    selector: 'app-printing',
    templateUrl: './printingDialog.html',
    styleUrls: ['./dialog.component.scss'],
})
export class PrintingDialogCloseComponent {
    constructor() {
    }

}
