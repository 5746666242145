<div class="storage-filter">
    <form [formGroup]="form">
        <mat-form-field class="storage-filter__input">
            <mat-label>Назва товару</mat-label>
            <input matInput type="text" formControlName="name" autocomplete="off">
            <button *ngIf="form.controls.name.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.controls.name.setValue('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
</div>
