import {Component, OnDestroy, OnInit} from '@angular/core';
import {Navigate} from '@ngxs/router-plugin';
import {Select, Store} from '@ngxs/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {GetPermissions} from '../core/states/auth/auth.action';
import {AuthState} from '../core/states/auth/auth.state';
import {GetCourse} from '../core/states/core/core.action';
import {RtRouterDataResolverState} from '../modules/rt-router-data-resolver/rt-router-data-resolver.state';

@Component({
    selector: 'app-define-auth',
    templateUrl: './define-auth.component.html',
    styleUrls: ['./define-auth.component.scss'],
})
export class DefineAuthComponent implements OnInit, OnDestroy {
    @Select(AuthState.isAuthenticate) isAuthenticate$: Observable<boolean>;
    @Select(RtRouterDataResolverState.currentRouteData) currentRouteData$: Observable<{ dashboard: boolean }>;

    subscription = new Subscription();

    constructor(private store: Store) {
    }

    ngOnInit(): void {
        this.subscription.add(combineLatest([this.currentRouteData$, this.isAuthenticate$])
            .pipe(
                take(1),
            )
            .subscribe({
                next: ([data, isAuth]) => {
                    if (!!isAuth) {
                        this.store.dispatch([new GetPermissions(), new GetCourse(), new Navigate(['/dashboard'])]);
                    } else {
                        if (data.dashboard) {
                            this.store.dispatch(new Navigate(['/login']));
                        }

                    }
                },
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
