import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CourseClass} from '../models/auxiliary.models';
import {ConfigSystemStructure} from '../models/config_system.models';
import {MaketsPrint} from '../models/makets_print.models';
import {MasterModel} from '../models/models-for-service.models';
import {UserModel} from '../states/auth/auth.model';

@Injectable({
    providedIn: 'root',
})
export class ParamsApplicationService {

    constructor(private http: HttpClient) {
    }

    getCourse(): Observable<CourseClass[]> {
        return this.http.get<CourseClass[]>('/api/auxiliary/getCourse');
    }

    getMasters(): Observable<MasterModel[]> {
        return this.http.get<MasterModel[]>('/api/auxiliary/get_library_masters');
    }

    getConfig(): Observable<ConfigSystemStructure> {
        return this.http
            .get<ConfigSystemStructure>('/api/auxiliary/get_config');
    }

    setConfigFromServer(data: ConfigSystemStructure): Observable<any> {
        return this.http.post('/api/auxiliary/updateConfig/', {data});
    }

    getMakets(): Observable<MaketsPrint> {
        return this.http.get<MaketsPrint>('/api/auxiliary/makets/');
    }

    getAllUsers(): Observable<UserModel[]> {
        return this.http.get<UserModel[]>('/api/auth/users/');
    }

    getBackupStatus(): Observable<{ status: string, date: string }> {
        return this.http.get<{ status: string, date: string }>('/api/backup/status/');
    }

    createBackupDb(): Observable<void> {
        return this.http.post<void>('/api/backup/', {});
    }
}
