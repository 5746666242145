import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {ConnectWebSocket, WebSocketDisconnected} from '@ngxs/websocket-plugin';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {RoleModel} from './core/states/auth/auth.model';
import {AuthState} from './core/states/auth/auth.state';
import {SetPlatform} from './core/states/core/core.action';
import {ToastStateModel} from './core/states/toast/toast.model';
import {ToastState} from './core/states/toast/toast.state';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @Select(AuthState.access) access$: Observable<RoleModel>;
    @Select(ToastState.toast) toast$: Observable<ToastStateModel['toast']>;

    title = 'vaillant';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private deviceService: DeviceDetectorService,
        private messageService: MessageService,
        private actions$: Actions,
    ) {
    }

    ngOnInit(): void {

        this.epicFunction();

        let protocol = 'ws://';
        if (window.location.protocol === 'https:') {
            protocol = 'wss://';
        }

        const connectWebSocket = new ConnectWebSocket({url: `${protocol}${window.location.host}/websocket`});

        this.store.dispatch([connectWebSocket]);
        this.actions$.pipe(
            ofActionDispatched(WebSocketDisconnected),
            debounceTime(2000),
        ).subscribe(() => {
            this.store.dispatch(connectWebSocket);
        });


        this.toast$.subscribe(toast => {
            this.messageService.add(toast);
        });


    }


    epicFunction(): void {
        this.store.dispatch(new SetPlatform(!(this.deviceService.isMobile())));
    }
}
