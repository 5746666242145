import {PrinterType} from '../../services/qz-tray.service';
import {UserModel} from '../auth/auth.model';

export class InitCoreState {
    static type = '[Core] InitCoreState';
}


export class SetPlatform {
    static type = '[Core] SetPlatform';

    constructor(public isDesktop: boolean) {
    }
}

export class SetIsQzPrint {
    static type = '[Core] SetIsQzPrint';

    constructor(public isQzPrint: boolean) {
    }
}

export class SetPrinter {
    static type = '[Core] SetPrinter';

    constructor(public printerType: PrinterType, public name: string) {
    }
}

export class SaveConfigFromServer {
    static type = '[Core] SaveConfigFromServer';

}


export class GetMasters {
    static type = '[Core] GetMasters';

}


export class GetCourse {
    static type = '[Core] GetCourse';

}


export class GetConfigSystem {
    static type = '[Core] GetConfigSystem';

}


export class GetMaketsForPrinting {
    static type = '[Core] GetMaketsForPrinting';

}


export class ConnectFromWSForPrinting {
    static type = '[Core] ConnectFromWSForPrinting';

}

export class GetCurrentMaster {
    static type = '[Core] GetCurrentMaster';


    constructor(public numberMaster: string) {
    }
}

export class ClearCoreState {
    static type = '[Core] ClearCoreState';

}


export class GetAllUsers {
    static type = '[Core] GetAllUsers';

}

