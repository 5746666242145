import {PROGRESS_STATUSES} from '../../../../shared/models/loadingStatus.model/PROGRESS_STATUSES';
import {AsteriskEndpoint, CallInAsteriskModel, CONNECT_STATUS_WS, RoutineModel} from '../../asterisk.symbols';

export interface AsteriskStateModel {
    volume: number;
    /** Is visible or not visible panel calling */
    isHidePanel: boolean;

    /** Call forwarding is enabled or disabled */
    directConnection: boolean;

    /** Number to be redirected to */
    directNumber: number;

    countConnected: number;

    /** Array of calls */
    channels: CallInAsteriskModel[];

    loadingStatusCustomerContact: PROGRESS_STATUSES;

    /** Web socket connection status */
    connectStatus: CONNECT_STATUS_WS;

    webPort: number;

    apiKey: string;

    toCallStatus: PROGRESS_STATUSES;

    endpoints: AsteriskEndpoint[];
    loadEndpointStatus: PROGRESS_STATUSES;
    isRoutine: boolean;
    routine: RoutineModel;
    getRoutineStatus: PROGRESS_STATUSES;

    updateConnectConfStatus: PROGRESS_STATUSES;
}

export const ASTERISK_DEFAULT_STATE: AsteriskStateModel = {
    volume: 0,
    isHidePanel: false,
    directConnection: false,
    directNumber: null,
    channels: [],
    connectStatus: CONNECT_STATUS_WS.DISCONNECT,
    webPort: null,
    apiKey: null,
    loadingStatusCustomerContact: PROGRESS_STATUSES.NOT_INITIALIZE,
    countConnected: 0,
    toCallStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    endpoints: [],
    loadEndpointStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    isRoutine: false,
    routine: null,
    getRoutineStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
    updateConnectConfStatus: PROGRESS_STATUSES.NOT_INITIALIZE,
};
