import {TelModel} from '../../../../core/models/tel.models';
import {ContractModel} from '../../../contracts/contract.models';
import {StoriesModel} from '../../../stories/story.model';
import {TalonModel} from '../../../talon/talon.model';
import {StandModel} from '../../stand.models';

export class OpenStand {
    static type = '[StandManage] OpenStand';

    constructor(public stand: StandModel) {
    }
}


export class CloseStand {
    static type = '[StandManage] CloseStand';

    constructor(public uid: string) {
    }
}


export class CloseStandForce {
    static type = '[StandManage] CloseStandForce';

    constructor(public uid: string) {
    }
}

export class ResetSelectedIndexStand {
    static type = '[StandManage] ResetSelectedIndexStand';

}

export class GetStandById {
    static type = '[StandManage] GetStandById';

    constructor(public id: number) {
    }
}

export class GetStandByIdSuccess {
    static type = '[StandManage] GetStandByIdSuccess';

    constructor(public stand: StandModel) {
    }
}

export class GetStandByIdFail {
    static type = '[StandManage] GetStandByIdFail';

    constructor(public error: any) {
    }
}

export class UpdateStandPartial {
    static type = '[StandManage] UpdateStandPartial';

    constructor(public stand: Partial<StandModel>, public id: string) {
    }
}

export class OpenDialogSaveStand {
    static type = '[StandManage] OpenDialogSaveStand';

}

export class OpenDialogClosedStand {
    static type = '[StandManage] OpenDialogClosedStand';

}

export class ResetDialogStand {
    static type = '[StandManage] ResetDialogStand';

}


export class ClearStand {
    static type = '[StandManage] ClearStand';
}

export class ClearErrorStand {
    static type = '[StandManage] ClearErrorStand';
}

export class SetErrorStand {
    static type = '[StandManage] SetErrorStand';

    constructor(public error: any) {
    }
}

export class SaveStand {
    static type = '[StandManage] SaveStand';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}


export class SaveStandSuccess {
    static type = '[StandManage] SaveStandSuccess';

    constructor(public data: StandModel, public uid: string, public forceClose: boolean) {
    }
}

export class UpdateStandSuccess {
    static type = '[StandManage] UpdateStandSuccess';

    constructor(public uid: string, public data: StandModel, public forceClose?: boolean) {
    }
}

export class CreateStand {
    static type = '[StandManage] CreateStand';

    constructor(public stand: StandModel) {
    }
}

export class GetTalonsForStand {
    static type = '[StandManage] GetTalonsForStand';

}

export class GetTalonsForStandSuccess {
    static type = '[StandManage] GetTalonsForStandSuccess';

    constructor(public talons: TalonModel[]) {
    }
}


export class GetContractsForStand {
    static type = '[StandManage] GetContractsForStand';

}


export class GetContractsForStandSuccess {
    static type = '[StandManage] GetContractsForStandSuccess';

    constructor(public contracts: ContractModel[]) {
    }
}


export class GetStoryForStand {
    static type = '[StandManage] GetStoryForStand';

    constructor(
        public openDialog: boolean = true,
        public tel?: TelModel,
        public tel2?: TelModel,
        public sn?: string,
        public limit?: number) {
    }
}

export class GetStoryForStandSuccess {
    static type = '[StandManage] GetStoryForStandSuccess';

    constructor(public openDialog: boolean = true, public stories: StoriesModel) {
    }
}

export class GetStoryForStandFail {
    static type = '[StandManage] GetStoryForStandFail';

    constructor(public error: any) {
    }
}


export class OpenDialogStoriesStand {
    static type = '[StandManage] OpenDialogStoriesStand';

    constructor(public tel?: TelModel, public sn?: string, public limit?: number) {
    }
}

export class OpenDialogStoriesStandSuccess {
    static type = '[StandManage] OpenDialogStoriesStandSuccess';

    constructor(public stories: StoriesModel) {
    }
}

export class OpenDialogStoriesStandFail {
    static type = '[StandManage] OpenDialogStoriesStandFail';

    constructor(public error: any) {
    }
}


export class PrintStandAct {
    static type = '[StandManage] PrintStandAct';
}

export class PrintStandSticker {
    static type = '[StandManage] PrintStandSticker';
}

export class OpenStandById {
    static type = '[StandManage] OpenStandById';

    constructor(public id: number) {
    }
}





