import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {OpenAIService} from './openAI.service';
import {SseService} from '../../core/services/sse.service';

@Component({
    selector: 'app-open-ia',
    templateUrl: './open-ia.component.html',
    styleUrls: ['./open-ia.component.scss'],
})
export class OpenIaComponent {

    @Input() ketelName: string;
    @Input() reason: string;
    helpText$ = new BehaviorSubject('');
    loadingChatGpt = new BehaviorSubject(false);

    constructor(private serviceOpenIA: OpenAIService, private dialog: MatDialog, private sse: SseService, private cd: ChangeDetectorRef) {
    }

    help(question: string) {
        this.helpText$.next('');
        this.loadingChatGpt.next(true);


        this.serviceOpenIA.sendQuestion(question).subscribe((v) => {
            if (v === '[DONE]') {
                this.sse.closeServerSentEvents();
                this.loadingChatGpt.next(false);
            } else {
                if (JSON.parse(v).choices[0].delta.content) {
                    this.helpText$.next((this.helpText$.value || '') + JSON.parse(v).choices[0].delta.content);
                }
            }
            this.cd.detectChanges();
        });

    }
}
