import {TelModel} from '../../../../core/models/tel.models';
import {StoriesModel} from '../../../stories/story.model';
import {ContractModel} from '../../contract.models';

export class OpenContract {
    static type = '[ContractManage] OpenContract';

    constructor(public contract: ContractModel) {
    }
}

export class CloseContract {
    static type = '[ContractManage] CloseContract';

    constructor(public uid: string) {
    }
}


export class OpenDialogSaveContract {
    static type = '[ContractManage] OpenDialogSaveContract';

}

export class OpenDialogClosedContract {
    static type = '[ContractManage] OpenDialogClosedContract';

}

export class ResetDialogContract {
    static type = '[ContractManage] ResetDialogContract';

}


export class CloseContractForce {
    static type = '[ContractManage] CloseContractForce';

    constructor(public uid: string) {
    }
}

export class ResetSelectedIndexContract {
    static type = '[ContractManage] ResetSelectedIndexContract';

}


export class GetContractById {
    static type = '[ContractManage] GetContractById';

    constructor(public id: number) {
    }
}

export class GetContractByIdSuccess {
    static type = '[ContractManage] GetContractByIdSuccess';

    constructor(public contract: ContractModel) {
    }
}

export class GetContractByIdFail {
    static type = '[ContractManage] GetContractByIdFail';

    constructor(public error: any) {
    }
}

export class UpdateContractPartial {
    static type = '[ContractManage] UpdateContractPartial';

    constructor(public contract: Partial<ContractModel>, public id: string) {
    }
}

export class ClearContract {
    static type = '[ContractManage] ClearContract';
}

export class SaveContract {
    static type = '[ContractManage] SaveContract';

    constructor(public uid: string, public forceClose?: boolean) {
    }
}

export class SaveContractSuccess {
    static type = '[ContractManage] SaveContractSuccess';

    constructor(public data: ContractModel, public uid: string, public forceClose: boolean) {
    }
}

export class UpdateContractSuccess {
    static type = '[ContractManage] UpdateContractSuccess';

    constructor(public uid: string, public data: ContractModel, public forceClose?: boolean) {
    }
}

export class SaveContractFail {
    static type = '[ContractManage] SaveContractFail';

    constructor(public error: any) {
    }
}

export class UpdateContractFail {
    static type = '[ContractManage] UpdateContractFail';

    constructor(public error: any) {
    }
}

export class CreateContract {
    static type = '[ContractManage] CreateContract';

    constructor(public contract?: ContractModel) {
    }
}


export class OpenDialogStoriesContract {
    static type = '[ContractManage] OpenDialogStoriesContract';

    constructor(public tel?: TelModel, public sn?: string, public limit?: number) {
    }
}

export class OpenDialogStoriesContractSuccess {
    static type = '[ContractManage] OpenDialogStoriesContractSuccess';

    constructor(public stories: StoriesModel) {
    }
}

export class OpenDialogStoriesContractFail {
    static type = '[ContractManage] OpenDialogStoriesContractFail';

    constructor(public error: any) {
    }
}

export class PrintContract {
    static type = '[ContractManage] PrintContract';

}

export class OpenContractById {
    static type = '[ContractManage] OpenContractById';

    constructor(public id: number) {
    }

}
