<mat-form-field appearance="fill" style="width: 120px;
margin: 5px;">
  <mat-label>{{label}}</mat-label>

  <input matInput [matDatepicker]="dp" [formControl]="date">
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp
                  startView="multi-year"
                  (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</mat-form-field>
