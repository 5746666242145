import {LoginModel, OnlineModel, RoleModel, UserModel} from './auth.model';


export class Logout {
    static type = '[auth] Logout';

}


export class Login {
    static type = '[auth] Login';

    constructor(public data: LoginModel) {
    }
}


export class LoginWidthTelegram {
    static type = '[auth] LoginWidthTelegram';

    constructor(public tel: string) {
    }
}

export class LoginWidthTelegramSuccess {
    static type = '[auth] LoginWidthTelegramSuccess';

    constructor(public uuid: string, public isExistTelegram: boolean) {
    }
}

export class LoginWidthTelegramFail {
    static type = '[auth] LoginWidthTelegramFail';

    constructor(public error: any) {
    }
}


export class LoginWidthTelegramAccess {
    static type = '[auth] LoginWidthTelegramAccess';

    constructor(public data: { isAccess: boolean, tel: string }) {
    }

}


export class LoginSuccess {
    static type = '[auth] LoginSuccess';

    constructor(public user: UserModel) {
    }
}


export class LoginFail {
    static type = '[auth] LoginFail';

    constructor(public error: any) {
    }
}


export class SetToken {
    static type = '[auth] SetToken';

    constructor(public token: string) {
    }
}

export class GetPermissions {
    static type = '[auth] GetPermissions';

    constructor() {
    }
}

export class GetPermissionsSuccess {
    static type = '[auth] GetPermissions Success';

    constructor(public roles: RoleModel) {
    }
}


export class GetPermissionsFail {
    static type = '[auth] GetPermissions Fail';

    constructor(public error: any) {
    }
}


export class SetOnlineList {
    static type = '[auth] SetOnlineList';

    constructor(public list: OnlineModel[]) {
    }
}

