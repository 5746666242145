import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {OrderService} from '../modules/orders/services/order.service';
import {OrdersListService} from '../modules/orders/services/ordersList.service';
import {TokenInterceptor} from './classes/token.interceptor';
import {AddressStyleDirective} from './directives/address-style.directive';
import {WarrantyPipe} from './pipes/warranty.pipe';
import {AuthService} from './services/auth.service';
import {ParamsApplicationService} from './services/params-application.service';

@NgModule({
    declarations: [
        WarrantyPipe,
        AddressStyleDirective,
    ],
    imports: [CommonModule],
    exports: [WarrantyPipe, AddressStyleDirective],
    providers: [
        AuthService,
        OrderService,
        OrdersListService,
        ParamsApplicationService,
        {provide: HTTP_INTERCEPTORS, multi: true, useClass: TokenInterceptor},
    ],
})
export class CoreModule {
}
