<div class="limiter" style="height: 100%;">

    <div class="container-login100" style="height: 100%; min-height: 100% ;overflow: scroll;">
        <div class="wrap-login100 shadow">
            <div class="login100-pic js-tilt" data-tilt>
                <img src="assets/img/logo.png" alt="IMG">
            </div>
            <ng-container
                    *ngIf="{
                    loginWidthTelegramStatusDebounce: loginWidthTelegramStatusDebounce$ | async,
                    isRefusal: isRefusal$ | async
                    } as extracted">
                <form class="login100-form validate-form" [formGroup]="authForm">
        <span class="login100-form-title">
          Авторизація
        </span>


                    <div class="wrap-input100 validate-input" style="margin-bottom: 5px;
          margin-top: 10px;">
                        <input class="input100" type="tel" [maxlength]="10" name="tel" placeholder="Телефон"
                               formControlName="tel" required>

                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                <mat-icon>call</mat-icon>
              </span>
                    </div>


                    <div *ngIf="isOtherWay || extracted.loginWidthTelegramStatusDebounce === progressStatuses.ERROR">
                        <div class="wrap-input100 validate-input" style="margin-bottom: 5px;   margin-top: 10px;">
                            <input class="input100" type="password" name="password" placeholder="Password"
                                   formControlName="password"
                                   required>
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </span>
                        </div>

                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn" (click)="signIn()">
                                Login
                            </button>
                        </div>

                    </div>


                    <ng-container
                            *ngIf="
                       extracted.loginWidthTelegramStatusDebounce === progressStatuses.IN_PROGRESS
                         && !isOtherWay">

                        <div  *ngIf="extracted.isRefusal === false; else isRefusal"
                             style="display: flex; justify-content: center;">
                            <mat-spinner class="loader" style="margin: 50px;" strokeWidth="3"></mat-spinner>
                        </div>

                        <ng-template #isRefusal>
                            <div class="is-refusal">Відмова в доступі</div>
                        </ng-template>

                        <div *ngIf="extracted.isRefusal === false" class="got-to-telegram">

                            <img src="assets/img/telegram.svg" style="height: 50px;" alt="">
                            <span style="margin-top: 20px">Перейдіть в телеграм</span>
                        </div>


                        <button class="login100-form-btn" style="margin-top: 10px; margin-bottom: 10px;"
                                (click)="anotherWay()">
                            Інший спосіб
                        </button>
                    </ng-container>

                </form>
            </ng-container>

        </div>
        <div style="width: 100%;
    display: flex;
    justify-content: center;">
            <button class="login100-form-btn" style="width: 250px;">
                <a href="https://t.me/VaillantVinBot"> ТЕЛЕГРАМ БОТ<img src="assets/img/telegram.svg" style="height: 30px;
    margin-left: 20px;">
                </a>
            </button>

        </div>

    </div>

</div>

