import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-storage-create-goods-dialog',
    templateUrl: './storage-create-goods-dialog.component.html',
    styleUrls: ['./storage-create-goods-dialog.component.scss'],
})
export class StorageCreateGoodsDialogComponent {

    form = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        art: new FormControl(),
        master_price: new FormControl(),
        price: new FormControl(null, [Validators.required]),
        price_opt: new FormControl(null, [Validators.required]),
        used_equipment: new FormControl(false),
        isAccessories: new FormControl(false),
    });

    constructor(public dialogRef: MatDialogRef<StorageCreateGoodsDialogComponent>) {
    }

    close() {
        this.dialogRef.close(false);
    }

    create() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
