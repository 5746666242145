import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {NgxMaskModule} from 'ngx-mask';
import { InputTelComponent } from './components/input-tel/input-tel.component';



@NgModule({
    declarations: [
        InputTelComponent,
    ],
    exports: [
        InputTelComponent,
    ],
    imports: [
        CommonModule,
        NgxMaskModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        MatButtonModule,
    ],
})
export class TelFieldModule { }
