import {Injectable} from '@angular/core';
import {Navigate} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CloseOrder} from 'src/app/modules/orders/state/order/orders.action';
import {CloseContract} from '../../../contracts/states/contract-manage-state/contract-manage.actions';
import {CloseInvoice} from '../../../invoices/states/invoice-manage-state/invoice-manage.actions';
import {CloseStand} from '../../../stand/states/stand-manage/stand-manage.actions';
import {CloseMovementGoods} from '../../../storage/states/storage-manage-state/storage-manage.actions';
import {CloseTalonVg} from '../../../talon-new/states/talon-vg/talon-vg.action';
import {CloseTalon} from '../../../talon/states/talon/talon.actions';
import {AddTab, ClearTabs, CloseTabByUID, IsChangedDoc, RemoveTab, SelectTab, ToCloseTab} from './tabs.action';
import {StateTabs, TabCategoriesModel, TabsStructure} from './tabs.models';


@State<StateTabs>({
    name: 'tabs',
    defaults: {
        selectedIndex: 0,
        previousTabs: {first: null, last: null},
        tabs: [],
    },
})
@Injectable()
export class TabsState {
    constructor() {
    }

    @Selector()
    static getTabs(state: StateTabs): TabsStructure[] {
        return state.tabs;
    }

    @Selector()
    static getSelectedIndex(state: StateTabs): number {
        return state.selectedIndex;
    }


    @Action(AddTab)
    addTab(ctx: StateContext<StateTabs>, {tab}: AddTab): void {
        const tabs = ctx.getState().tabs;

        if (tabs.find((f) => f.url === tab.url)) {
            ctx.dispatch(
                new SelectTab(
                    tabs.findIndex((i) => i.url === tab.url),
                    tab,
                ),
            );
            return;
        }

        const previousTabs = {...ctx.getState().previousTabs};

        previousTabs.first = previousTabs.last;
        previousTabs.last = tab;

        ctx.patchState({
            tabs: [...tabs, tab],
            selectedIndex: tabs.length,
            previousTabs,
        });

        ctx.dispatch([new Navigate([tab.url])]);
    }

    @Action(ToCloseTab)
    toCloseTab(ctx: StateContext<StateTabs>, {tab, uid, url}: ToCloseTab): void {
        let localTab = tab;
        if (!localTab) {
            localTab = [...ctx.getState().tabs].find((f) => {
                if (uid) {
                    return f.uid === uid;
                } else if (url) {
                    return f.url === url;
                }
            });
        }
        switch (localTab.app) {
            case TabCategoriesModel.Journal: {
                ctx.dispatch(new RemoveTab(localTab));
                break;
            }
            case TabCategoriesModel.Order: {
                ctx.dispatch(new CloseOrder(localTab.uid));
                break;
            }
            case TabCategoriesModel.Talon: {
                ctx.dispatch(new CloseTalon(localTab.uid));
                break;
            }
            case TabCategoriesModel.TalonVg: {
                ctx.dispatch(new CloseTalonVg(localTab.uid));
                break;
            }
            case TabCategoriesModel.WorkPerformed: {
                ctx.dispatch(new RemoveTab(localTab));
                break;
            }
            case TabCategoriesModel.Stand: {
                ctx.dispatch(new CloseStand(localTab.uid));
                break;
            }
            case TabCategoriesModel.Invoice: {
                ctx.dispatch(new CloseInvoice(localTab.uid));
                break;
            }
            case TabCategoriesModel.Contract: {
                ctx.dispatch(new CloseContract(localTab.uid));
                break;
            }
            case TabCategoriesModel.Movement: {
                ctx.dispatch(new CloseMovementGoods(+localTab.id));
                break;
            }
            case TabCategoriesModel.CustomerCard: {
                ctx.dispatch(new RemoveTab(localTab));
                break;
            }
        }
    }

    @Action(CloseTabByUID)
    closeTabByUID(ctx: StateContext<StateTabs>, {uid}: CloseTabByUID): void {
        const tab = [...ctx.getState().tabs].find((f) => f.uid === uid);
        ctx.dispatch(new RemoveTab(tab, uid));
    }

    @Action(RemoveTab)
    removeTab(ctx: StateContext<StateTabs>, {tab, uid}: RemoveTab): void {
        let tabs: TabsStructure[];
        if (uid) {
            tabs = [...ctx.getState().tabs].filter((f) => f.uid !== uid);
            tab = [...ctx.getState().tabs].find((f) => f.uid === uid);
        } else {
            tabs = [...ctx.getState().tabs].filter((f) => f.url !== tab.url);
        }

        const previousTabs = {...ctx.getState().previousTabs};

        ctx.patchState({
            tabs,
        });

        if (!tabs.length) {
            ctx.dispatch(new Navigate(['/']));
            return;
        }
        if (previousTabs.last.url === tab.url) {
            const index = tabs.findIndex((f) => f.url === previousTabs.first.url);
            const redirectTab = tabs[index];
            if (redirectTab) {
                ctx.dispatch(new SelectTab(index, redirectTab));
            } else {
                ctx.dispatch(new SelectTab(tabs.length - 1, tabs[tabs.length - 1]));
            }
        } else {
            ctx.patchState({
                selectedIndex: ctx.getState().selectedIndex - 1,
            });

        }
    }

    @Action(ClearTabs)
    clearTabs(ctx: StateContext<StateTabs>): void {
        ctx.patchState({tabs: [], selectedIndex: 0, previousTabs: {first: null, last: null}});
    }

    @Action(SelectTab)
    selectTab(ctx: StateContext<StateTabs>, {index, tab}: SelectTab): void {
        const previousTabs = {...ctx.getState().previousTabs};
        previousTabs.first = previousTabs.last;
        previousTabs.last = tab;

        ctx.patchState({
            previousTabs,
            selectedIndex: index,
        });
        ctx.dispatch(new Navigate([tab.url]));
    }

    @Action(IsChangedDoc)
    isChangedDoc(ctx: StateContext<StateTabs>, {uid, isChanged}: IsChangedDoc): void {
        const tabs = [...ctx.getState().tabs];
        const tab = {...tabs.find((i) => String(i.uid) === String(uid))};
        const index = tabs.findIndex((i) => String(i.uid) === String(uid));
        tab.isChanged = isChanged;
        tabs[index] = tab;
        ctx.patchState({tabs});
    }
}
