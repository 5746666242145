import {StorageModel} from '../../../receipt/symbols';
import {FolderExpectDataBase, StorageMovementModel} from '../../storage.symbols';


export class GetGoodsById {
    static type = '[StorageManage] GetGoodsById';

    constructor(public id: number) {
    }
}

export class GetGoodsByIdSuccess {
    static type = '[StorageManage] GetGoodsByIdSuccess';

    constructor(public goods: StorageModel) {
    }
}

export class GetGoodsByIdFail {
    static type = '[StorageManage] GetGoodsByIdFail';

    constructor(public error: any) {
    }
}


export class ClearCurrentGoods {
    static type = '[StorageManage] ClearCurrentGoods';

}

export class UpdateGoods {
    static type = '[StorageManage] UpdateGoods';

    constructor(public id: string, public goods: StorageModel) {
    }
}

export class UpdateGoodsSuccess {
    static type = '[StorageManage] UpdateGoodsSuccess';

    constructor(public goods: StorageModel) {
    }
}

export class UpdateGoodsSuccessWS {
    static type = '[StorageManage] UpdateGoodsSuccess WS';

    constructor(public payload: StorageModel) {
    }
}

export class UpdateGoodsFail {
    static type = '[StorageManage] UpdateGoodsFail';

    constructor(public error: any) {
    }
}

export class SetCurrentGoods {
    static type = '[StorageManage] SetCurrentGoods';

    constructor(public goods: StorageModel) {
    }
}

export class ResetUpdateGoodsStatus {
    static type = '[StorageManage] ResetUpdateGoodsStatus';
}


export class CreateFolder {
    static type = '[StorageManage] CreateFolder';

    constructor(public folder: FolderExpectDataBase) {
    }
}

export class CreateFolderSuccess {
    static type = '[StorageManage] CreateFolderSuccess';

    constructor(public folder: FolderExpectDataBase) {
    }
}

export class CreateFolderSuccessWS {
    static type = '[StorageManage] CreateFolderSuccess WS';

    constructor(public payload: FolderExpectDataBase) {
    }
}

export class CreateFolderFail {
    static type = '[StorageManage] CreateFolderFail';

    constructor(public error: any) {
    }
}


export class UpdateFolder {
    static type = '[StorageManage] UpdateFolder';

    constructor(public folder: FolderExpectDataBase) {
    }
}

export class UpdateFolderSuccess {
    static type = '[StorageManage] UpdateFolderSuccess';

    constructor(public folder: FolderExpectDataBase) {
    }
}

export class UpdateFolderFail {
    static type = '[StorageManage] UpdateFolderFail';

    constructor(public error: any) {
    }
}


export class CreateGoods {
    static type = '[StorageManage] CreateGoods';

    constructor(public goods: StorageModel) {
    }
}

export class CreateGoodsSuccess {
    static type = '[StorageManage] CreateGoodsSuccess';

    constructor(public goods: StorageModel) {
    }
}

export class CreateGoodsSuccessWs {
    static type = '[StorageManage] CreateGoodsSuccess WS';

    constructor(public payload: StorageModel) {
    }
}

export class CreateGoodsFail {
    static type = '[StorageManage] CreateGoodsFail';

    constructor(public error: any) {
    }
}

export class LoadMovementGoods {
    static type = '[StorageManage] LoadMovementGoods';

    constructor(public id: number) {
    }
}

export class LoadMovementGoodsSuccess {
    static type = '[StorageManage] LoadMovementGoodsSuccess';

    constructor(public goods: StorageMovementModel) {
    }
}

export class LoadMovementGoodsFail {
    static type = '[StorageManage] LoadMovementGoodsFail';

    constructor(public error: any) {
    }
}

export class CloseMovementGoods {
    static type = '[StorageManage] CloseMovementGoods';

    constructor(public id: number) {
    }
}

export class PrintMovementGoodsReceipt {
    static type = '[StorageManage] PrintMovementGoodsReceipt';

}

export class InsertMovementGoods {
    static type = '[StorageManage] InsertMovementGoods';

    constructor(public goods: StorageMovementModel) {
    }
}

export class InsertMovementGoodsSuccess {
    static type = '[StorageManage] InsertMovementGoodsSuccess';

    constructor(public goods: StorageMovementModel, public previousGoods: StorageMovementModel) {
    }
}

export class InsertMovementGoodsFail {
    static type = '[StorageManage] InsertMovementGoodsFail';

    constructor(public error: any) {
    }
}

export class UpdateMovementGoods {
    static type = '[StorageManage] UpdateMovementGoods';

    constructor(public id: number, public goods: StorageMovementModel) {
    }
}

export class UpdateMovementGoodsSuccess {
    static type = '[StorageManage] UpdateMovementGoodsSuccess';

    constructor(public goods: StorageMovementModel) {
    }
}

export class UpdateMovementGoodsFail {
    static type = '[StorageManage] UpdateMovementGoodsFail';

    constructor(public error: any) {
    }
}

export class CreateMovementGoods {
    static type = '[StorageManage] CreateMovementGoods';

    constructor(public goods: StorageMovementModel) {
    }
}
