import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Select} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {TelModel} from 'src/app/core/models/tel.models';
import {AuthService} from 'src/app/core/services/auth.service';
import {RoleModel} from '../../../core/states/auth/auth.model';
import {AuthState} from '../../../core/states/auth/auth.state';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-client-full',
    templateUrl: './client-full.component.html',
    styleUrls: ['./client-full.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFullComponent implements OnChanges, OnDestroy {
    @Select(AuthState.access) access$: Observable<RoleModel>;

    @Output() outputTel: EventEmitter<{ tel: TelModel }> = new EventEmitter();
    @Output() outputTel2: EventEmitter<{ tel2: TelModel }> = new EventEmitter();
    @Output() outputUser: EventEmitter<{ user: string }> = new EventEmitter();
    @Input() user?: string;
    telLocal: TelModel;
    tel2Local: TelModel;


    controlUser = new FormControl();
    subscription = new Subscription();

    constructor(public auth: AuthService, private cd: ChangeDetectorRef) {
    }

    @Input() set tel(tel: TelModel) {
        this.telLocal = tel;
    }

    @Input() set tel2(tel: TelModel) {
        this.tel2Local = tel;
    }


    ngOnChanges(): void {


        this.controlUser.setValue(this.user, {emitEvent: false});

        this.subscription.add(
            this.controlUser.valueChanges
                .subscribe(v => this.outputUser.emit({user: v})));
        this.cd.detectChanges();

    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getTel(tel: TelModel): void {
        if (!isEqual(tel, this.telLocal)) {
            this.outputTel.emit({tel});
        }
    }


    getTel2(tel: TelModel): void {
        if (!isEqual(tel, this.tel2Local)) {
            this.outputTel2.emit({tel2: tel});
        }
    }


    private isVerify(tel: TelModel): boolean {
        return tel.subscriber.length === 4 || !tel.area;
    }


}
