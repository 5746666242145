import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {KetelModel} from '../../../core/models/ketel.models';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {EquipmentService} from '../equipment/equipment.service';
import {STATE_TALONE} from '../../../core/models/talone.models';
import {ContractModel, STATE_CONTRACT} from '../../../modules/contracts/contract.models';
import {TalonModel} from '../../../modules/talon/talon.model';
import {StorageModel} from '../../../modules/receipt/symbols';

@Component({
    selector: 'app-equipment-mobile',
    templateUrl: './equipment-mobile.component.html',
    styleUrls: ['./equipment-mobile.component.scss']
})
export class EquipmentMobileComponent implements OnDestroy, OnInit {
    @Output() outputEquipmentName = new EventEmitter<{ ketel: Partial<KetelModel> }>();
    @Output() outputEquipmentSn = new EventEmitter<{ ketel: Partial<KetelModel> }>();
    @Output() outputEquipmentKwt = new EventEmitter<{ ketel: Partial<KetelModel> }>();

    @Input() set talons(talons: TalonModel[]) {
        this.indexSelectTalon = talons?.length;
        this.talonsLocal = talons;
    }

    @Input() set contracts(contracts: ContractModel[]) {
        this.indexSelectContract = contracts.length;
        this.contractsLocal = contracts;
    }

    @Input() set equipment(equipment: KetelModel) {
        if (this.subscriptionName) {
            this.subscriptionName.unsubscribe();
        }
        if (this.subscriptionSn) {
            this.subscriptionSn.unsubscribe();
        }
        if (this.subscriptionKwt) {
            this.subscriptionKwt.unsubscribe();
        }

        this.form.controls.name.setValue(equipment.name);
        this.form.controls.kWt.setValue(equipment.kWt);

        if (!this.form.controls.sn.touched) {
            this.form.controls.sn.setValue(equipment.sn);
        }
        this.equipmentLocal = {...equipment};
        this.init();
    }

    equipmentLocal: KetelModel;
    form = new FormGroup({
        name: new FormControl(),
        sn: new FormControl(),
        kWt: new FormControl()
    });

    ketels: BehaviorSubject<StorageModel[]> = new BehaviorSubject([]);

    subscriptionName: Subscription;
    subscriptionKwt: Subscription;
    subscriptionSn: Subscription;
    subscription = new Subscription();

    talonsLocal: TalonModel[];
    stateTalone = STATE_TALONE;
    indexSelectTalon: number;


    indexSelectContract: number;
    contractsLocal: ContractModel[];
    stateContract = STATE_CONTRACT;



    constructor(private service: EquipmentService) {
    }

    init() {

        this.subscriptionName = this.form.controls.name.valueChanges.subscribe(name => {
            const updateKetel = {...this.equipmentLocal, name};
            this.outputEquipmentName.emit({ketel: updateKetel});
        });

        this.subscriptionSn = this.form.controls.sn.valueChanges.subscribe(sn => {
            const updateKetel = {...this.equipmentLocal, sn};
            this.outputEquipmentSn.emit({ketel: updateKetel});
        });

        this.subscriptionKwt = this.form.controls.kWt.valueChanges.subscribe(kWt => {
            const updateKetel = {...this.equipmentLocal, kWt};
            this.outputEquipmentKwt.emit({ketel: updateKetel});
        });
    }

    ngOnInit(): void {
        this.subscription.add(this.form.controls.name.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((v: string) => this.service.getLibraryEquipment(v, 'all', 'main'))
            ).subscribe((v: any) => {
                this.ketels.next(v.ketels);
            }));
    }

    ngOnDestroy(): void {
        if (this.subscriptionName) {
            this.subscriptionName.unsubscribe();
        }
        if (this.subscriptionSn) {
            this.subscriptionSn.unsubscribe();
        }
        if (this.subscriptionKwt) {
            this.subscriptionKwt.unsubscribe();
        }

        this.subscription.unsubscribe();
    }

}
